<template>
  <div class="custom-animation-gj">
    <div class="d-flex justify-content-between mb-3">
      <router-link
        to="/HomePage/MyPosts/userpost"
        class="text-decoration-none post-nav-link"
        @click="changeEffectPost"
        :class="{ active: changePost }"
      >
        <h3 class="post-nav-heading">Post</h3>
      </router-link>
      <router-link
        to="/HomePage/MyPosts/hiddenpost"
        class="text-decoration-none post-nav-link"
        @click="changeEffectHidden"
        :class="{ active: changehidden }"
      >
        <h3 class="post-nav-heading">Hidden Post</h3>
      </router-link>
      <router-link
        to="/HomePage/MyPosts/reportedpost"
        class="text-decoration-none post-nav-link"
        @click="changeEffectReported"
        :class="{ active: changeReported }"
      >
        <h3 class="post-nav-heading">Reported Post</h3>
      </router-link>
    </div>
    <router-view class="custom-animation-gj"></router-view>
  </div>
</template>

<script>
export default {
  name: "MyPosts",
  data() {
    return {
      changePost: false,
      changehidden: false,
      changeReported: false,
      Mypost: [],
    };
  },
  methods: {
    changeEffectPost() {
      this.changePost = !this.changePost;
      this.changehidden = false;
      this.changeReported = false;
    },
    changeEffectHidden() {
      this.changehidden = !this.changehidden;
      this.changePost = false;
      this.changeReported = false;
    },
    changeEffectReported() {
      this.changeReported = !this.changeReported;
      this.changePost = false;
      this.changehidden = false;
    },
  },
};
</script>

<style scoped>
.post-nav-link {
  padding: 0.5rem 1rem;
  color: #b4b4b4;
}

.post-nav-heading {
  margin: 0;
}

.post-nav-link.active {
  color: #c10111;
  border-bottom: 0.1rem solid #c10111;
}
</style>
