<template>
  <div class="d-flex align-items-center ms-1 py-auto">
    <button
      @click="toggleLikeDislike"
      id="likehand"
      type="button"
      class="btn-sm"
      style="display: flex; width: 25%; background: none; border: none"
    >
      <img
        v-if="isLike == false"
        src="../assets/like-t.svg"
        width="22"
        height="22"
      />

      <img
        v-else
        src="../assets/like-s.svg"
        width="22"
        height="22"
        style="margin-left: -2%; margin-top: -3%"
        class="animate__animated animate__heartBeat"
      />
    </button>

    <h6
      class="px-1 ms-3 mb-0"
      @click="showLikes"
      data-bs-toggle="modal"
      :data-bs-target="'#exampleModal-' + postId"
      style="cursor: pointer; color: black; font-size: 1rem; margin-top: 0.2rem"
    >
      {{ likesCount }}
    </h6>

    <div
      class="modal fade"
      :id="'exampleModal-' + postId"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Likes {{ likesCount }}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-for="like in likeAry"
              :key="like.id"
              class="d-flex align-items-center flex-column w-100"
            >
              <div class="d-flex align-items-center w-100">
                <img
                  class="profile-pic"
                  :src="
                    like.user.profile_photo
                      ? like.user.profile_photo
                      : defaultImage
                  "
                />
                <h1 class="fs-6 my-0 mx-3">
                  {{ like.user.first_name }} {{ like.user.last_name }}
                </h1>
              </div>
              <hr class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "UserlikeP",
  props: {
    postId: String,
    likes_count: String,
    is_liked: Boolean,
  },
  data() {
    return {
      liked: this.is_liked,
      likesCount: this.likes_count,
      isLike: this.is_liked,
      likeAry: [],
      defaultImage: require("@/assets/profile-no-img.png"),
    };
  },
  methods: {
    // toggleLikeDislike() {
    //   this.liked = !this.liked;
    //   axios.post("entrant/likes/toggle-like", { post_id: this.postId })
    //     .then((response) => {
    //       this.isLike = !this.isLike;
    //       this.likesCount = response.data.data.likes_count;
    //     });
    // },

    async toggleLikeDislike() {
      this.liked = !this.liked;
      const userType = this.$store.state.userType;

      // Define the base endpoint for likes
      let endpoint = "";

      switch (userType) {
        case "1":
          endpoint = "entrant/likes/toggle-like";
          break;
        case "3":
          endpoint = "gc/likes/toggle-like";
          break;
        case "2":
          endpoint = "gj/likes/toggle-like";
          break;
        default:
          // Handle other user types or show a default set of likes
          console.error("Invalid user type");
          return;
      }

      try {
        const response = await axios.post(endpoint, { post_id: this.postId });
        this.isLike = !this.isLike;
        this.likesCount = response.data.data.likes_count;
      } catch (error) {
        console.error("Error toggling like/dislike:", error);
      }
    },

    async showLikes() {
      // console.log(this.postId);
      const userType = this.$store.state.userType;

      // Define the base endpoint for likes
      let endpoint = "";

      switch (userType) {
        case "1":
          endpoint = "entrant/posts/likes";
          break;
        case "3":
          endpoint = "gc/posts/likes";
          break;
        case "2":
          endpoint = "gj/posts/likes";
          break;
        default:
          // Handle other user types or show a default set of likes
          console.error("Invalid user type");
          return;
      }

      try {
        const response = await axios.post(
          endpoint,
          { post_id: this.postId },
          {
            headers: {
              DeviceID: "1111",
              versionName: "1.0.0",
              DeviceType: "0",
            },
          }
        );
        this.likeAry = response.data.data.likes;
      } catch (error) {
        console.error("Error fetching likes:", error);
      }
    },

    // showLikes() {
    //   console.log(this.postId);
    //   axios.post("entrant/posts/likes", { post_id: this.postId }, {
    //     headers: {
    //       DeviceID: "1111",
    //       versionName: "1.0.0",
    //       DeviceType: "0",
    //     },
    //   })
    //     .then((response) => {
    //       this.likeAry = response.data.data.likes;
    //     });
    // },
  },
};
</script>

<style scoped>
.profile-pic {
  width: 13%;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 10px;
}
</style>
