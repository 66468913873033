// store.js
import { createStore } from "vuex";

export default createStore({
	state: {
		userType: localStorage.getItem("userType") || null,
	},
	mutations: {
		setUserType(state, userType) {
			// console.log("User Type: ", userType);
			state.userType = userType;
			// Persist user type in localStorage
			localStorage.setItem("userType", userType);
		},
	},
	created() {
		// console.log('User type from store:', this.$store.state.userType);
	},
});
