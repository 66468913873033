<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div class="container p-0 m-auto w-75">
      <div
        class="row w-100 d-flex justify-content-center m-0 p-0 position-relative"
        style="top: 8rem"
      >
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <LeftSideContent />

          <LeftAdBanner />
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <div
              class="d-flex justify-content-between align-otmes-center shadow p-3 bg-white rounded-3"
            >
              <router-link to="/event" v-slot="{ navigate }">
                <button
                  v-on:click="navigate"
                  role="link"
                  class="btn btn-danger"
                >
                  + create new event
                </button>
              </router-link>
              <i
                class="fa fa-sliders d-flex align-items-center"
                id="fa-slider"
                aria-hidden="true"
              ></i>
            </div>

            <div
              id="carouselExampleIndicators"
              class="carousel slide my-3"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="../assets/images/Photos/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../assets/images/Photos/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../assets/images/Photos/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div
              class="outer-div shadow p-3 bg-white rounded-3 mb-5 overflow-auto"
              style="height: 50vh"
            >
              <div
                class="rounded-4 px-2 py-2 my-3"
                style="background: rgb(186 178 178 / 68%)"
                v-for="event in userEvents"
                :key="event.id"
              >
                <router-link
                  to="/viewevent"
                  class="text-decoration-none text-dark"
                >
                  <div
                    class="d-flex align-items-cenetr mt-3 custom-res-event-details"
                  >
                    <div class="d-block w-25 custom-res-event-img-div">
                      <img
                        class="w-100 h-100 rounded-4"
                        :src="event.event_logo"
                        alt="event-logo"
                      />
                    </div>
                    <div class="d-flex flex-column pl-2">
                      <div class="d-flex align-items-center">
                        <h5 class="m-0" style="color: #5b5858ad">
                          Organizer :
                        </h5>
                        <p class="m-0">{{ event.organizer_name }}</p>
                      </div>
                      <div class="d-flex align-items-center my-2">
                        <h5 class="m-0" style="color: #5b5858ad">Location :</h5>
                        <p class="m-0">{{ event.location }}</p>
                      </div>
                      <div class="d-flex align-items-center my-2">
                        <h5 class="m-0" style="color: #5b5858ad">Fees :</h5>
                        <p class="m-0">{{ event.entry_pass_amount }}</p>
                      </div>
                      <div class="d-flex align-items-center">
                        <h5 class="m-0" style="color: #5b5858ad">
                          Start Date :
                        </h5>
                        <p class="m-0">
                          {{ event.started_at }} to {{ event.ended_at }}
                        </p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-2 col-lg-3">
          <RightSideContent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

// import HomeHeader from '@/components/Header-Homepage.vue'
import LeftSideContent from "./LeftSideContent.vue";
import NavBar from "./Profile_Screens/NavBar.vue";
import RightSideContent from "./RightSideContent.vue";

import LeftAdBanner from "./LeftAdBanner.vue";

export default {
  name: "EventPage",
  components: {
    NavBar,
    LeftSideContent,
    RightSideContent,
    LeftAdBanner,
  },
  data() {
    return {
      userEvents: [], // Assuming userEvents is an array to store event data
    };
  },
  mounted() {
    this.allEventFetch();
  },
  methods: {
    async allEventFetch() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/events";
            break;
          case "3":
            endpoint = "gc/events";
            break;
          case "2":
            endpoint = "gj/events";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-event-read-all";
            break;
        }

        const response = await axios.get(endpoint);
        // console.log(response);
        this.userEvents = response.data.data; // Assuming events are in the "data" property
        // console.log("User Events =", this.userEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    /* max-width: 100%; */
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .responsive-css-garba-class {
    flex-direction: column;
    text-align: center;
  }

  .custom-res {
    justify-content: center;
    margin: 0.6rem 0;
  }

  .custome-res-headings {
    margin: 1rem 0;
  }

  .custom-res-img {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}

@media (max-width: 560px) {
  .custom-res-event-details {
    flex-direction: column;
  }

  .custom-res-event-img-div {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .custom-res-event-img-div img {
    width: 50% !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
</style>
