<template>
    <div class="" style="width: 90%; margin-left: 5%;" >
        
      <h1>Deletion Policy for GarbaJocky</h1>
      <p>
        At GarbaJocky, we respect your privacy and your right to control your personal information. If you wish to delete your account or any content that you have uploaded on our platform, please send an email to our customer support team at <a href="mailto:garbajockey@gmail.com?subject=Account%20Deletion%20Request">garbajockey@gmail.com</a> with the subject "Account Deletion Request."
      </p>
      <p>
        Upon receiving your request, we will review and verify the request to ensure that it is legitimate. Once we have confirmed the request, we will delete your account and any associated personal information within 30 days. Please note that we may retain certain information for legal or business purposes, such as transaction records or backup copies of deleted content.
      </p>
      <p>
        We also reserve the right to suspend or terminate any account that violates our terms and conditions, or that engages in fraudulent or inappropriate behavior. In such cases, we may delete any associated content immediately without prior notice.
      </p>
      <p>
        If you have any questions or concerns about our deletion policy, please do not hesitate to contact our customer support team at: <a href="mailto:garbajockey@gmail.com">garbajockey@gmail.com</a>.
      </p>
    
    </div>
    
  </template>
  
  <style scoped>
    .container {
      max-width: 100%;
      margin: 0 auto;
      padding: 20px;
    }
  
    h1 {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
  </style>
  