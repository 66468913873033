<template>
  <div
    class="user_actions w-100 d-flex justify-content-center align-items-center"
  >
    <table class="w-100">
      <tr class="row-data-number mb-3" style="border-bottom: 2px dashed black">
        <td
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="switchTab('tab1')"
          style="border-right: 2px dashed black"
          class="text-center p-3"
        >
          <div class="d-flex flex-column">
            <span>followers</span>
            <span>{{ followersTotal }}</span>
          </div>
        </td>

        <td
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="switchTab('tab2')"
          style="border-right: 2px dashed black"
          class="text-center p-3"
        >
          <div class="d-flex flex-column">
            <span>Following</span>
            <span>{{ followingsTotal }}</span>
          </div>
        </td>

        <td
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="switchTab('tab3')"
          class="text-center p-3"
        >
          <div class="d-flex flex-column">
            <span>Request</span>
            <span>{{ followRequestsTotal }}</span>
          </div>
        </td>
      </tr>

      <tr class="row-data-number">
        <td
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="switchTab('tab4')"
          style="border-right: 2px dashed black"
          class="text-center p-3"
        >
          <div class="d-flex flex-column">
            <span>Subscribers</span>
            <span>{{ suscribersUserTotal ? suscribersUserTotal : 0 }}</span>
          </div>
        </td>

        <td
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="switchTab('tab5')"
          style="border-right: 2px dashed black"
          class="text-center p-3"
        >
          <div class="d-flex flex-column">
            <span>Reported Users</span>
            <span>{{ reportedUsersTotal ? reportedUsersTotal : 0 }}</span>
          </div>
        </td>

        <td
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="switchTab('tab6')"
          class="text-center p-3"
        >
          <div class="d-flex flex-column">
            <span>Block Users</span>
            <span>{{ blockedUsersTotal ? blockedUsersTotal : 0 }}</span>
          </div>
        </td>
      </tr>
    </table>

    <div
      class="modal fade modal-centred"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="search-box p-2">
            <button
              type="button"
              class="btn-close position-absolute end-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <input
              type="search"
              v-model="query"
              @input="getMyFollowers"
              placeholder="Search"
              class="form-control me-2"
            />
          </div>
          <div class="modal-header">
            <ul class="nav nav-tabs" style="border-bottom: none">
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: activeTab === 'tab1' }"
                  data-bs-toggle="tab"
                  href="#tab1"
                  @click="switchTab('tab1')"
                  >Followers</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: activeTab === 'tab2' }"
                  data-bs-toggle="tab"
                  href="#tab2"
                  @click="switchTab('tab2')"
                  >Following</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: activeTab === 'tab3' }"
                  data-bs-toggle="tab"
                  href="#tab3"
                  @click="switchTab('tab3')"
                  >Requests</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: activeTab === 'tab4' }"
                  data-bs-toggle="tab"
                  href="#tab4"
                  @click="switchTab('tab4')"
                  >Suscribers</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: activeTab === 'tab5' }"
                  data-bs-toggle="tab"
                  href="#tab5"
                  @click="switchTab('tab5')"
                  >Reported User</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="{ active: activeTab === 'tab6' }"
                  data-bs-toggle="tab"
                  href="#tab6"
                  @click="switchTab('tab6')"
                  >Block Users</a
                >
              </li>
              <!-- Add more tabs as needed -->
            </ul>
          </div>
          <div class="modal-body">
            <div>
              <div
                class="tab-pane fade"
                :class="{ show: activeTab === 'tab1' }"
                id="tab1"
              >
                <div
                  class="followers"
                  v-for="follower in followers"
                  :key="follower.id"
                >
                  <div class="d-flex align-items-center w-100">
                    <img
                      :src="
                        follower.profile_photo
                          ? follower.profile_photo
                          : defaultImage
                      "
                      alt="img"
                      style="width: 15%"
                    />
                    <div class="d-flex w-100 justify-content-between ml-2">
                      <h3>
                        {{ follower.first_name }} {{ follower.last_name }}
                      </h3>
                      <button
                        class="btn btn-danger"
                        @click="removeFollower(follower.id)"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div
                class="tab-pane fade"
                :class="{ show: activeTab === 'tab2' }"
                id="tab2"
              >
                <div
                  class="user_following"
                  v-for="following in followings"
                  :key="following.id"
                >
                  <div class="d-flex align-items-center w-100">
                    <img
                      :src="
                        following.profile_photo
                          ? following.profile_photo
                          : defaultImage
                      "
                      alt="img"
                      style="width: 15%"
                    />
                    <div class="d-flex w-100 justify-content-between ml-2">
                      <h3>
                        {{ following.first_name }} {{ following.last_name }}
                      </h3>
                      <button
                        class="btn btn-danger"
                        @click="unfollowUser(following.id)"
                      >
                        Unfollow
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div
                class="tab-pane fade"
                :class="{ show: activeTab === 'tab3' }"
                id="tab3"
              >
                <div
                  class="follow-request"
                  v-for="request in followRequests"
                  :key="request.id"
                >
                  <div class="d-flex align-items-center w-100">
                    <img
                      :src="
                        request.profile_photo
                          ? request.profile_photo
                          : defaultImage
                      "
                      alt="img"
                      style="width: 15%"
                    />
                    <div class="d-flex w-100 justify-content-between ml-2">
                      <h3>{{ request.first_name }} {{ request.last_name }}</h3>
                      <button
                        class="btn btn-danger"
                        style="margin-right: -12rem"
                        @click="rejectRequest(request.id)"
                      >
                        Reject
                      </button>
                      <button
                        class="btn btn-danger"
                        @click="acceptRequest(request.id)"
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div
                class="tab-pane fade"
                :class="{ show: activeTab === 'tab4' }"
                id="tab4"
              >
                <div
                  class="suscriber"
                  v-for="suscriber in suscribersUser"
                  :key="suscriber.id"
                >
                  <div class="d-flex align-items-center w-100">
                    <img
                      :src="
                        suscriber.garba_class_logo
                          ? suscriber.garba_class_logo
                          : defaultImage
                      "
                      alt="img"
                      style="width: 15%"
                    />
                    <div class="d-flex w-100 justify-content-between ml-2">
                      <h3>{{ suscriber.garba_class_name }}</h3>
                      <button
                        class="btn btn-danger"
                        @click="suscribe(suscriber.id)"
                      >
                        Suscribe
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div
                class="tab-pane fade"
                :class="{ show: activeTab === 'tab5' }"
                id="tab5"
              >
                <div
                  class="suscriber"
                  v-for="reporteduser in reportedUsers"
                  :key="reporteduser.id"
                >
                  <div class="d-flex align-items-center w-100">
                    <img
                      :src="
                        reporteduser.profile_photo
                          ? reporteduser.profile_photo
                          : defaultImage
                      "
                      alt="img"
                      style="width: 15%"
                    />
                    <div class="d-flex w-100 justify-content-between ml-2">
                      <h3>
                        {{ reporteduser.first_name
                        }}{{ reporteduser.last_name }}
                      </h3>
                      <!-- <button class="btn btn-danger" @click="reported(reporteduser.id)">Suscribe</button> -->
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div
                class="tab-pane fade"
                :class="{ show: activeTab === 'tab6' }"
                id="tab6"
              >
                <div
                  class="suscriber"
                  v-for="blockedUser in blockedUsers"
                  :key="blockedUser.id"
                >
                  <div class="d-flex align-items-center w-100">
                    <img
                      :src="
                        blockedUser.profile_photo
                          ? blockedUser.profile_photo
                          : defaultImage
                      "
                      alt="img"
                      style="width: 15%"
                    />
                    <div class="d-flex w-100 justify-content-between ml-2">
                      <h3>
                        {{ blockedUser.first_name }}{{ blockedUser.last_name }}
                      </h3>
                      <!-- <button class="btn btn-danger" @click="reported(reporteduser.id)">Suscribe</button> -->
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <!-- Add more tab content as needed -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "UserActions",
  data() {
    return {
      // request: 330,
      followers: [],
      followersTotal: "",
      followings: [],
      followingsTotal: "",
      followRequests: [],
      followRequestsTotal: "",
      suscribersUser: [],
      suscribersUserTotal: "",
      reportedUsers: [],
      reportedUsersTotal: "",
      blockedUsers: [],
      blockedUsersTotal: "",
      profile: [],
      item: [],
      sub: [],
      city: "",
      id: "",
      query: "",
      garbaclasses: [],
      activeTab: "tab1",
      defaultImage: require("@/assets/profile-no-img.png"),
    };
  },
  methods: {
    async getMyFollowings() {
      try {
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/follows/my-followings";
            break;
          case "3":
            endpoint = "gc/follows/my-followings";
            break;
          case "2":
            endpoint = "gj/follows/my-followings";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }
        axios.get(endpoint).then((response) => {
          // console.log("entrant user followings", response.data.data);
          this.following = response.data.data;
          this.followingsTotal = response.data.total;
          // console.log("following = ", this.followings);
          // console.log("following total = ", this.followingsTotal);
        });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    async getMyFollowers() {
      try {
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/follows/my-followers";
            break;
          case "3":
            endpoint = "gc/follows/my-followers";
            break;
          case "2":
            endpoint = "gj/follows/my-followers";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        /*followers api call  */
        axios
          .get(endpoint)
          .then((response) => {
            // console.log(response.data.data);
            this.followers = response.data.data;
            this.followersTotal = response.data.total;
            // console.log("followers = ", this.followers);
            // console.log("followers = ", this.followersTotal);
          })
          .catch((err) => {
            console.log("error = ", err);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    async getMyFollowRequests() {
      try {
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/follows/my-followers-requests";
            break;
          case "3":
            endpoint = "gc/follows/my-followers-requests";
            break;
          case "2":
            endpoint = "gj/follows/my-followers-requests";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        /*followers api call  */
        axios
          .get(endpoint)
          .then((response) => {
            // console.log(response.data.data);
            this.followRequests = response.data.data;
            this.followRequestsTotal = response.data.total;
            // console.log("follow Request = ", this.followRequests);
            // console.log("follow Request Total = ", this.followRequestsTotal);
          })
          .catch((err) => {
            console.log("error = ", err);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    async getMySuscribers() {
      try {
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-subscriptions";
            break;
          case "3":
            endpoint = "gc/my-subscribers";
            break;
          case "2":
            endpoint = "gj/my-subscriptions";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        /*Suscribers api call  */
        axios
          .get(endpoint)
          .then((response) => {
            // console.log(response.data.data);
            this.suscribersUser = response.data.data.subscriptions;
            this.suscribersUserTotal = response.data.data.subscriptions_count;
            // console.log("Suscriber user = ", this.suscribersUser);
            // console.log("Suscriber user Total = ", this.suscribersUserTotal);
          })
          .catch((err) => {
            console.log("error = ", err);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    async getMyReportedUsers() {
      try {
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/users/my-reported-users";
            break;
          case "3":
            endpoint = "gc/users/my-reported-users";
            break;
          case "2":
            endpoint = "gj/users/my-reported-users";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        /*Suscribers api call  */
        axios
          .get(endpoint)
          .then((response) => {
            // console.log(response.data);
            this.reportedUsers = response.data.data.reported_users;
            this.reportedUsersTotal = response.data.data.reported_users_count;
            // console.log("Reported user = ", this.reportedUsers);
            // console.log("Reported user Total = ", this.reportedUsersTotal);
          })
          .catch((err) => {
            console.log("error = ", err);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    async getMyBlockUsers() {
      try {
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/blocks/my-blockee";
            break;
          case "3":
            endpoint = "gc/blocks/my-blockee";
            break;
          case "2":
            endpoint = "gj/blocks/my-blockee";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        /*Suscribers api call  */
        axios
          .get(endpoint)
          .then((response) => {
            // console.log(response.data);
            this.blockedUsers = response.data.data.blockee;
            this.blockedUsersTotal = response.data.data.blockees_count;
            // console.log("Reported user = ", this.blockedUsers);
            // console.log("Reported user Total = ", this.blockedUsersTotal);
          })
          .catch((err) => {
            console.log("error = ", err);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    switchTab(tab) {
      this.activeTab = tab;
      this.fetchDataForTab(tab);
    },

    async fetchDataForTab(tab) {
      switch (tab) {
        case "tab1":
          await this.getMyFollowers();
          break;
        case "tab2":
          await this.getMyFollowings();
          break;
        case "tab3":
          await this.getMyFollowRequests();
          break;
        case "tab4":
          await this.getMySuscribers();
          break;
        case "tab5":
          await this.getMyReportedUsers();
          break;
        case "tab6":
          await this.getMyBlockUsers();
          break;
        // Add more cases for other tabs as needed
        default:
          break;
      }
    },
  },
  mounted() {
    this.fetchDataForTab(this.activeTab);
    this.getMyFollowings();
    this.getMyFollowers();
    this.getMyReportedUsers();
    this.getMyFollowRequests();
    this.getMySuscribers();
    this.getMyReportedUsers();
    this.getMyBlockUsers();
  },
};
</script>

<style scoped>
.tab-pane {
  display: none;
  /* Hide all tab content by default */
}

.tab-pane.show {
  display: block;
  /* Show the active tab content */
}
</style>
