<template>
  <div class="d-flex align-items-center ms-2">
    <div style="display: contents">
      <img
        :src="profile.profile_photo ? profile.profile_photo : defaultImage"
        class="profile-image rounded-circle mr-2"
      />
    </div>
    <div class="ms-2" style="width: 90%">
      <p class="fs-6 m-0 user-name">
        What's in your mind {{ profile.first_name + " " + profile.last_name }}
      </p>
    </div>
  </div>

  <div
    class="video d-flex justify-content-center align-items-center flex-column"
  >
    <button type="button" class="video-button" @click="isOpen = true">
      <img
        class="video-btn"
        src="images/Icons/408405_videos_icon.svg"
        alt="Video"
      />
      <p class="title fs-6 m-0">Video</p>
    </button>
  </div>

  <Modal :open="isOpen" @close="isOpen = !isOpen">
    <p class="m-2 text-center">PostVideo</p>
    <hr />

    <div
      class="Post_video d-flex justify-content-center align-items-center w-75 my-2"
    >
      <div
        for="upload"
        class="d-flex justify-content-center align-items-center w-100%"
        :class="{ 'level-preview': videoUploadClass === 'show-level' }"
      >
        <img
          class="w-100"
          id="video-preview"
          src="../assets/up.png"
          alt=""
          style="height: 7rem"
        />
        <input
          :disabled="isUploading"
          type="file"
          class="d-flex justify-content-center align-items-center file-input"
          id="videos"
          ref="inputvideo"
          accept="video/*"
          @change="handleFileChange"
          style="opacity: 0; position: absolute; cursor: pointer"
        />
      </div>
      <div
        v-for="(video, index) in selectedVideos"
        :key="index"
        class="d-flex align-item-center w-100 justify-content-center position-relative"
      >
        <video
          class="w-75"
          ref="previewVideo"
          :src="video.previewUrl"
          controls
        ></video>
        <button @click="removeVideo(index)" class="remove-button">
          <i class="fas fa-times" style="color: red"></i>
        </button>
      </div>
    </div>
    <div class="px-3">
      <span
        class="video-size d-flex justify-content-center align-items-center w-100"
      ></span>
      <input
        class="input-box w-100 my-3"
        type="text"
        placeholder="Title"
        id="title"
        v-model="title"
      />
      <div>
        <input
          class="input-box w-100 my-3"
          type="text"
          placeholder="Description"
          id="description"
          v-model="description"
        />
      </div>
      <div>
        <input
          class="input-box w-100 my-3"
          type="text"
          placeholder="Add Tag"
          id="tags"
          v-model="tagInput"
          @input="searchTags"
        />
        <div v-if="tagSearchResults.length > 0" class="tag-search-results">
          <ul>
            <li
              v-for="result in tagSearchResults"
              :key="result.id"
              @click="selectTag(result)"
            >
              {{ result.first_name }} {{ result.last_name }}
            </li>
          </ul>
        </div>

        <!-- Add this div to display selected tags -->
        <div v-if="selectedTags.length > 0" class="selected-tags">
          <span v-for="(tag, index) in selectedTags" :key="index" class="tag">
            {{ tag }}
            <button @click="removeSelectedTag(index)" class="remove-tag-button">
              x
            </button>
          </span>
        </div>
      </div>
      <div class="d-flex justify-contnet-center align-items-center">
        <button
          :disabled="isUploading"
          class="Post_btn btn btn-danger w-100 d-flex align-items-center justify-content-center mx-0 my-3 px-0 py-2"
          type="submit"
          @click="uploadVideo"
        >
          Post
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { ref } from "vue";
// import { useStore } from "vuex";
import Modal from "@/components/Model/video-modal.vue";
import axios from "@/axios";

export default {
  name: "AddVideo",
  components: { Modal },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      title: "",
      description: "",
      tags: "",
      selectedVideos: [],
      videoUploadClass: "",
      videos: [],
      isUploading: false, // Added isUploading property
      latitude: null,
      longitude: null,
      tagInput: "",
      tagSearchResults: [],
      selectedTags: [],
      profile: [],
      defaultImage: require("@/assets/profile-no-img.png"),
      // city: "",
    };
  },

  methods: {
    async getDe() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(endpoint);
        this.profile = response.data.data;
        // console.log("User Profile:", this.profile);

        if (localStorage.getItem("token") || response.data.Status == 200) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
        } else {
          this.$router.push("/login");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    handleFileChange(event) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const previewUrl = URL.createObjectURL(file);

        const videoElement = document.createElement("video");
        videoElement.src = previewUrl;

        videoElement.onloadedmetadata = () => {
          const width = videoElement.videoWidth;
          const height = videoElement.videoHeight;

          this.selectedVideos.push({
            file: file,
            previewUrl: previewUrl,
            width: width,
            height: height,
          });

          this.videoUploadClass = "show-level";
        };
      }
    },

    async uploadVideo() {
      // console.log("Function Called !!!!");
      // console.log(this.latitude);
      // console.log(this.longitude);

      try {
        // console.log("Before Geolocation Fetch !!!");
        // Get location before setting isUploading to true
        await this.getLocation();
        // console.log("After Geolocation Fetched !!!");

        this.isUploading = true;

        // console.log("Before Form Data Created !!!!");
        const formData = new FormData();

        for (let i = 0; i < this.selectedVideos.length; i++) {
          const video = this.selectedVideos[i];
          // formData.append("videos[]", video.file);
          formData.append("videos[0]", video.file);
          formData.append("width", video.width); // Add width to formData
          formData.append("height", video.height); // Add height to formData
        }

        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("tags", this.selectedTags);
        // formData.append("tags", JSON.stringify({ tags: [this.tagInput] }));
        formData.append("location_lat", this.latitude);
        formData.append("location_lng", this.longitude);
        // formData.append("city", this.city);

        console.log("After Form Data Created !!!");
        // Determine the endpoint based on user type
        let endpoint = "";
        // Access userType in your component
        const userType = this.$store.state.userType;
        console.log("User type:", userType);

        switch (userType) {
          case "1":
            endpoint = "entrant/posts";
            break;
          case "3":
            endpoint = "gc/posts";
            break;
          case "2":
            endpoint = "gj/posts";
            break;
          default:
            console.error("Unknown user type");
            break;
        }

        // const response = await axios.post("entrant/posts", formData);
        const response = await axios.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.user_type === 2) {
          const uploadedVideo = response.data;
          // this.videos.push(uploadedVideo);
          this.videos.unshift(uploadedVideo); // Add the new video to the beginning of the array
        }

        this.resetForm();

        // console.log("Video uploaded successfully:", response.data);
      } catch (error) {
        console.error("Error uploading video:", error);
      } finally {
        this.isUploading = false;
      }
    },

    resetForm() {
      this.title = "";
      this.description = "";
      this.tags = "";
      this.selectedVideos = [];
      this.videoUploadClass = "";
      this.$refs.inputvideo.value = "";

      // Close the modal by setting isOpen to false
      this.isOpen = false;
    },

    async getLocation() {
      // console.log("Geolocation Function Called !!!!");
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // console.log("Position in Geolocation Function: ", position);
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            resolve(); // Resolve the Promise when the location is obtained
          },
          (error) => {
            alert(
              "Please Give Location Permission, \nOtherwise you were not able to post anything!!"
            );
            console.error("Error getting location:", error);
            reject(error); // Reject the Promise if there is an error
          }
        );
      });
    },

    removeVideo(index) {
      this.selectedVideos.splice(index, 1);
      if (this.selectedVideos.length === 0) {
        this.videoUploadClass = "";
      }
    },

    async searchTags() {
      if (this.tagInput.length > 2) {
        try {
          const response = await axios.get(`serach?search=${this.tagInput}`);
          this.tagSearchResults = response.data.data.users;
          console.log("result = ", this.tagSearchResults);
        } catch (error) {
          console.error("Error searching tags:", error);
        }
      } else {
        this.tagSearchResults = [];
      }
    },

    selectTag(result) {
      // Add the selected tag to the selectedTags array
      this.selectedTags.push(result.first_name + " " + result.last_name);

      // Clear the tag input and search results
      this.tagInput = "";
      this.tagSearchResults = [];
    },

    removeSelectedTag(index) {
      this.selectedTags.splice(index, 1);
    },
  },

  mounted() {
    // Get location when the component is mounted
    this.getLocation();
    // console.log("Geolocation Function Data: ", this.getLocation());
    this.getDe();
  },
};
</script>

<style scoped>
.user-name {
  font-weight: 600;
}
.profile-image {
  width: 2.6rem;
  height: 2.6rem;
  object-fit: cover;
}

.video-button {
  border: none;
  background: none;
}

.Post_video {
  border: 2px dashed #c10111;
  border-radius: 1rem;
  margin: auto;
  padding: 1rem;
  height: auto;
  min-height: 9rem;
  position: relative;
}

.upload_video {
  margin: 1rem 0;
  cursor: pointer;
}

.upload_video input {
  display: none;
}

.input-box {
  border: 1px solid #c10111;
  border-radius: 5px;
  height: 46px;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}

.file-input {
  width: inherit;
  height: 100%;
}

.level-preview {
  color: #fff;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 15px;
  left: 11px;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 0px 7px #6e6161;
}

.level-preview img {
  width: 1.8rem !important;
  height: 2rem !important;
}

.Post_btn {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  background-color: #c10111;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  /* Align to the end of the container (right) */
  align-items: center;
  /* Align to the start of the container (top) */
  position: absolute;
  top: -8px;
  right: -7px;
  background: #b8b8b8;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.video-size {
  background: #c10111;
  height: 1rem;
  margin: 1rem 0;
}

.tag-search-results {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tag-search-results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tag-search-results li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag-search-results li:hover {
  background-color: #f0f0f0;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.tag {
  background-color: #c10111;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.remove-tag-button {
  background: none;
  border: none;
  color: white;
  margin-left: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .profile-image {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
