<template>
  <div class="card-container">
    <div class="video-section" v-for="post in displayedPosts" :key="post.id">
      <video
        :src="post.videos[0].video"
        class="post-video"
        controls
        type="video/mp4"
        v-if="post.videos && post.videos.length > 0"
      ></video>
      <!-- {{post.description}} -->
    </div>
  </div>
  <div class="card-container mt-2">
    <button
      class="btn btn-danger"
      v-if="displayedPosts.length < MyPost.length"
      @click="loadMore"
    >
      Load More Post
    </button>
    <button
      class="btn btn-danger"
      v-if="displayedPosts.length > 4"
      @click="seeLess"
    >
      See Less
    </button>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "userpost",
  data() {
    return {
      MyPost: [],
      displayedPosts: [],
      postsToShow: 4,
    };
  },
  mounted() {
    this.GetMyPost();
  },
  methods: {
    GetMyPost() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/posts/my-posts";
            break;
          case "3":
            endpoint = "gc/posts/my-posts";
            break;
          case "2":
            endpoint = "gj/posts/my-posts";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        axios.get(endpoint).then((response) => {
          // console.log(response.data.data);
          this.MyPost = response.data.data;
          // console.log("from userpost page", this.MyPost);

          this.updateDisplayedPosts();
        });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    updateDisplayedPosts() {
      this.displayedPosts = this.MyPost.slice(0, this.postsToShow);
    },

    loadMore() {
      this.postsToShow += 4; // Increase the number of posts to show by 4
      this.updateDisplayedPosts();
    },

    seeLess() {
      this.postsToShow = 4; // Reset the number of posts to show to 4
      this.updateDisplayedPosts();
    },
  },
};
</script>

<style scoped>
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.video-section {
  margin-top: 0.5rem;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* Assuming videos are 16:9 aspect ratio */
}

.post-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
</style>
