<template>
  <div class="suggestion-box w-100">
    <div class="title">
      <span>Suggestion for you</span>
      <p class="text-dark" @click="this.$router.push({ name: 'Login' })">
        See All
      </p>
    </div>
    <hr />
    <div class="user-1 d-flex flex-wrap w-100">
      <div
        v-for="suggestion in suggestions"
        :key="suggestion.id"
        class="d-flex justify-contnet-center align-items-center flex-column"
        style="width: 50%"
      >
        <!-- <img class="suggestion_img" :src="suggestion.profile_photo" />
        <img
          class="suggestion_img"
          src="./assets/profile-no-img.png"
          v-if="suggestion.profile_photo === ''"
        /> -->

        <img
          :src="
            suggestion.profile_photo ? suggestion.profile_photo : defaultImage
          "
          class="suggestion_img"
          alt="profile-img"
        />
        <br />
        {{ suggestion.first_name }} {{ suggestion.lastname }}
        <button
          v-if="!suggestion.is_following"
          class="btn btn-danger suggestion-btn"
          @click="this.$router.push({ name: 'Login' })"
        >
          Follow
        </button>
        <!-- @click="sendFollowRequest(suggestion.id)"  -->
        <button
          v-if="suggestion.is_following"
          class="btn btn-danger suggestion-btn"
          @click="this.$router.push({ name: 'Login' })"
        >
          Unfollow
        </button>
        <!-- @click="sendUnfollowRequest(suggestion.id)" -->
      </div>
    </div>
  </div>

  <div class="my-3">
    <router-link to="/Login">
      <button type="button" class="btn btn-danger w-100">Contact-Us</button>
    </router-link>
  </div>

  <RightAdBanner />
</template>
  
  <script>
import axios from "axios";

import RightAdBanner from "./RightAdBanner.vue";
export default {
  name: "DummyRightSide",
  data() {
    return {
      token: "",
      profile: [],
      suggestions: [],
      defaultImage: require("@/assets/profile-no-img.png"),
      // apiUrl: "http://uatapi.garbajockey.com/public/api/",
      apiUrl: "https://api.garbajockey.com/api/",
    };
  },
  components: {
    RightAdBanner,
  },
  methods: {
    suggestion() {
      axios
        .get(`${this.apiUrl}suggestions`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          // console.log(response.data.data);
          this.suggestions = response.data.data.users;
          // console.log(this.suggestions);
        });
    },
    lodData() {
      this.token = localStorage.getItem("authToken");
      // console.log("this is home page show token", this.token);
    },
  },
  created() {
    this.lodData();
  },
  mounted() {
    this.suggestion();
  },
};
</script>
  
  <style scoped>
.suggestion-box {
  background-color: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  box-shadow: 0 0 7px #d4d4d4;
}

.suggestion-box p {
  font-size: 14px;
  margin: 2px 0px 0px 26px;
}

.suggestion-box span {
  font-size: 14px;
  margin-top: 2px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 4px 6.5px;
}

.user-1 div:nth-child(2n + 1) {
  border-right: 3px solid #828587;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:nth-child(2n + 2) {
  border-right: none;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:last-child {
  border-bottom: none;
}

.suggestion_img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 50%;
  padding: 0.3rem;
}

.suggestion-btn {
  margin: 11px 2px 10px;
  padding: 0px 7px 2px;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .suggestion_img {
    height: 4.8rem;
    width: 4.8rem;
  }
}

@media screen and (min-width: 1440px) {
  .suggestion_img {
    height: 6.8rem;
    width: 6.8rem;
  }
}
</style>
  