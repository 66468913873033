<template>
  <!-- <div v-for="post in posts" :key="post.id" class="postw"> -->
  <div
    v-for="post in posts"
    :key="post.id"
    class="postw animate__animated animate__zoomIn"
  >
    <div class="post-block w-100 mt-2 px-3 py-3">
      <div class="d-flex justify-content-between my-2">
        <div class="d-flex">
          <div class="mr-2">
            <router-link to="#" class="text-dark">
              <img
                :src="
                  post.user.profile_photo
                    ? post.user.profile_photo
                    : defaultImage
                "
                class="author-img"
              />
            </router-link>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <h5 class="mb-0">
              <a class="text-dark" style="text-decoration: none">
                {{ post.user.first_name }} {{ post.user.last_name }}
              </a>
            </h5>
            <!-- <p class="mb-0 text-muted">
              {{ moment(post.created_at).fromNow() }}
              {{ post.location_city }}
            </p> -->
          </div>
        </div>

        <LisT :postId="post.id" class="mt-1 me-1" />
      </div>

      <!-- <lisT v-for="(post, index) in posts" :key="post.id" :post-id="post.id" :item-index="index"
                @post-deleted="onPostDeleted" /> -->

      <div class="post-block__content mb-2 mt-4">
        <div class="post_video" v-if="post.videos && post.videos.length > 0">
          <video
            :src="post.videos[0].video"
            controls
            height="400"
            width="1080"
            type="video/mp4"
            @play="handleVideoPlay(post.id)"
            controlsList="nodownload"
          ></video>
        </div>
      </div>

      <div class="mb-3">
        <div class="User_action d-flex mb-2 align-items-center">
          <div class="d-flex align-items-center justify-content-between w-100">
            <UserlikeP
              :postId="post.id"
              :likes_count="post.likes_count"
              :is_liked="post.is_liked"
              @click="this.$router.push({ name: 'Login' })"
            />
            <UserComT
              :postId="post.id"
              :comments_count="post.comments_count"
              @click="this.$router.push({ name: 'Login' })"
            />
            <UsersharE
              :postId="post.id"
              :shares_count="post.shares_count"
              @click="this.$router.push({ name: 'Login' })"
            />
            <!-- <UservieW :postId="post.id" :views_count="post.views_count" /> -->
            <UservieW
              :postId="post.id"
              :views_count="post.views_count"
              @increment-view-count="incrementViewCount"
              @click="this.$router.push({ name: 'Login' })"
            ></UservieW>
          </div>
        </div>
        <p class="mb-0 ms-1">{{ post.description }}</p>
      </div>

      <div class="post-block__comments">
        <AddCommT
          :postId="post.id"
          @click="this.$router.push({ name: 'Login' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { useStore } from 'vuex';
import moment from "moment";
import { ref } from "vue";
import axios from "axios";
import UserlikeP from "./UserlikeP.vue";
import UserComT from "./UserComT.vue";
import UsersharE from "./UsersharE.vue";
import UservieW from "./UservieW.vue";
import LisT from "./lisT.vue";
import AddCommT from "./addCommT.vue";
// import { end } from "@popperjs/core";

export default {
  name: "AllPosts",
  props: {
    postId: String,
  },
  components: {
    UserlikeP,
    UserComT,
    UsersharE,
    UservieW,
    AddCommT,
    LisT,
  },

  setup() {
    const isOpen = ref(false);
    return {
      isOpen,
    };
  },
  data() {
    return {
      profile: [], // Your user profile data
      posts: [],
      hasMore: true,
      moment: moment,
      liked: false,
      comment: "",
      city: "",
      defaultImage: require("./assets/profile-no-img.png"),
      // apiUrl: "http://uatapi.garbajockey.com/public/api/",
      apiUrl: "https://api.garbajockey.com/api/",
      isLoadingMorePosts: false,
      endLoad: false,
    };
  },
  methods: {
    closePop() {
      let mode = document.getElementById("mode");
      mode.classList.remove("mode-all");
    },

    like() {
      this.liked = !this.liked;
    },

    async getPost() {
      // const userType = this.$store.state.userType;
      // // console.log("user type =", userType);

      // // Define the base endpoint for posts
      // let endpoint = "";

      // switch (userType) {
      //   case "1":
      //     endpoint = "entrant/posts";
      //     break;
      //   case "3":
      //     endpoint = "gc/posts";
      //     break;
      //   case "2":
      //     endpoint = "gj/posts/gj";
      //     break;
      //   default:
      //     // Handle other user types or show a default set of posts
      //     endpoint = "default";
      //     break;
      // }
      // console.log("TOken: ", localStorage.getItem("authToken"));

      try {
        // const response = await axios.get(
        //   `${endpoint}?per_page=8&page=${this.Page}`
        // );
        const response = await axios.get(
          `${this.apiUrl}posts/likes-desc?likes=desc&per_page=10`,
          {
            headers: {
              Authorization: `Bearer ` + localStorage.getItem("authToken"),
            },
          }
        );

        // console.log("Response: ", response.data);
        this.posts = response.data.data;

        // if (data.length > 0) {
        //   this.posts = [...this.posts, ...data];
        //   this.Page++;
        //   if ($state) {
        //     $state.loaded();
        //   }
        // } else {
        //   this.hasMore = false;
        //   if ($state) {
        //     $state.complete();
        //   }
        // }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },

    async loadMorePosts() {
      // console.log("Load More Function Called!!!");

      try {
        this.isLoadingMorePosts = true;

        const page = Math.ceil(this.posts.length / 10) + 1;
        // console.log("Page Number: ", page);
        const pageSize = 10;
        const response = await axios.get(
          `${this.apiUrl}posts/likes-desc?likes=desc`,
          {
            headers: {
              Authorization: `Bearer ` + localStorage.getItem("authToken"),
            },
            params: {
              page: page,
              per_page: pageSize,
            },
          }
        );

        // console.log("More Posts Response: ", response);

        const currentPosts = response.data.data;

        if (currentPosts.length > 0) {
          this.posts = [...this.posts, ...currentPosts];
          if (this.posts.length >= response.data.total) {
            // console.log("All Posts Fetched Successfully!!");
            this.endLoad = true;
          }
        } else {
          this.isLoadingMorePosts = false;
          this.endLoad = true;
        }

        // console.log("More Posts: ", this.posts, " Length: ", this.posts.length);
      } catch (error) {
        console.error("Error fetching more posts:", error);
      } finally {
        this.isLoadingMorePosts = false;
      }
    },

    handleVideoPlay(postId) {
      // Check if the view has already been counted for this post
      const post = this.posts.find((post) => post.id === postId);
      // console.log("posts = ", postId);
      if (post && !post.viewCounted) {
        // Increment the view count when the video is played
        post.views_count++;
        // Set the flag to true to indicate that the view has been counted
        post.viewCounted = true;

        // Update localStorage to reflect the new view count
        const storedViews =
          JSON.parse(localStorage.getItem("post_views")) || {};
        // storedViews[postId] = (storedViews[postId] || 0) + 1;
        // localStorage.setItem('post_views', JSON.stringify(storedViews));

        setTimeout(() => {
          // Increment the view count after the timeout
          post.views_count++;

          // Update localStorage again to reflect the new view count (increment by 1)
          storedViews[postId] = (storedViews[postId] || 0) + 1;
          localStorage.setItem("post_views", JSON.stringify(storedViews));

          // Emit another event to update the view count in UservieW component
          this.$emit("increment-view-count", postId);
        }, 5000);
      }
    },

    // getStoredViews(postId) {
    //   // Retrieve the stored view count from localStorage
    //   const storedViews = JSON.parse(localStorage.getItem("post_views")) || {};
    //   return storedViews[postId] || 0;
    // },

    handleScroll() {
      // Clear the previous timer
      clearTimeout(this.scrollTimer);

      // Set a new timer to execute after a delay
      this.scrollTimer = setTimeout(() => {
        if (
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 200
        ) {
          if (this.endLoad === false) {
            this.loadMorePosts();
            // console.log("Load More Calling!!!");
          }
        }
      }, 2000); // Adjust the debounce delay as needed
    },
  },
  mounted() {
    this.getPost();
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.post {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 7px #d4d4d4;
  visibility: visible;
}

.post_image img {
  width: 13%;
  padding: 0 6px;
  border-radius: 3rem;
  height: 3rem;
}

.video {
  float: right;
  margin: -1rem -3rem 5px;
}

.video img {
  margin: 4px 11px 6px;
  cursor: pointer;
}

.post-block {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 30px #d4d4d4;
}

.post-block img.author-img {
  height: 45px;
  width: 45.5px;
  border-radius: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

.post-block img.author-img.author-img--small {
  width: 11%;
  padding: 1%;
}

.post-block__content img,
.post-block__content video,
.post-block__content audio {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
}

.post-block .comment-view-box__comment {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px;
}

input.form-control {
  border-radius: 10px;
}

.comment-btn {
  border: none;
  background-color: #ffffff;
}

.suggestion-title {
  height: 42px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 165px;
  margin: 7px -13px 10px;
  padding: 3px 5px 4px 5px;
}

.user_action {
  padding: 0px 0px 0px 56px;
  margin: -3px 5px 10px;
}

.user_action span {
  margin: -3px 10px 10px;
  padding: 0px 20px 0px;
}

.about-section {
  margin-left: -2%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
}
</style>
