<template>
    <div>
        <h3>Profile Page</h3>
    </div>
</template>
<script>
    // import axios from 'axios'
export default{
    name:"ProfilePage",
    data(){
        return{

        }
    },
    methods:{
    
    }    

}
</script>
<style scoped>
</style>