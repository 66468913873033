import { createWebHistory, createRouter } from "vue-router";

import HelloWorld from "./components/RegisterNew.vue";
import UserSignin from "./components/UserLogin.vue";
import ForgotPass from "./components/forgot.vue";
import OtpVerification from "./components/OTP_Verification.vue";
import NewPassword from "./components/NewPass.vue";
import BodySection from "./components/Home-Body.vue";
import ProfilePage from "./components/ProfilePage.vue";
import Notification from "./components/Notification.vue";
import GarbaPage from "./components/GarbaPage.vue";
import GarbaJockey from "./components/GarbaJockey.vue";
import AboutGC from "./components/About_GC.vue";
import SubsriptionPage from "./components/Subscripton_Page.vue";
import Subsription_Page_2 from "./components/SubscriptionPage_2.vue";
import EventPage from "./components/EventPage.vue";
import event from "./components/event.vue";
import viewevent from "./components/viewevent.vue";
import MyProfile from "./components/Profile_Screens/View_Profile_List/MyProfile.vue";
import MyPosts from "./components/Profile_Screens/View_Profile_List/MyPosts.vue";
import MyGarbaClass from "./components/Profile_Screens/View_Profile_List/MyGarba.vue";
import MyTags from "./components/Profile_Screens/View_Profile_List/MyTags.vue";
import UploadVideo from "./components/Profile_Screens/View_Profile_List/UploadVideo.vue";
import ChangePassword from "./components/Profile_Screens/View_Profile_List/ChangePassword.vue";
import UplodedDetails from "./components/Profile_Screens/UploadedDetails.vue";
import HomePage from "./components/Profile_Screens/HomePage.vue";
import GC_Id_Post from "./components/UserIdPost.vue";
import Single_Post from "./components/Profile_Screens/SinglePage.vue";
import deletePolicy from "./deletePolicy.vue";
import UserSearch from "./components/Profile_Screens/NavBar_Search.vue";
import Payment_Success from "./components/Payment_Success_Model.vue";
import Entrant_User_GC from "./components/Profile_Screens/Entrant_My_Garba_Class.vue";
import userpost from "./components/Profile_Screens/UserPost.vue";
import hiddenpost from "./components/Profile_Screens/HiddenPost.vue";
import reportedpost from "./components/Profile_Screens/ReportedPost.vue";
import privacypolicy from "./components/privacypolicy.vue";
import contactUs from "./components/ContactUs.vue";
import RaiseTicketNew from "./components/RaiseTicketNew.vue";
import DummySignin from "./components/DummyUser/DummySignin.vue";

// IB Talks and Blogs Components

import IB_Talks_Page from "./components/IB_Talks_And_Blogs/IB_Talks_Page.vue";
import All_Blogs from "./components/IB_Talks_And_Blogs/All_Blogs.vue";
import Blogs from "./components/IB_Talks_And_Blogs/Blogs.vue";

// Dummy IB Talks and Dummy Blogs Components

import IB_Talks_Dummy_Page from "./components/DummyUser/IB_Talks_Dummy_Page.vue";
import The_Phoenix_Tales_Dummy_Page from "./components/DummyUser/The_Phoenix_Tales_Dummy.vue";
import All_Blogs_Dummy_Page from "./components/DummyUser/All_Blogs_Dummy_Page.vue";
import Blogs_Dummy from "./components/DummyUser/Blogs_Dummy.vue";

// Tutorial Page Component

import TutorialPage from "./components/TutorialPage/TutorialPage.vue";

// Dummy Tutorial Page

import TutorialPage_Dummy from "./components/DummyUser/TutorialPage_Dummy.vue";

const routes = [
	// Dummy Tutorial Page
	{
		path: "/Tutorial-Page",
		component: TutorialPage_Dummy,
		name: "TutorialPage_Dummy",
	},
	// Tutorial Page
	{
		path: "/tutorialPage",
		component: TutorialPage,
		name: "TutorialPage",
	},
	//  Dummy IB Talks and Dummy Blogs routes
	{
		path: "/the-phoenix-tales",
		component: The_Phoenix_Tales_Dummy_Page,
		name: "The_Phoenix_Tales_DummyPage",
	},
	{
		path: "/all-blogs",
		component: All_Blogs_Dummy_Page,
		name: "AllBlogs_DummyPage",
	},
	{
		path: "/single-blogs/:id",
		component: Blogs_Dummy,
		name: "Blogs_Dummy",
	},
	// IB Talks and Blogs routes
	{
		path: "/IBTalks",
		component: IB_Talks_Page,
		name: "IBTalks",
	},
	{
		path: "/AllBlogs",
		component: All_Blogs,
		name: "AllBlogs",
	},
	{
		path: "/blogs/:id",
		component: Blogs,
		name: "Blogs",
	},
	//Dummy-User pages herr
	{
		path: "/",
		component: DummySignin,
		name: "DummySignin",
	},
	{
		name: "Home",
		component: BodySection,
		path: "/Home",
	},

	{
		name: "Signup",
		component: HelloWorld,
		path: "/sign-up",
	},

	{
		name: "Login",
		component: UserSignin,
		path: "/login",
	},
	{
		name: "ForgotPassword",
		component: ForgotPass,
		path: "/forgotpassword",
	},
	{
		name: "OtpVerification",
		component: OtpVerification,
		path: "/OtpVerification",
	},
	{
		name: "NewPassword",
		component: NewPassword,
		path: "/reset-password",
		props: (route) => ({
			token: route.query.token,
			email: route.query.email,
		}),
	},
	{
		name: "ProfilePage",
		component: ProfilePage,
		path: "/ProfilePage",
	},
	{
		name: "Notification",
		component: Notification,
		path: "/Notification",
	},
	{
		name: "GarbaPage",
		component: GarbaPage,
		path: "/GarbaPage",
	},
	{
		name: "AboutGarbaClass",
		component: AboutGC,
		path: "/AboutGC/:id",
		props: true,
	},

	{
		name: "Subcription",
		component: SubsriptionPage,
		path: "/subscription/:id",
		props: true,
	},
	{
		name: "GC_Id_Post",
		component: GC_Id_Post,
		path: "/GC_Id_Post",
	},
	{
		name: "Subcription_2",
		component: Subsription_Page_2,
		path: "/subsription_Page_2/:id",
	},
	{
		name: "GarbaJockey",
		component: GarbaJockey,
		path: "/GarbaJockey",
	},
	{
		path: "/posts/:id",
		component: Single_Post,
	},

	// {
	//   name:"MenuBar",
	//   components:MenuBar,
	//   path:"/MenuBar"
	// },
	{
		name: "EventPage",
		component: EventPage,
		path: "/eventpage",
	},
	{
		name: "event",
		component: event,
		path: "/event",
	},
	{
		name: "viewevent",
		component: viewevent,
		path: "/viewevent",
	},

	{
		name: "UplodedDetails",
		component: UplodedDetails,
		path: "/UplodedDetails",
	},
	{
		component: HomePage,
		path: "/HomePage",
		children: [
			{
				name: "MyProfile",
				component: MyProfile,
				path: "/HomePage/MyProfile",
			},
			{
				path: "/HomePage/MyPosts",
				component: MyPosts,
				children: [
					{
						name: "userpost",
						component: userpost,
						path: "/HomePage/MyPosts/userpost",
					},
					{
						name: "hiddenpost",
						component: hiddenpost,
						path: "/HomePage/MyPosts/hiddenpost",
					},

					{
						name: "reportedpost",
						component: reportedpost,
						path: "/HomePage/MyPosts/reportedpost",
					},
				],
			},
			{
				name: "MyGarbaClass",
				component: MyGarbaClass,
				path: "/HomePage/MyGarbaClass",
			},
			{
				name: "MyTags",
				component: MyTags,
				path: "/HomePage/MyTags",
			},
			{
				name: "UploadVideo",
				component: UploadVideo,
				path: "/HomePage/UploadVideo",
			},
			{
				name: "ChangePassword",
				component: ChangePassword,
				path: "/HomePage/ChangePassword",
			},
		],
	},

	{
		name: "deletetionPolicy",
		component: deletePolicy,
		path: "/deletionPolicy",
	},
	{
		name: "UserSearch",
		component: UserSearch,
		path: "/usearch",
	},

	{
		name: "Entrant_User_GC",
		component: Entrant_User_GC,
		path: "/Entrant_User_GC",
	},
	{
		name: "Payment_Success",
		component: Payment_Success,
		path: "/Payment_Success",
	},
	{
		name: "privercy policy",
		component: privacypolicy,
		path: "/privacypolicy",
	},
	{
		name: "Contact US",
		component: contactUs,
		path: "/contactUs",
	},

	{
		name: "RaiseTicketNew",
		component: RaiseTicketNew,
		path: "/RaiseTicketNew",
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
