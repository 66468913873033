<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div class="container p-0 m-auto w-75">
      <div
        class="row w-100 d-flex justify-content-center m-0 p-0 position-relative"
        style="top: 8rem"
      >
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <LeftSideContent />

          <LeftAdBanner />
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <!-- <UserPost /> -->
          <div
            class="post w-100 px-2 py-2"
            style="
              background-color: #ffffff;
              border-radius: 12px;
              box-shadow: 0 0 7px #d4d4d4;
              visibility: visible;
            "
          >
            <div class="post_image d-flex justify-content-between">
              <AddVideo />
            </div>
          </div>

          <AllPost :numberOfVideos="2" />

          <infinite-loading @infinite="getPost"></infinite-loading>
          <!-- <UserPost /> -->
        </div>

        <div class="col-12 col-sm-12 col-md-2 col-lg-3">
          <RightSideContent />
        </div>
      </div>
    </div>
  </div>
  <!-- <BodySection/> -->
</template>

<script>
// import moment from "moment";
import NavBar from "./Profile_Screens/NavBar.vue";
// import { ref } from "vue";
// import axios from "@/axios";
import InfiniteLoading from "vue-infinite-loading";
import LeftSideContent from "./LeftSideContent.vue";
import RightSideContent from "./RightSideContent.vue";
import AddVideo from "./Add-Video.vue";
import AllPost from "./AllPost.vue";
import LeftAdBanner from "./LeftAdBanner.vue";

export default {
  name: "GarbaJockey",
  props: {
    postId: String,
    numberOfVideos: {
      type: Number,
      // default: 2, // Set a default value if needed
    },
  },
  components: {
    NavBar,
    InfiniteLoading,
    LeftSideContent,
    RightSideContent,
    AddVideo,
    AllPost,
    LeftAdBanner,
  },
};
</script>

<style scoped>
@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
</style>
