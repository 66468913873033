<template>
  <div v-for="i in banners" :key="i.src" class="img-div" id="left-ad-banners">
    <a @click="redirect(i.link)" style="cursor: pointer">
      <img :src="i.src" class="img-fluid bg-cover" alt="Image" />
    </a>
  </div>
</template>

<script>
export default {
  name: "LeftAdBanner",
  data() {
    return {
      banners: [
        {
          src: require("../assets/images/Ad_Ib.png"),
          link: "https://play.google.com/store/apps/details?id=com.infinitybrains&hl=en_US",
        },
        {
          src: require("../assets/images/Ad_Ai.png"),
          link: "https://infinitybrains.com/",
        },
        {
          src: require("../assets/images/Ad_Delight.png"),
          link: "https://play.google.com/store/apps/details?id=com.ibdelight&hl=en_US",
        },
        {
          src: require("../assets/images/Ad_SMK.png"),
          link: "https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en_US",
        },
        {
          src: require("../assets/images/Ad_Ib_Hrms.png"),
          link: "https://play.google.com/store/apps/details?id=com.ibhrms&hl=en_US",
        },
      ],
      single_banner: null,
      // maxBanner: 15,
    };
  },
  computed: {
    // randomBanners() {
    //   return Array.from(
    //     {
    //       length: this.maxBanner,
    //     },
    //     () => this.getRandomBanner()
    //   );
    // },
  },
  methods: {
    // getRandomBanner() {
    //   const randomIndex = Math.floor(Math.random() * this.banners.length);
    //   this.single_banner = this.banners[randomIndex];
    //   return this.single_banner;
    // },
    // handleWindowResize() {
    //   // Update maxBanner based on the screen width
    //   if (window.innerWidth < 1201) {
    //     this.maxBanner = 18;
    //     // console.log("Banner Size less Than 1201: ", this.maxBanner);
    //   } else {
    //     this.maxBanner = 14;
    //     // console.log("Banner Size greater Than 1201: ", this.maxBanner);
    //   }
    // },
    redirect(url) {
      // console.log("Url: ", url);
      window.location.href = url;
    },
  },
  created() {
    // Set initial maxBanner value
    // this.handleWindowResize();
    // // Add event listener for window resize
    // window.addEventListener("resize", this.handleWindowResize);
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((element) => {
        if (element.isIntersecting) {
          element.target.classList.add("animated-anim");
          observer.unobserve(element.target);
        }
      });
    });

    this.$nextTick(() => {
      document
        .querySelectorAll("#left-ad-banners")
        .forEach((div) => observer.observe(div));
    });
  },
  unmounted() {
    // Remove event listener when component is destroyed
    // window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style scoped>
.img-div {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  margin: 6rem auto;
  transition: box-shadow 0.3s;
}

.animated-anim {
  animation: fadeInLeft;
  animation-duration: 1.2s;
}

.img-div img {
  height: 24.5rem;
  transition: transform 0.5s;
}

.img-div:hover {
  box-shadow: 0px 0px 0.5px #bab9b9;
}

.img-div:hover,
.img-div img {
  transform: scale(1.018);
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .img-div img {
    height: 19rem;
    transition: transform 0.5s;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
}
</style>
