<template>
  <div class="dropdown">
    <!-- style="width: 25%; margin-left: 92%; margin-top: -11%; margin-bottom: 10%" -->
    <button
      class="dropdown"
      type="button"
      id="dropdownMenuButton1"
      @click="this.$router.push({ name: 'Login' })"
      style="border: none; background: none"
    >
      <!-- <img src="images/Icons/Group 2108.svg" width="20" height="15" /> -->
      <i class="bi bi-three-dots"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "lisT",
  data() {
    return {};
  },

  props: {
    postId: String,
    itemIndex: Number,
  },

  methods: {},
};
</script>

<style scoped>
.dropdown-item {
  font-size: 1rem;
}
</style>
