import { createApp } from "vue";
import App from "./App.vue";
import InfiniteLoading from "vue-infinite-loading";
import router from "./routes";
import axios from "axios";

// Import Animate.css Here
import "animate.css";

import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "bootstrap";

import store from "./store/store";

// Retrieve user type from localStorage
const userType = localStorage.getItem("userType");

// Commit the user type to the Vuex store if it exists
if (userType) {
	store.commit("setUserType", userType);
}

const app = createApp(App).use(router);
app.component("InfiniteLoading", InfiniteLoading);

app.use(store);

app.mount("#app");

// axios.defaults.baseURL = "https://api.garbajockey.com/api";
// axios.defaults.baseURL = https://uatapi.garbajockey.com/api/;

axios.defaults.headers.common["Authorization"] =
	" Bearer " + localStorage.getItem("token");

// createApp(App).use(router).mount("#app");
