<template>
  <div class="custom-animation-gj">
    <NavBar />

    <div class="container w-75" style="margin: 7rem auto">
      <div class="row d-flex justify-centent-center">
        <div class="col-md-12 p-0">
          <HomeProfile />
        </div>

        <div
          class="col-md-3 menu-left p-3 mt-3"
          style="
            box-shadow: 0px 0px 9px -4px purple;
            background: rgb(255 221 250);
          "
        >
          <p class="font-weight-bold border-bottom pb-3">Menu</p>

          <router-link
            exact
            to="/HomePage/MyProfile"
            @click="colorChangeProfile"
            class="text-decoration-none"
          >
            <p
              class="MyProfile"
              :class="{
                'text-danger': activeProfile,
                'text-muted': !activeProfile,
              }"
            >
              My Profile
            </p>
          </router-link>
          <hr class="mt-0 mb-2" />
          <router-link
            to="/HomePage/MyPosts/userpost"
            @click="colorChangePost"
            class="text-decoration-none"
          >
            <p
              class="Posts"
              :class="{ 'text-danger': activePost, 'text-muted': !activePost }"
            >
              Posts
            </p>
          </router-link>
          <hr class="mt-0 mb-2" />
          <router-link
            to="/HomePage/MyTags"
            @click="colorChangeTag"
            class="text-decoration-none"
          >
            <p
              class="Tags"
              :class="{ 'text-danger': activeTag, 'text-muted': !activeTag }"
            >
              Tags
            </p>
          </router-link>
          <hr class="mt-0 mb-2" />
          <router-link
            to="/HomePage/MyGarbaClass"
            @click="colorChangeGarba"
            class="text-decoration-none"
          >
            <p
              class="MyGarbaClass"
              :class="{
                'text-danger': activeGarba,
                'text-muted': !activeGarba,
              }"
            >
              My Garba Class
            </p>
          </router-link>
          <hr class="mt-0 mb-2" />
          <router-link
            v-if="$store.state.userType === '3'"
            to="/HomePage/UploadVideo"
            @click="colorChangeUpload"
            class="text-decoration-none"
          >
            <p
              class="UploadVideo"
              :class="{
                'text-danger': activeUpload,
                'text-muted': !activeUpload,
              }"
            >
              Upload Video
            </p>
          </router-link>
          <hr v-if="$store.state.userType === '3'" class="mt-0 mb-2" />
          <router-link
            to="/HomePage/ChangePassword"
            @click="colorChangePassword"
            class="text-decoration-none"
          >
            <p
              class="ChangePassword"
              :class="{
                'text-danger': activePassword,
                'text-muted': !activePassword,
              }"
            >
              Change Password
            </p>
            <MyGarba />
          </router-link>
          <hr class="mt-0 mb-2" />
        </div>

        <div
          class="menu-right col-md-9 p-3 mt-3"
          style="box-shadow: 0px 0px 9px -4px purple"
        >
          <!-- <MyProfile /> -->
          <router-view class="custom-animation-gj" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
import HomeProfile from "./HomeProfile.vue";
// import MyProfile from "./View_Profile_List/MyProfile.vue";

export default {
  name: "HomePage",
  components: {
    NavBar,
    HomeProfile,
    // MyProfile
  },
  data() {
    return {
      activeProfile: false,
      activePost: false,
      activeTag: false,
      activeGarba: false,
      activeUpload: false,
      activePassword: false,
    };
  },
  methods: {
    colorChangeProfile() {
      this.activeProfile = !this.activeProfile;
      this.activePost = false;
      this.activeTag = false;
      this.activeGarba = false;
      this.activeUpload = false;
      this.activePassword = false;
    },
    colorChangePost() {
      this.activePost = !this.activePost;
      this.activeProfile = false;
      this.activeTag = false;
      this.activeGarba = false;
      this.activeUpload = false;
      this.activePassword = false;
    },
    colorChangeTag() {
      this.activePost = false;
      this.activeProfile = false;
      this.activeTag = !this.activeTag;
      this.activeGarba = false;
      this.activeUpload = false;
      this.activePassword = false;
    },
    colorChangeGarba() {
      this.activePost = false;
      this.activeProfile = false;
      this.activeTag = false;
      this.activeGarba = !this.activeGarba;
      this.activeUpload = false;
      this.activePassword = false;
    },
    colorChangeUpload() {
      this.activePost = false;
      this.activeProfile = false;
      this.activeTag = false;
      this.activeGarba = false;
      this.activeUpload = !this.activeUpload;
      this.activePassword = false;
    },
    colorChangePassword() {
      this.activePost = false;
      this.activeProfile = false;
      this.activeTag = false;
      this.activeGarba = false;
      this.activeUpload = false;
      this.activePassword = !this.activePassword;
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) and (max-width: 1199px) {
  .container {
    margin: 7rem auto !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
</style>
