<!-- UservieW.vue -->
<template>
  <div class="d-flex align-items-center me-1">
    <button
      type="button"
      class="btn-sm p-0 d-flex align-items-center"
      style="width: 25%; background: none; border: none"
      @click="handleViewCountIncrement"
    >
      <i class="fa fa-eye" style="font-size: 1.2rem"></i>
      <span class="text-dark mx-1 px-2" style="font-size: 1rem">{{
        localViewsCount
      }}</span>
    </button>
  </div>
</template>
  
<script>
export default {
  props: {
    postId: String,
    views_count: String,
  },
  data() {
    return {
      localViewsCount: this.views_count,
      timerId: null, // Store the timer ID
    };
  },
  methods: {
    handleViewCountIncrement() {
      if (!this.hasBeenViewed()) {
        this.$emit("increment-view-count", this.postId);
        this.localViewsCount++;

        // Set a timer to increment the view count after 5 seconds
        this.timerId = setTimeout(() => {
          this.localViewsCount++;
          this.updateLocalStorage();
        }, 5000);
      }
    },
    hasBeenViewed() {
      return localStorage.getItem(`viewed_${this.postId}`) === "true";
    },
    updateLocalStorage() {
      localStorage.setItem(`viewed_${this.postId}`, "true");
    },
  },
  beforeUnmount() {
    // Clear the timer when the component is unmounted
    clearTimeout(this.timerId);
  },
  mounted() {
    if (this.hasBeenViewed()) {
      this.localViewsCount++;
    }
  },
};
</script>
  