<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="video-show-container">
          <div
            v-for="gc_video in ShowVideos.slice(0, 1)"
            :key="gc_video.id"
            class="mb-4"
          >
            <div
              class="overlay d-flex align-items-center justify-content-center"
              @click="toggleVideo(gc_video)"
            >
              <div
                class="overlay-img d-flex justify-content-center align-items-center w-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="78"
                  height="80"
                  viewBox="0 0 78 80"
                >
                  <g
                    id="Group_423"
                    data-name="Group 423"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Ellipse_80"
                      data-name="Ellipse 80"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="6"
                    >
                      <ellipse cx="39" cy="40" rx="39" ry="40" stroke="none" />
                      <ellipse cx="39" cy="40" rx="36" ry="37" fill="none" />
                    </g>
                    <text
                      id="_1"
                      data-name="1"
                      transform="translate(28 55.001)"
                      fill="#fff"
                      font-size="43"
                      font-family="SegoeUI-Semibold, Segoe UI"
                      font-weight="600"
                    >
                      <tspan x="0" y="0">1</tspan>
                    </text>
                  </g>
                </svg>
              </div>
              <div>
                <video
                  :src="gc_video.video"
                  controls
                  type="video/mp4"
                  v-if="gc_video.video && gc_video.video.length > 0"
                  :id="'video-' + gc_video.id"
                  class="single-video w-100"
                ></video>
              </div>
            </div>
          </div>

          <div class="video-container">
            <div
              v-for="gc_videos in ShowVideos.slice(0, 3)"
              :key="gc_videos.id"
              class="video-list mb-3"
            >
              <div class="d-flex align-items-center">
                <img
                  v-if="gc_videos && gc_videos.video_preview_photo"
                  :src="gc_videos.video_preview_photo"
                  alt="video preview image"
                  class="rounded-3 w-25"
                  style="height: 5rem"
                />
                <div class="d-flex flex-column">
                  <div class="ml-3">
                    <p>{{ gc_videos.garba_class.garba_class_name }}</p>
                    <p>{{ gc_videos.title }}</p>
                  </div>
                  <div
                    class="d-flex align-items-center position-absolute end-0 mx-3"
                  >
                    <i class="bi bi-pencil-square mx-2"></i>
                    <i class="bi bi-trash3"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="readMore"
              v-for="gc_videos in ShowVideos"
              :key="gc_videos.id"
              class="video-list mb-3"
            >
              <div class="d-flex align-items-center">
                <img
                  v-if="gc_videos && gc_videos.video_preview_photo"
                  :src="gc_videos.video_preview_photo"
                  alt="video preview image"
                  class="rounded-3 w-25"
                  style="height: 5rem"
                />
                <div class="d-flex flex-column">
                  <div class="ml-3">
                    <p>{{ gc_videos.garba_class.garba_class_name }}</p>
                    <p>{{ gc_videos.title }}</p>
                  </div>
                  <div
                    class="d-flex align-items-center position-absolute end-0 mx-3"
                  >
                    <i class="bi bi-pencil-square mx-2"></i>
                    <i class="bi bi-trash3"></i>
                  </div>
                </div>
              </div>
            </div>

            <p @click="readMore = !readMore">
              <span v-if="readMore" class="cursor-pointer"
                >See less videos ...</span
              >
              <span v-else class="cursor-pointer">See more videos ...</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="upload_video_section">
          <p class="m-0 fs-4 fw-semibold">Video Title</p>
          <div style="border-bottom: 2px solid black; width: 35%"></div>

          <input
            class="form-control add_video_title my-3"
            type="text"
            placeholder="Add video title here"
            v-model="title"
          />

          <div
            class="select_video d-flex justify-content-center align-items-center my-3 p-3"
            style="border: dotted; border-color: red"
          >
            <div v-if="videoPreview" class="position-relative">
              <video :src="videoPreview" class="w-100" controls></video>
              <button
                type="button"
                class="close position-absolute top-0 end-0"
                aria-label="Close"
                @click="removeVideo"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div v-else>
              <input
                type="file"
                ref="inputVideo"
                accept="video/*"
                @change="Gc_handleFileSelected"
                style="display: none"
              />
              <img
                src="../assets/video_icon.svg"
                class="cursor-pointer"
                @click="openFileInput('video')"
              />
              <span @click="openFileInput('video')" style="cursor: pointer"
                >Tap here to select video</span
              >
            </div>
          </div>

          <div
            class="select_video d-flex justify-content-center align-items-center my-3 p-3"
            style="border: dotted; border-color: red"
          >
            <div v-if="image_preview" class="position-relative">
              <img :src="image_preview" alt="Selected Image" class="w-100" />
              <button
                type="button"
                class="close position-absolute top-0 end-0"
                aria-label="Close"
                @click="removeImage"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div v-else>
              <input
                type="file"
                ref="inputImage"
                accept="image/*"
                @change="Gc_handleFileSelected"
                style="display: none"
              />
              <img
                src="../assets/video_icon.svg"
                class="cursor-pointer"
                @click="openFileInput('image')"
              />
              <span @click="openFileInput('image')" style="cursor: pointer"
                >Tap here to upload preview Photo</span
              >
            </div>
          </div>

          <div class="mb-3">
            <p class="m-0 fs-4 fw-semibold">Description</p>
            <div style="border-bottom: 2px solid black; width: 35%"></div>

            <textarea
              class="form-control my-3"
              cols="35"
              rows="6"
              v-model="description"
            ></textarea>
          </div>

          <div
            v-for="garbaclass in GarbaClasses.slice(0, 1)"
            :key="garbaclass.id"
          >
            <button
              class="btn btn-danger"
              style="width: 100%"
              @click="uploadVideo(garbaclass.id)"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "UploadVideo",
  data() {
    return {
      GarbaClasses: [],
      ShowVideos: [],
      first_name: "",
      last_name: "",
      item: [],
      video: null,
      title: "",
      description: "",
      sr_no: 1,
      gc_video: null,
      gc_id: "",
      readMore: false,
      userType: "",
      showFileInput: false,
      videoPreview: "",
      image: "",
      image_preview: "",
      last_page: "",
    };
  },
  mounted() {
    this.userType = this.$store.state.userType;

    if (this.userType) {
      axios
        .get("gc/garba-class-videos?page=2")
        .then((response) => {
          const latestVideo = response.data || [];

          this.last_page = latestVideo.last_page;
          this.ShowGcVideos();
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
      this.GetMyGc();
    }
  },

  methods: {
    openFileInput(type) {
      if (type === "video" && this.$refs.inputVideo) {
        this.$refs.inputVideo.click();
      } else if (type === "image" && this.$refs.inputImage) {
        this.$refs.inputImage.click();
      }
    },

    Gc_handleFileSelected(event) {
      const file = event.target.files[0];

      if (file.type.startsWith("video/")) {
        // Handle video file
        this.video = file;
        this.videoPreview = URL.createObjectURL(file);
        // console.log(this.video);
        // console.log(this.video.type);
      } else if (file.type.startsWith("image/")) {
        // Handle image file
        this.image = file;
        this.image_preview = URL.createObjectURL(file);
        // console.log("image = ", this.image);
        // console.log("image type = ", this.image.type);
      }

      this.showFileInput = false;
    },

    // Gc_preview_file_handle(event) {
    //   // const file = this.$refs.inputImage.files[0];
    //   const file = event.target.files[0];
    //   if (file.type.startsWith('video/')) {
    //     this.videoPreview = URL.createObjectURL(file);
    //     console.log(this.videoPreview);

    //   } else if (file.type.startsWith('image/')) {
    //     // Handle image file
    //     this.image_preview = URL.createObjectURL(file);
    //     console.log("IMage preview type:", this.image_preview);
    //   }
    // },

    async uploadVideo(id) {
      const formData = new FormData();
      formData.append("garba_class_id", id);
      formData.append("title", this.title);
      formData.append("description", this.description);

      if (this.video) {
        formData.append("video", this.video);
      }

      if (this.image) {
        formData.append("video_preview", this.image);
      }

      formData.append("sr_no", this.sr_no);
      this.sr_no++;

      try {
        // const result =
        await axios.post(`gc/garba-class-videos`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // console.log("Media uploaded successfully", result);
        // Reset the form fields after successful upload
        this.resetFields();
        setTimeout(() => {
          this.$router.go();
        }, 860);
      } catch (error) {
        // alert(error.response.data.message);
        alert("Garba Class Video Uploaded Successfully !!!");
        setTimeout(() => {
          this.$router.go();
        }, 860);
      }
    },

    resetFields() {
      this.video = null;
      this.title = "";
      this.description = "";
      this.image = null;
      this.image_preview = "";
      this.videoPreview = "";
    },

    GetMyGc() {
      axios
        .get("gc/my-garba-classes")
        .then((response) => {
          this.GarbaClasses = response.data.data.garba_classes || [];
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },

    ShowGcVideos() {
      axios
        .get(`gc/garba-class-videos?page=${this.last_page}`)
        .then((response) => {
          const latestVideo = response.data.data || [];

          if (latestVideo.length > 0) {
            // Reverse the order of the latestVideo array and assign it to this.ShowVideos
            this.ShowVideos = latestVideo.slice().reverse();
            // console.log("Show video = ", this.ShowVideos);
            this.sr_no = this.ShowVideos[0].sr_no
              ? ++this.ShowVideos[0].sr_no
              : 1;
            // console.log("Sr_No: ", this.sr_no);
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    },

    toggleVideo(gc_video) {
      const videoElement = document.querySelector(`#video-${gc_video.id}`);
      if (videoElement) {
        if (videoElement.paused) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }
    },

    removeVideo() {
      this.video = null;
      this.videoPreview = "";
    },

    removeImage() {
      this.image_preview = "";
      if (this.$refs.inputImage) {
        this.$refs.inputImage.value = null;
      }
    },
  },
};
</script>

<style scoped>
/* Add custom styles if needed */

.single-video {
  position: relative;
  z-index: -10;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.overlay-img {
  position: absolute;
  z-index: -1;
}

.selected-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
