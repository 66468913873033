<template>
  <div class="vue-model" v-show="open">
    <div
      class="vue-model-inner d-flex justify-content-center align-items-center w-100 my-5"
    >
      <div
        class="vue-model-content d-fle justify-content-center align-otems-center w-50 my-5"
      >
        <slot />
        <img
          src="images/Icons/plus_icon1.svg"
          class="close-btn"
          @click="close"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalVideo",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const close = () => {
      emit("close");
    };
    return { close };
  },
};
</script>
<style scoped>
.vue-model-content p {
  margin: 10px 10px 10px;
}

::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.vue-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.vue-model-inner {
  color: black !important;
}

.vue-model-content {
  position: relative;
  background-color: #ffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border-radius: 0.3rem;
  top: 0;
  left: 0;
}

.vue-model-content.model-inner-img {
  width: 20%;
}

.close-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  cursor: pointer;
  right: 10px;
}

@media (max-width: 750px) {
  .vue-model-content {
    width: 75% !important;
  }
}

@media (max-width: 650px) {
  .vue-model-content {
    width: 100% !important;
    margin: 0 23px;
  }
}

@media (min-width: 1200px) {
  .vue-model-content {
    width: 35% !important;
  }
}
@media (min-width: 1800px) {
  .vue-model-content {
    width: 25% !important;
  }
}
</style>
