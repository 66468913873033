<template>
  <div class="custom-animation-gj">
    <NavBar />

    <div class="container" style="margin: 7rem auto">
      <div class="row">
        <div
          class="col-12 rounded-3 p-2"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <h1 class="fs-5">Create Event</h1>
        </div>
      </div>

      <div class="row mt-4">
        <div
          class="col-md-6 rounded-3 p-3"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <h2 class="fs-5">Upload Event Details</h2>
          <hr />

          <div class="event-details">
            <div class="row">
              <div class="col">
                <label for="eventName" class="form-label text-danger"
                  >Event Name*</label
                >
                <input
                  v-model="eventData.eventName"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="col">
                <label for="organizerName" class="form-label text-danger"
                  >Organizer Name*</label
                >
                <input
                  v-model="eventData.organizerName"
                  type="text"
                  class="form-control"
                  id="form-organizer"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-label text-danger" for="eventPoster"
                  >Event Poster</label
                >
                <input
                  @change="handleFileChange('eventPoster')"
                  type="file"
                  class="form-control"
                  name="file"
                  id="file"
                  ref="eventPoster"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="eventDescription" class="form-label text-danger"
                  >Event Description</label
                >
                <input
                  v-model="eventData.eventDescription"
                  type="text"
                  class="form-control"
                  id="form-eventDescription"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="eventFee" class="form-label text-danger"
                  >Event Fee</label
                >
                <input
                  v-model="eventData.eventFee"
                  type="number"
                  min="1"
                  max="1000000"
                  class="form-control"
                  id="form-eventFee"
                  required
                />
              </div>
              <div class="col">
                <label for="eventPass" class="form-label text-danger"
                  >Event Pass</label
                >
                <input
                  v-model="eventData.eventPass"
                  type="text"
                  class="form-control"
                  id="form-eventPass"
                  required
                />
              </div>
            </div>

            <div class="row">
              <!-- <div class="col">
                            <label for="eventCountry" class="form-label text-danger">Event Country</label>
                            <input v-model="eventData.eventCountry" type="text" class="form-control" id="form-eventCountry"
                                required>
                        </div> -->
              <!-- <div class="col">
                            <label for="eventState" class="form-label text-danger">Event State</label>
                            <input v-model="eventData.eventState" type="text" class="form-control" id="form-eventState"
                                required>
                        </div> -->
              <div class="col">
                <label for="eventCountry" class="form-label text-danger"
                  >Event Country</label
                >
                <select
                  v-model="eventData.eventCountry"
                  @change="loadStates"
                  class="form-control"
                  id="form-eventCountry"
                  required
                >
                  <option disabled value="">Select Country</option>
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label for="eventState" class="form-label text-danger"
                  >Event State</label
                >
                <select
                  v-model="eventData.eventState"
                  @change="loadCities"
                  class="form-control"
                  id="form-eventState"
                  required
                >
                  <option disabled value="">Select State</option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col">
                            <label for="eventCity" class="form-label text-danger">Event City</label>
                            <input v-model="eventData.eventCity" type="text" class="form-control" id="form-eventCity"
                                required>
                        </div> -->
              <div class="col">
                <label for="eventCity" class="form-label text-danger"
                  >Event City</label
                >
                <select
                  v-model="eventData.eventCity"
                  class="form-control"
                  id="form-eventCity"
                  required
                >
                  <option disabled value="">Select City</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label for="eventCityPinCode" class="form-label text-danger"
                  >Event City Pin-Code</label
                >
                <input
                  v-model="eventData.eventCityPinCode"
                  type="text"
                  class="form-control"
                  id="form-eventCityPinCode"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="eventStartDate" class="form-label text-danger"
                  >Start Date</label
                >
                <input
                  v-model="eventData.eventStartDate"
                  type="date"
                  class="form-control"
                  id="form-eventStartDate"
                  required
                />
              </div>
              <div class="col">
                <label for="eventEndDate" class="form-label text-danger"
                  >End Date</label
                >
                <input
                  v-model="eventData.eventEndDate"
                  type="date"
                  class="form-control"
                  id="form-eventEndDate"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-label text-danger" for="eventIdProof"
                  >upload Orgnazation Id Proof</label
                >
                <input
                  @change="handleFileChange('eventIdProof')"
                  type="file"
                  name="form-eventIdProof"
                  class="form-control"
                  id="form-eventIdProof"
                  ref="eventIdProof"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-label text-danger" for="eventYears"
                  >Organizing since how many years?*</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="pincode"
                  maxlength="4"
                  id="form-eventYears"
                  pattern="\d{4}"
                  required
                  v-model="eventData.eventYears"
                />
                <!-- <select
                v-model="eventData.eventYears"
                name="form-eventYears"
                id="form-eventYears"
                class="form-control"
              >
                <option disabled value="">Select number of years</option>
                <option value="1">1 year</option>
                <option value="2">2 years</option>
                <option value="3">3 years</option>
                <option value="4">4 years</option>
              </select> -->
              </div>
              <div class="col">
                <label for="eventMobileNumber" class="form-label text-danger"
                  >Mobile Number</label
                >
                <input
                  v-model="eventData.eventMobileNumber"
                  type="number"
                  class="form-control"
                  id="form-eventMobileNumber"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="eventLYAttend" class="form-label text-danger"
                  >last years attendance</label
                >
                <input
                  v-model="eventData.eventLYAttend"
                  type="number"
                  class="form-control"
                  id="form-eventLYAttend"
                  min="1"
                  max="1000000"
                  required
                />
              </div>
              <div class="col">
                <label for="eventCapaxityNo" class="form-label text-danger"
                  >capacity(number)</label
                >
                <input
                  v-model="eventData.eventCapaxityNo"
                  type="number"
                  class="form-control"
                  id="form-eventCapaxityNo"
                  min="1"
                  max="1000000"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-label text-danger" for="eventPassImg"
                  >upload pass image</label
                >
                <input
                  @change="handleFileChange('eventPassImg')"
                  type="file"
                  name="form-eventPassImg"
                  class="form-control"
                  id="form-eventPassImg"
                  ref="eventPassImg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 rounded-3 p-3 shadow">
          <h2 class="fs-5">Upload Bank Details</h2>
          <hr />

          <div class="bank-details">
            <!-- <div class="row mb-3">
                        <div class="col">
                            <label for="eventAddressProof" class="form-label text-danger">Event Address Proof</label>
                            <input v-model="bankData.eventAddressProof" type="text" class="form-control"
                                id="form-eventAddressProof" required>
                        </div>
                    </div> -->

            <div class="row">
              <div class="col">
                <label class="form-label text-danger" for="eventAddressProof"
                  >Event Address Proof</label
                >
                <input
                  @change="handleFileChange('eventAddressProof')"
                  type="file"
                  name="form-eventAddressProof"
                  class="form-control"
                  id="form-eventAddressProof"
                  ref="eventAddressProof"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label for="eventProof" class="form-label text-danger"
                  >Event Proof</label
                >
                <input
                  v-model="bankData.eventProof"
                  type="text"
                  class="form-control"
                  id="form-eventProof"
                  required
                />
              </div>
            </div>

            <!-- <div class="row">
                        <div class="col">
                            <label class="form-label text-danger" for="eventLogo">Event Logo</label>
                            <input @change="handleFileChange('eventLogo')" type="file" name="form-eventLogo"
                                class="form-control" id="form-eventLogo" ref="eventLogo">
                        </div>
                    </div> -->

            <div class="row mb-3">
              <div class="col">
                <label for="eventBankBranchName" class="form-label text-danger"
                  >Bank Name</label
                >
                <input
                  v-model="bankData.eventBankBranchName"
                  type="text"
                  class="form-control"
                  id="form-eventBankBranchName"
                  name="form-eventBankBranchName"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label
                  for="eventBankAccountHolderName"
                  class="form-label text-danger"
                  >Account Holder Name</label
                >
                <input
                  v-model="bankData.eventBankAccountHolderName"
                  type="text"
                  class="form-control"
                  id="form-eventBankAccountHolderName"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label
                  for="eventBankAccountNumber"
                  class="form-label text-danger"
                  >Account Number</label
                >
                <input
                  v-model="bankData.eventBankAccountNumber"
                  type="number"
                  min="1"
                  max="12"
                  class="form-control"
                  id="form-eventBankAccountNumber"
                  name="form-eventBankAccountNumber"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label for="eventBankIfcCode" class="form-label text-danger"
                  >IFC Code</label
                >
                <input
                  v-model="bankData.eventBankIfcCode"
                  type="text"
                  class="form-control"
                  id="form-eventBankIfcCode"
                  name="form-eventBankIfcCode"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label
                  for="eventBankBranchAddress"
                  class="form-label text-danger"
                  >Bank Address</label
                >
                <input
                  v-model="bankData.eventBankBranchAddress"
                  type="text"
                  class="form-control"
                  id="form-eventBankBranchAddress"
                  name="form-eventBankBranchAddress"
                  required
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-label text-danger" for="eventUploadBankProof"
                  >upload Bank Proof</label
                >
                <input
                  @change="handleFileChange('eventUploadBankProof')"
                  type="file"
                  name="form-eventUploadBankProof"
                  class="form-control"
                  id="form-eventUploadBankProof"
                  ref="eventUploadBankProof"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <div class="form-check">
                  <input
                    v-model="confirmCheckbox"
                    type="checkbox"
                    class="form-check-input"
                    id="form-eventCheckbox"
                    required
                  />
                  <label
                    class="form-check-label text-danger"
                    for="eventCheckbox"
                  >
                    Please confirm that you agree to our privacy policy & terms
                  </label>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <!-- Display error message -->
              <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>

              <div class="col-12">
                <button
                  class="btn btn-danger w-100"
                  v-on:click="postEvent"
                  role="link"
                >
                  Post Event
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./Profile_Screens/NavBar.vue";
import axios from "@/axios";

export default {
  name: "event",
  components: {
    NavBar,
  },
  data() {
    return {
      countries: [],
      states: [],
      cities: [],
      eventData: {
        eventName: "",
        organizerName: "",
        eventPoster: null,
        eventDescription: "",
        eventFee: "",
        eventPass: "",
        eventCountry: "101",
        eventState: "",
        eventCity: "",
        eventCityPinCode: "",
        eventStartDate: "",
        eventEndDate: "",
        eventIdProof: null,
        eventYears: "",
        eventMobileNumber: "",
        eventLYAttend: "",
        eventCapaxityNo: "",
        eventPassImg: null,
      },
      bankData: {
        // eventAddressProof: '',
        eventAddressProof: null,
        eventProof: "",
        eventBankAccountHolderName: "",
        eventBankAccountNumber: "",
        eventBankBranchName: "",
        eventBankIfcCode: "",
        eventBankBranchAddress: "",
        eventUploadBankProof: null,
      },
      confirmCheckbox: false,
      errorMessage: "",
    };
  },

  mounted() {
    // Load countries on component mount
    this.loadCountries();
  },

  watch: {
    "eventData.eventCountry": "loadStates",
    "eventData.eventState": "loadCities",
  },

  methods: {
    async postEvent() {
      try {
        if (
          !this.eventData.eventName ||
          !this.eventData.organizerName ||
          !this.eventData.eventPoster ||
          !this.eventData.eventDescription ||
          !this.eventData.eventFee ||
          !this.eventData.eventPass ||
          !this.eventData.eventCountry ||
          !this.eventData.eventState ||
          !this.eventData.eventCity ||
          !this.eventData.eventCityPinCode ||
          !this.eventData.eventStartDate ||
          !this.eventData.eventEndDate ||
          !this.eventData.eventIdProof ||
          !this.eventData.eventYears ||
          !this.eventData.eventMobileNumber ||
          !this.eventData.eventLYAttend ||
          !this.eventData.eventCapaxityNo ||
          !this.eventData.eventPassImg ||
          !this.bankData.eventAddressProof ||
          !this.bankData.eventProof ||
          !this.bankData.eventBankBranchName ||
          !this.bankData.eventBankAccountHolderName ||
          !this.bankData.eventBankAccountNumber ||
          !this.bankData.eventBankIfcCode ||
          !this.bankData.eventBankBranchAddress ||
          !this.bankData.eventUploadBankProof ||
          !this.confirmCheckbox
        ) {
          // Set error message
          this.errorMessage = "Please fill in all required fields.";

          // Clear the error message after 5 seconds
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);

          // Return to stop further execution
          return;
        } else {
          const formData = new FormData();

          // Append event data to FormData with correct field names
          formData.append("event_name", this.eventData.eventName);
          formData.append("organizer_name", this.eventData.organizerName);
          formData.append("event_logo", this.eventData.eventPoster);
          formData.append("event_description", this.eventData.eventDescription);
          formData.append("entry_pass_amount", this.eventData.eventFee);
          formData.append("pass_availibilities", this.eventData.eventPass);
          formData.append("country_id", this.eventData.eventCountry);
          formData.append("state_id", this.eventData.eventState);
          formData.append("city_id", this.eventData.eventCity);
          formData.append("pin_code", this.eventData.eventCityPinCode);
          formData.append("started_at", this.eventData.eventStartDate);
          formData.append("ended_at", this.eventData.eventEndDate);
          formData.append("organization_id_proof", this.eventData.eventIdProof);
          formData.append("organizing_since", this.eventData.eventYears);
          formData.append("organizer_mobile", this.eventData.eventMobileNumber);
          formData.append("last_year_attendance", this.eventData.eventLYAttend);
          formData.append("capacity", this.eventData.eventCapaxityNo);
          formData.append("entry_pass_image", this.eventData.eventPassImg);

          // Append bank data to FormData with correct field names
          formData.append(
            "event_address_proof",
            this.bankData.eventAddressProof
          );
          formData.append("address", this.bankData.eventProof);
          formData.append("bank_name", this.bankData.eventBankBranchName);
          formData.append(
            "bank_account_holder_name",
            this.bankData.eventBankAccountHolderName
          );
          formData.append(
            "bank_account_no",
            this.bankData.eventBankAccountNumber
          );
          formData.append("bank_ifsc_code", this.bankData.eventBankIfcCode);
          formData.append("bank_address", this.bankData.eventBankBranchAddress);
          formData.append(
            "bank_detail_photo",
            this.bankData.eventUploadBankProof
          );

          // Append confirmCheckbox value
          if (this.confirmCheckbox == true) {
            let termCondition = 1;
            formData.append("accept_terms_and_conditions", termCondition);
          } else {
            let termCondition = 0;
            formData.append("accept_terms_and_conditions", termCondition);
          }

          const userType = this.$store.state.userType;
          // console.log("User type at the beginning:", userType);

          let endpoint = "";
          switch (userType) {
            case "1":
              endpoint = "entrant/events";
              break;
            case "3":
              endpoint = "gc/events";
              break;
            case "2":
              endpoint = "gj/events";
              break;

            // Add more cases for other user types as needed

            default:
              endpoint = "default-profile";
              break;
          }

          // Make POST request
          await axios.post(endpoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          // Show success message
          // Reset the form after successful post
          this.resetForm();

          alert("Event posted successfully!");
        }
      } catch (error) {
        console.error("Error posting event:", error);
      }
    },

    handleFileChange(fieldName) {
      const fileInput = this.$refs[fieldName];

      if (fileInput.files.length > 0) {
        const file = fileInput.files[0];

        // Update the corresponding property in eventData or bankData
        if (fieldName in this.eventData) {
          this.eventData[fieldName] = file;
        } else if (fieldName in this.bankData) {
          this.bankData[fieldName] = file;
        } else {
          console.error(
            `Field ${fieldName} not found in eventData or bankData`
          );
        }
      }
    },

    async loadCountries() {
      try {
        let allCountries = [];
        let shouldContinue = true;
        let page = 1;

        while (shouldContinue) {
          const response = await axios.get(`countries?page=${page}`);
          // console.log("Response:", response); // Log the response

          const countries = response.data && response.data.data;

          if (!countries || countries.length === 0) {
            // No more countries to fetch, break the loop
            shouldContinue = false;
          } else {
            allCountries = [...allCountries, ...countries];
            page++;
          }
        }

        this.countries = allCountries;
        // console.log("All Countries:", this.countries); // Log the final list of countries
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    },

    async loadStates() {
      try {
        const response = await axios.get(
          `states_of_country?country_id=${this.eventData.eventCountry}`
        );
        this.states = response.data.data; // Update states array
        // console.log("states = ", this.states);
        this.eventData.eventState = ""; // Reset state value
        this.eventData.eventCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading states:", error);
      }
    },

    async loadCities() {
      try {
        const response = await axios.get(
          `cities_of_state?state_id=${this.eventData.eventState}`
        );
        this.cities = response.data.data; // Update cities array
        // console.log("states = ", this.cities);
        this.eventData.eventCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading cities:", error);
      }
    },

    resetForm() {
      this.eventData = {
        eventName: "",
        organizerName: "",
        eventPoster: null,
        eventDescription: "",
        eventFee: "",
        eventPass: "",
        eventCountry: "",
        eventState: "",
        eventCity: "",
        eventCityPinCode: "",
        eventStartDate: "",
        eventEndDate: "",
        eventIdProof: null,
        eventYears: "",
        eventMobileNumber: "",
        eventLYAttend: "",
        eventCapaxityNo: "",
        eventPassImg: null,
      };

      this.bankData = {
        eventAddressProof: null,
        eventProof: "",
        eventBankBranchName: "",
        eventBankAccountHolderName: "",
        eventBankAccountNumber: "",
        eventBankIfcCode: "",
        eventBankBranchAddress: "",
        eventUploadBankProof: null,
      };

      this.confirmCheckbox = false;
    },
  },
};
</script>

<style scoped></style>
