<template>
  <div class="dropdown position-relative" style="right: 0; top: 0;">
    <button class="dropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
      style="border: none; background: none; width:100% ">
      <img src="images/Icons/Group 2108.svg" width="20" height="15" style="margin-right:0.5rem" />
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="
        position: absolute;
        inset: 0px auto auto -153px;
        margin: 0px;
        transform: translate(0px, 30px);
      ">
      <li>
        <a class="dropdown-item" id="hide_post" href="#">Delete</a>
      </li>

      <li>
        <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModat">Hide</a>
      </li>
      <li>
        <a class="dropdown-item" href="#" id="hide_post">View</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "NotfiList"
}
</script>