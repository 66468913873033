<template>
  <div class="home-page ">
    <div class="upload-section" id="upload-section">
      <div class="upload-container" id="upload-container">
        <p class="upload-instuction">
          Share your passion for Garba with the world by creating your own
          Garba class on GarbaJockey.
        </p>
        <router-link to="/UplodedDetails">
          <button class="create-garba-button">Create own garba class</button>
        </router-link>
      </div>
    </div>
    </div>
    <!-- <HomeProfile />  -->
    <!-- <MenuBar /> -->
</template>
<script>
// import UserActions from "@/components/Model/User_Actions.vue"
// import HomeProfile from "./HomeProfile.vue";
// import MenuBar from "./MenuBar.vue";
export default {
  name: "EntrantUserGC",
  components: {
    //   HomeProfile,
    //   MenuBar
  },
};
</script>
<style scoped>
.create_ent_gc{
    margin:1rem;
}
.home-page {
    padding:2%;
  margin-top: 19rem;
  width: 100%;
  overflow: hidden;
}
.upload-section {
    margin-top: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 10%;
  /* visibility: hidden; */
}
/* .upload-section-all {
  visibility: visible;
  margin-top: -62rem;
} */
.upload-container {
  border: 0.2rem dashed #c10111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  margin-top: -36rem;
  width: 80rem;
  border-radius: 1rem;
  margin-left: -3rem;
  padding:3%;
}
.upload-instuction {
  padding: 1.9rem;
  font-size: 1rem;
}
.create-garba-button {
  background-color: #c10111;
  border: none;
  width: 100%;
  border-radius: 0.4rem;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem 2rem;
 }
/*@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .home-page {
    background-color: #efefef;
    margin-top: 4rem;
    width: 100%;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .home-page {
    background-color: #efefef;
    margin-top: 6.2rem;
    width: 100%;
    overflow: hidden;
  }
}
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .home-page {
    background-color: #efefef;
    /* margin-top: 0; 
    margin-top: 4rem;

    width: 100%;
    overflow: hidden;
  }
} */
/* .home-page {
  background-color: #efefef;
  /* margin-top: 0; 
  margin-top: 4rem;
  width: 100%;
  overflow: hidden;
} */
/* @media screen and (min-width: 425px) and (max-width: 575.98px) {
  .home-page {
    background-color: #efefef;
    /* margin-top: 0; 
    margin-top: 4rem;

    width: 100%;
    overflow: hidden;
  }
}
@media screen and (min-width: 320px) and (max-width: 424.98px) {
  .home-page {
    background-color: #efefef;
    /* margin-top: 0; 
    margin-top: 4rem;
    width: 191%;
    height: 190vh;
    overflow: hidden;
  }
} */
</style>