<template>
  <div class="d-flex align-items-center ms-1">
    <button
      type="button"
      class="btn-sm p-0 d-flex align-items-center"
      style="width: 25%; background: none; border: none"
      data-bs-toggle="modal"
      :data-bs-target="'#exampleModel' + postId"
      @click="showcomment(postId)"
    >
      <i
        class="fa-sharp fa-regular fa-comment"
        :class="{ 'animate__animated animate__tada': iconClicked }"
        style="font-size: 1.5rem; margin-top: 0.14rem"
      ></i>
      <span class="text-dark mx-1 px-2" style="font-size: 1rem">{{
        commentCount
      }}</span>
    </button>
  </div>
  <div
    class="modal fade"
    :id="'exampleModel' + postId"
    tabindex="-1"
    :aria-labelledby="'exampleModelLabel' + postId"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" :id="'exampleModelLabel' + postId">
            <span>Comment {{ commentCount }}</span>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column w-100"
        >
          <div
            class="d-flex justify-content-center align-items-center flex-column w-100"
            v-for="comment in commentAry[postId]"
            :key="comment.id"
          >
            <div class="d-flex w-100">
              <img
                class="rounded-5"
                style="width: 15%; height: 2.9rem"
                :src="
                  comment.user.profile_photo
                    ? comment.user.profile_photo
                    : defaultImage
                "
              />
              <div
                class="d-flex flex-column w-100 rounded-4 mx-1 px-3"
                style="background: #f0f0f0"
              >
                <div>
                  <span class="fs-6">{{ comment.user.first_name }}</span>
                  <span class="px-2 fs-6">{{ comment.user.last_name }}</span>
                </div>
                <span class="fs-6">{{ comment.comment }}</span>
              </div>
            </div>
            <hr class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "UsecomT",
  props: {
    postId: String,
    comments_count: String,
  },
  data() {
    return {
      commentAry: {}, // Use an object to store comments for each post
      commentCount: this.comments_count,
      defaultImage: require("@/assets/profile-no-img.png"),
      iconClicked: false,
    };
  },
  methods: {
    commentIconClicked() {
      this.iconClicked = !this.iconClicked;

      setTimeout(() => {
        this.iconClicked = false;
      }, 1500);
    },
    async showcomment(postId) {
      this.commentIconClicked();
      try {
        // console.log("showcomment() method called");
        // console.log(postId);

        const userType = this.$store.state.userType;

        // Define the base endpoint for comments
        let endpoint = "";

        switch (userType) {
          case "1":
            endpoint = "entrant/posts/comments";
            break;
          case "3":
            endpoint = "gc/posts/comments";
            break;
          case "2":
            endpoint = "gj/posts/comments";
            break;
          default:
            // Handle other user types or show a default set of comments
            console.error("Invalid user type");
            return;
        }

        const response = await axios.post(endpoint, {
          post_id: postId,
        });

        // console.log(response.data);

        if (response.data.status && response.data.status_code === "206") {
          // console.log("No comments available");
          return;
        }

        // Directly assign comments to the commentAry object
        // this.$set(this.commentAry, postId, response.data.data.comments || []);
        this.commentAry[postId] = response.data.data.comments || [];
        // console.log("comment ", this.commentAry[postId]);
        this.$forceUpdate(); // Force a re-render
        this.commentCount = response.data.data.comments_count; // Update commentCount
        // console.log(postId);
      } catch (error) {
        console.error(error);
      }
    },

    // showcomment(postId) {
    //   console.log('showcomment() method called');
    //   console.log(postId);

    //   axios
    //     .post("entrant/posts/comments", {
    //       post_id: postId,
    //     })
    //     .then((response) => {
    //       console.log(response.data);

    //       if (response.data.status && response.data.status_code === '206') {
    //         console.log('No comments available');
    //         return;
    //       }

    //       // Directly assign comments to the commentAry object
    //       // this.$set(this.commentAry, postId, response.data.data.comments || []);
    //       this.commentAry[postId] = response.data.data.comments || [];
    //       console.log("comment ", this.commentAry[postId]);
    //       this.$forceUpdate(); // Force a re-render
    //       this.commentCount = response.data.data.comments_count; // Update commentCount
    //       console.log(postId);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
  },
};
</script>

<style scoped></style>