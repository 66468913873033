<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div
      class="w-100 d-flex justify-content-center align-items-center flex-column container"
      style="margin: 7rem auto"
    >
      <img
        class="w-100"
        alt
        src="..\assets\b5a9ebefbdba66c084a0e863d6906d5a.png"
      />
      <div class="row w-100">
        <div
          class="col-sm-12 col-md-4 rounded-3 p-0"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <div class="leftbox p-3">
            <h4>Total Payment:</h4>
            <h4>
              Rs.&nbsp;{{ subscription.subscription_amount }}
              <br />
              <label>
                <p id="rr">Including Taxes(8%)</p>
              </label>
            </h4>
            <h4 id="a">Terms & Conditions</h4>
            <p class="terms-conditions">
              By subscribing to a Garba class through the Service, you agree to
              the terms and conditions outlined in the separate Garba class
              terms and conditions.
            </p>
          </div>
        </div>

        <div
          class="col-sm-12 col-md-8 rounded-3 p-0"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <div class="rightbox p-3">
            <h2>Subscription</h2>
            <hr style="color: #c10111" />
            <div class="user_subscription">
              <div
                class="Subscription_details"
                v-for="subscriptions in 1"
                :key="subscriptions.id"
              >
                <div class="d-flex w-100 justify-content-between">
                  <p>Garba Class Subscription</p>
                  <p>{{ subscription.subscription_amount }}</p>
                </div>

                <div class="d-flex w-100 justify-content-between">
                  <p>CGST</p>
                  <p>{{ subscription.cgst_amount }}</p>
                </div>

                <div class="dashed_border"></div>

                <div class="d-flex w-100 justify-content-between">
                  <p>Gross Amount</p>
                  <p>+150.00</p>
                </div>

                <div class="d-flex w-100 justify-content-between">
                  <p>SGST</p>
                  <p>{{ subscription.sgst_amount }}</p>
                </div>

                <div class="d-flex w-100 justify-content-between">
                  <p>GST</p>
                  <p>{{ subscription.gst_amount }}</p>
                </div>

                <div class="dashed_border"></div>

                <div class="d-flex w-100 justify-content-between">
                  <p>Gross Amount</p>
                  <p>{{ subscription.subscription_amount }}</p>
                </div>
              </div>
            </div>
            <div
              class="Procced_btn w-100 d-flex justify-content-center align-items-center"
            >
              <button
                class="btn btn-danger w-50"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                id="Procced_button"
                @click="userSub"
              >
                Proceed
              </button>
            </div>
            <div class="offline_gc_radiobtn my-3">
              <router-link
                :to="{
                  path: `/subsription_Page_2/${subscription.id}`,
                }"
                class="text-decoration-none text-black"
                style="cursor: pointer"
              >
                <!-- params: { id: subscription.id }, -->
                <input class="gc_radio_btn" type="radio" />
                <span class="mx-2"
                  >I have paid my subscribed to Garba Class</span
                >
              </router-link>
            </div>
          </div>
        </div>

        <form
          method="POST"
          class="pl-5 pr-5"
          id="paymentForm"
          :action="payuUrl"
        >
          <input type="hidden" name="key" v-model="mkey" size="64" />
          <input type="hidden" name="txnid" v-model="txnid" size="64" />
          <input type="hidden" name="amount" v-model="amount" size="64" />
          <input
            type="hidden"
            name="productinfo"
            v-model="productinfo"
            size="64"
          />
          <input type="hidden" name="firstname" v-model="FirstName" size="64" />
          <input
            type="hidden"
            name="service_provider"
            value="payu_paisa"
            size="64"
          />
          <input type="hidden" name="email" v-model="EmailAddress" size="64" />
          <input type="hidden" name="phone" v-model="MobileNumber" size="64" />
          <!-- <input type="hidden" name="lastname" v-model="LastName" size="64" /> -->
          <input type="hidden" name="surl" v-model="surl" />
          <input type="hidden" name="furl" v-model="furl" />
          <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
        </form>
      </div>

      <!-- model- popup -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Payment Details
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form method="POST" class="pl-5 pr-5" :action="payuUrl">
                <label for="fristName" class="form-label">FirstName</label>
                <input
                  type="text"
                  v-model="FirstName"
                  id="Fristname"
                  class="form-control"
                  required
                />
                <label for="LastName" class="form-label">LastName</label>
                <input
                  type="text"
                  v-model="LastName"
                  id="email"
                  class="form-control"
                  required
                />

                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  v-model="EmailAddress"
                  id="email"
                  class="form-control"
                  required
                />
                <label for="MobileNumber" class="form-label"
                  >MobileNumber</label
                >

                <input
                  type="number"
                  v-model="MobileNumber"
                  id="MobileNumber"
                  class="form-control"
                  required
                />
                <!-- <label for="amount" class="form-label">amount</label>
              <input type="number" v-model="amount" id="amount" class="form-control" required />-->
              </form>

              <!-- <h3 class="text-center" style="color:#c10111">Congralulations..!</h3>
            <p style="text-align:center">
              Thank you for subscription,
              <br />now you are eligible to view all
              offers.
            </p>-->
              <button
                type="button"
                class="btn btn-danger mt-3"
                id="home_btn"
                @click="PaymentOp"
              >
                Procced
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./Profile_Screens/NavBar.vue";
import axios from "@/axios";
export default {
  name: "SubscriptionPage",
  props: {
    userId: String,
  },
  components: {
    NavBar,
  },
  data() {
    return {
      payuUrl: "https://secure.payu.in/_payment",
      subscription: [],
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      firstname: "",
      LastName: "",
      EmailAddress: "",
      MobileNumber: "",
      productinfo: "garbajocky",
      surl: window.location.origin + "/Payment_Success",
      furl: window.location.origin + "/Home",
      hash: this.hash,
      amount: " ",
      txnid: this.makeid(),
      payment_status: "1",
    };
  },
  methods: {
    userSub() {
      try {
        const userType = this.$store.state.userType;
        const userId = this.$route.params.id;
        let data = {
          garba_class_id: userId,
          subscription_type: 1,
        };

        let endpoint = "";

        switch (userType) {
          case "1":
            endpoint = `entrant/subscriptions/subseribe`;
            break;
          case "3":
            endpoint = `gc/subscriptions/subseribe`;
            break;
          case "2":
            endpoint = `gj/subscriptions/subseribe`;
            break;
          default:
            endpoint = "default-profile";
            break;
        }

        axios
          .post(endpoint, data)
          .then((response) => {
            // console.log("you subscribe", response);
            this.subscription = response.data.data;
            // console.log("from subscription array", this.subscription);
            this.amount = response.data.data.subscription_amount;
            localStorage.setItem("paymentStatus", this.paymentStatus);
          })
          .catch((error) => {
            console.log(error.response.data.status_code);
            if (error.response.data.status_code == 422) {
              const confirmed = confirm(
                "This Garba Class is Expired!! \nDo you want to choose another one?"
              );

              if (confirmed) {
                // Your code to be executed if the user confirms
                this.$router.push("/GarbaPage");
              } else {
                // Your code to be executed if the user cancels
                this.$router.push("/Home");
              }
            } else {
              alert(error.response.data.message);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    PaymentOp() {
      // alert(this.amount);
      var data =
        this.mkey +
        "|" +
        this.txnid +
        "|" +
        this.amount +
        "|" +
        this.productinfo +
        "|" +
        this.FirstName +
        "|" +
        this.EmailAddress +
        "|||||||||||";
      var sha512 = require("js-sha512");
      var salt = "3oFxUMtWG2";
      var hash = sha512(data + salt);
      // console.log(this.amount);

      // console.log(hash);
      // console.log(data);
      document.getElementById("hash").value = hash;
      localStorage.setItem("hash", hash);
      document.getElementById("paymentForm").submit();
    },

    //  handlePaymentResponse() {
    //     if (this.paymentStatus === 2) {
    //     this.paymentStatus = 1;
    //   }
    //    const payload = {
    //     transaction_id: this.txnid,
    //     payment_status: this.paymentStatus
    //   };
    //   axios.post(`entrant/subscriptions/payment-status/${this.userId}`,payload).then(response =>{
    //     console.log(response.data)
    //    localStorage.setItem('paymentStatus', this.paymentStatus);

    //   })

    // }

    updatePaymentStatus() {
      if (this.paymentStatus === 1) {
        this.payment_status = localStorage.getItem("");
        axios
          .post(`entrant/subscriptions/payment-status/${this.userId}`, {
            transaction_id: this.txnid,
            payment_status: this.paymentStatus,
          })
          .then(() => {
            // Handle successful response
            // console.log("Payment status updated successfully", response);
            const paymentStatus = localStorage.getItem("paymentStatus");

            if (paymentStatus) {
              // The payment status exists in local storage
              console.log("Payment status:", paymentStatus);
            } else {
              // The payment status doesn't exist in local storage
              console.log("Payment status not found");
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error updating payment status:", error);
          });
      } else {
        this.paymentStatus = 2;
      }
    },
  },

  mounted() {
    this.userSub();
    this.makeid();
    // this.updatePaymentStatus();

    // this.PaymentOp();
  },
};
</script>

<style scoped>
.dashed_border {
  border: 2px dashed grey;
  width: 100%;
}

hr.solid {
  border: 1px solid #c10111;
  color: #c10111;
}

.gc_radio_btn {
  accent-color: #c10111;
}
</style>
