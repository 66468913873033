<template>
  <div class="custom-animation-gj">
    <DummyNavbar class="NavBar mt-0" />
    <div class="hello">
      <div
        class="container d-flex justify-content-center align-items-center mx-auto px-3"
        style="margin-top: 90px"
      >
        <div class="main mt-5 pb-5">
          <div class="form w-50 mt-5">
            <p class="heading">Create A New Account</p>
            <p id="demo"></p>
            <br />
            <form class="form-row row g-3 w-100" @submit="checkForm">
              <div class="col-sm col-md-12 col-lg-6 mt-0">
                <label for="fristname" class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control px-2"
                  id="first_name"
                  v-model="first_name"
                />
              </div>
              <div class="col-sm col-md-12 col-lg-6 mt-0">
                <label for="lastname" class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control px-2"
                  id="last_name"
                  v-model="last_name"
                />
              </div>
              <div class="col-12 mt-0">
                <label for="Email" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control px-2"
                  id="email"
                  v-model="email"
                />
              </div>
              <div class="col-12 mt-0">
                <label for="birth_date" class="form-label">Date of birth</label>
                <label for="birth_date" id="dob" v-on:click="Userdob"></label>
                <input
                  type="date"
                  class="form-control px-2"
                  id="birth_date"
                  v-model="birth_date"
                />
              </div>
              <div class="col-md-6 mt-0">
                <p class="gender my-2">Gender</p>
                <input
                  type="radio"
                  name="Gender"
                  value="0"
                  id="malegender"
                  class="ms-2"
                  v-model="selectedGender"
                />

                <label for="malegender" class="form-label">Male</label>

                <input
                  type="radio"
                  name="Gender"
                  value="1"
                  id="femalegender"
                  v-model="selectedGender"
                />
                <label for="femalegender" class="form-label" id="gender_label"
                  >Female</label
                >
              </div>

              <div class="col-12 mt-0">
                <label for="password" class="form-label mt-2">Password</label>
                <input
                  type="password"
                  class="form-control mb-0 px-2"
                  id="password"
                  v-model="password"
                />
                <span
                  class="password d-flex justify-content-end align-items-center w-25 ms-auto position-relative"
                  v-on:click="pass_toggle"
                >
                  <i class="fas fa-eye-slash" id="eye"></i>
                </span>
              </div>

              <div class="col-12 mt-0">
                <label for="confirmpass" class="form-label"
                  >Confirm Password</label
                >
                <input
                  type="password"
                  class="form-control mb-0 px-2"
                  id="password_confirmation"
                  v-model="password_confirmation"
                />
                <span
                  class="c-hide d-flex justify-content-end align-items-center w-25 ms-auto position-relative"
                  v-on:click="pass_toggle1"
                >
                  <i class="fas fa-eye-slash" id="eye1"></i>
                </span>
              </div>

              <p id="message"></p>

              <div class="col-md-12 mt-2">
                <label class="checkbox" style="cursor: pointer">
                  <input
                    type="checkbox"
                    v-model="termsAgreed"
                    style="cursor: pointer"
                  />
                  I agree & Terms and conditions
                </label>
              </div>

              <div class="col-12 mt-0">
                <button
                  :disabled="!isValid"
                  type="submit"
                  class="btn btn-primary btn-lg btn-block"
                  id="btn"
                  v-on:click="signIn"
                >
                  Submit
                </button>
              </div>

              <div class="col-md-12 d-flex mt-0 d-flex justify-content-center">
                <p class="text-center">
                  Already have an account ?<span
                    ><router-link class="signin-text ms-2" to="/login"
                      >Sign in Here</router-link
                    ></span
                  >
                </p>
              </div>
            </form>
          </div>
        </div>

        <!-- <img
        class="dandiya-sticks"
        src="images/dandiya-sticks-clipart_10.png"
        alt=""
      />

      <img class="rangoli" src="images/Asset 2@3x.png" alt="" />

      <img class="garba-jocky" src="images/Group 1945.png" alt="" />
      <div class="dancer">
        <img
          class="dancer"
          src="images/dandiya-dancers_23-2147967531.png"
          alt=""
        />
      </div>
      <div class="rangoli-1">
        <img src="images/Asset 2@3x.png" alt="" />
      </div>
      <div class="rangoli-2">
        <img src="images/Asset 2@3x.png" alt="" />
      </div>
      <img class="rangoli-3" src="images/Asset 2@3x.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import $ from "jquery";
// import datepicker from 'js-datepicker'

import DummyNavbar from "./DummyUser/DummyNavbar.vue";
export default {
  name: "HelloWorld",
  data() {
    return {
      // error: [],
      first_name: "",
      last_name: "",
      email: "",
      birth_date: "",
      password: "",
      password_confirmation: "",
      selectedGender: "",
      termsAgreed: false,
    };
  },
  components: {
    DummyNavbar,
  },
  computed: {
    isValid() {
      return this.termsAgreed;
    },
  },
  methods: {
    signIn() {
      axios
        .post("entrant/users", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          birth_date: this.birth_date,
          password: this.password,
          password_confirmation: this.password_confirmation,
          gender: this.selectedGender,
        })
        .then((response) => {
          localStorage.setItem("email", this.email);
          if (response.data.status_code == 201) {
            this.$router.push("/OtpVerification");
          }

          // console.log(response.data.data);
        })
        .catch((error) => {
          // console.log("error found", error.response.data.message);
          document.getElementById("demo").innerHTML =
            error.response.data.message;
        });
    },

    Userdob() {
      let inputDate = document.getElementById("birth_date");
      inputDate.addEventListener("focus", function () {
        if (this.getAttribute("type") === "date") {
          this.showPicker();
        }
      });
      //  alert("datepicker")
    },

    pass_toggle() {
      $("#eye").click(function () {
        if ($(this).hasClass("fa-eye-slash")) {
          $(this).removeClass("fa-eye-slash");

          $(this).addClass("fa-eye");

          $("#password").attr("type", "text");
        } else {
          $(this).removeClass("fa-eye");

          $(this).addClass("fa-eye-slash");

          $("#password").attr("type", "password");
        }
      });
    },

    pass_toggle1() {
      $("#eye1").click(function () {
        if ($(this).hasClass("fa-eye-slash")) {
          $(this).removeClass("fa-eye-slash");

          $(this).addClass("fa-eye");

          $("#password_confirmation").attr("type", "text");
        } else {
          $(this).removeClass("fa-eye");

          $(this).addClass("fa-eye-slash");

          $("#password_confirmation").attr("type", "password");
        }
      });
    },
    checkForm() {
      $(".form").submit(function (e) {
        e.preventDefault();
        // var first_name = $("#firstname").val();
        // console.log(first_name);
        // var last_name = $("#lastname").val();
        // var email = $("#Email").val();
        // var password = $("#pwd").val();
        // var Cpassword = $("#cpwd").val();

        // var dob = $("#dob").val();
        $(".error").remove();
        // if (first_name.length < 1) {
        //   $("#firstname").after(
        //     '<span class="error">This field is required</span>');
        // }
        // if (dob.length < 1) {
        //   $("#dob").after('<span class="error">This field is required</span>');
        // }
        // if (last_name.length < 1) {
        //   $("#lastname").after(
        //     '<span class="error">This field is required</span>'
        //   );
        // }
        // if (email.length < 1) {
        //   $("#Email").after(
        //     '<span class="error">This field is required</span>'
        //   );
        // } else {
        //   var regEx =
        //     /^[A-Z0-9][A-Z0-9._%+-]{0,63}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/;
        //   var validEmail = regEx.test(email);
        //   if (!validEmail) {
        //     $("#email").after('<span class="error">Enter a valid email</span>');
        //   }
        // }
        // if (password.length < 8) {
        //   $("#pwd").after(
        //     '<span class="error">Password must be at least 8 characters long</span>'
        //   );
        // }
        // if (Cpassword.length < 8) {
        //   $("#cpwd").after(
        //     '<span class="error">Password must be at least 8 characters long</span>'
        // );
        // }
        // if ($('input[name="gender"]:checked').length == 0) {
        //   $("gender_label").after(
        //     '<span class="error">please select the gender</span>'
        //   );
        // }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },

  // checkPassword() {
  //   let password = document.getElementById("pwd").value;
  //   let cnfrmPassword = document.getElementById("cpwd").value;
  //   let message = document.getElementById("message");
  //   console.log(
  //     " password:",
  //     password,
  //     "\n",
  //     "Confirm Password:",
  //     cnfrmPassword
  //   );

  //   if (password.length != 0) {
  //     if (password == cnfrmPassword) {
  //       message.textContent = "Passwords match";
  //     } else {
  //       message.textContent = " Password don't match";
  //       message.style.color = "#c10111";
  //     }
  //   }
  // },

  // mounted()
  // {
  //   let users = localStorage.getItem('user.Info');
  //   if (users) {
  //     this.$router.push({ name: "Home" });
  //   }
  // }
};
</script>

<!--n- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Add this CSS code to your project's stylesheet */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hello {
  height: auto;
  min-height: 100vh;
  background-size: cover;
  background-image: url("../assets/background-image.png");
  background-repeat: no-repeat;
}

.NavBar {
  position: relative;
  top: 0;
}

#demo {
  text-align: center;
  color: red;
}

.main {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form {
  box-shadow: 0 0 10px #451111;
  float: right;
  padding: 23px 2px;
  margin: 0;
  overflow: hidden;
  background: white;
  border-radius: 2rem;
  z-index: 1;
}

.form-row {
  margin-left: 0;
}

.heading {
  margin: -37px 0px 0px;
  padding: 27px 16px 10px;
  text-align: center;
  font-size: 25px;
  color: #c10111;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 900;
}

input.form-control {
  margin: 8px 0px;
  padding: 1px;
  background-color: #d4d4d4;
  width: 100%;
}

label.form-label {
  margin: -3px 12px -2px;
}

#dob {
  height: 30%;
  position: relative;
  top: 55%;
  left: -6%;
  width: 62%;
  cursor: pointer;
}

.password {
  cursor: pointer;
  color: black !important;
  top: -1.7rem;
  left: -13px;
}

#eye {
  cursor: pointer;
}

.confirm-password {
  position: relative;
  top: -135px;
  left: 326px;
  cursor: pointer;
}

span {
  color: #c10111;
}

.checkbox {
  margin: 10px;
}

#btn {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: #c10111;
  border: none;
  width: 92%;
}

.gender {
  margin-left: 10px;
}

.signin-text {
  text-decoration: none;
  color: #c10111;
  font-weight: 700;
}

#radio1 {
  accent-color: #c10111;
}

#radio2 {
  accent-color: #c10111;
}

.checkbox {
  accent-color: #c10111;
  margin-top: -4%;
}

.hide {
  position: relative;
  top: -127px;
  left: 340px;
  cursor: pointer;
}

.c-hide {
  cursor: pointer;
  color: black !important;
  top: -1.7rem;
  left: -13px;
}

.dandiya-sticks {
  width: 17%;
  position: fixed;
  top: -71px;
  left: -73px;
}

.rangoli {
  width: 23%;
  position: fixed;
  left: 237px;
  top: 135px;
}

.garba-jocky {
  width: 15%;
  position: fixed;
  left: 300px;
  top: 197px;
}

.dancer {
  width: 16%;
  position: fixed;
  left: -4px;
  top: 509px;
}

.rangoli-1 img {
  width: 9%;
  position: fixed;
  left: 50%;
  top: -2%;
  z-index: -1;
}

.rangoli-2 img {
  position: fixed;
  width: 25%;
  top: -26%;
  left: 87%;
  z-index: -2;
}

.rangoli-3 {
  top: 569px;
  left: 1194px;
  width: 9%;
  position: fixed;
  z-index: -2;
}

.radio-btn {
  margin: 10px 10px 10px;
  accent-color: #c10111;
}

.check-box-input {
  accent-color: #c10111;
}

.frist-row {
  padding: 0px 5px 0px;
}

.error {
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .main {
    justify-content: center;
  }

  .form {
    width: 75% !important;
  }
}

/* Adjust styles for small screens, like mobile devices */
@media screen and (max-width: 767px) {
  .form {
    width: 100% !important;
  }

  .container {
    margin-top: 0rem !important;
  }

  /* You can add more specific adjustments for smaller screens here */
}

@media (min-width: 1600px) {
  .container {
    margin-right: 13rem;
  }
}
</style>
