<template>
  <div class="custom-animation-gj">
    <DummyNavbar class="NavBar mt-0" />
    <div class="main-container">
      <div
        class="container d-flex justify-content-center align-items-center mx-auto px-3"
        style="margin-top: 90px"
      >
        <div
          class="container_form w-100 d-flex align-items-center justify-content-end mt-5 pb-5"
        >
          <div class="form-container mx-5 mt-5">
            <p class="heading">Welcome to GarbaJockey</p>

            <h4 class="title">Sign In</h4>

            <form @submit.prevent="loGin" class="pt-4">
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  v-model="email"
                  id="email"
                  class="form-control"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <div class="input-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control"
                    v-model="password"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="password"
                    @keyup.enter="loGin"
                    required
                  />
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="toggleShow"
                  >
                    <i
                      :class="{
                        'bi-eye-fill': showPassword,
                        'bi-eye': !showPassword,
                      }"
                    ></i>
                  </button>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <a href="/ForgotPassword" class="btn btn-forget"
                  >Forgot password?</a
                >
              </div>

              <div class="my-3">
                <button
                  @click="loGin"
                  :disabled="!email || !password"
                  type="button"
                  class="btn btn-danger w-100"
                  id="button"
                  style="cursor: pointer"
                >
                  Signin
                </button>
              </div>

              <div class="d-flex justify-content-center flex-column w-100">
                <span class="Sign-text d-flex justify-content-center">
                  New Here?
                  <router-link to="sign-up" class="create_account ms-2">
                    <span>Create A New Account</span>
                  </router-link>
                </span>
                <div class="d-flex signin-section">
                  <hr class="line" />
                  <span class="OR-Section">OR</span>
                  <hr class="line" />
                </div>
              </div>

              <div
                class="my-3 d-flex justify-content-between w-100 social-media-col12"
              >
                <div
                  @click="logFb"
                  class="d-flex justify-content-center align-items-center btn btn-light w-50 Facebook_icon"
                >
                  <img src="images/Icons/facebook.svg" alt="facebook-icon" />
                  <span class="ml-2 Facebook_span">Facebook</span>
                </div>
                <div
                  @click="logGo"
                  class="d-flex justify-content-center align-items-center btn btn-light w-50 ml-2 Google_Icon"
                >
                  <img
                    src="../../public/images/google.png"
                    alt="google-icon"
                    style="width: 7%"
                  />
                  <span class="ml-2 Google_span">Google</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <login-content />
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import LoginContent from "./LoginContent.vue";
import DummyNavbar from "./DummyUser/DummyNavbar.vue";
// import { useStore } from "vuex";

export default {
  name: "UserSignin",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      // reM:"",
    };
  },
  mounted() {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    logFb() {
      axios.get("login/facebook").then((response) => response.data.url);
    },
    logGo() {
      axios.get("login/google").then((response) => response.data.url);
    },
    loGin() {
      axios
        .post("login", {
          email: this.email,
          password: this.password,
          remember_me: this.reM,
        })
        .then((response) => {
          let email = response.data.data.email;
          // Set the user type in Vuex store
          const userType = response.data.data.user_type;
          // console.log("User Type From Login: ", userType);
          this.$store.commit("setUserType", userType);
          // console.log("Vuex Store State:", this.$store.state.userType);
          localStorage.setItem("userid", response.data.data.id);
          localStorage.setItem("token", response.data.data.authorization);
          localStorage.setItem("email", email);
          if (localStorage.getItem("token")) {
            // console.log(
            //   "this is locoal storage token",
            //   localStorage.getItem("token")
            // );
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
            let resultfinal = axios.get("entrant/posts", {
              headers: {
                DeviceID: "1111",
                versionName: "1.0.0",
                DeviceType: "1",
              },
            });
            this.listdef = resultfinal.data;
          }
          this.$router.push("/Home");
        })
        .catch((error) => {
          alert("Email Id or Password is Incorrect...", error);
        });
    },
    reM() {
      let remember_me = "";
      if (remember_me == 0) {
        this.remember_me;
      } else if (remember_me == 1) {
        !remember_me;
      }
    },
  },
  components: { LoginContent, DummyNavbar },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
};
</script>

<style scoped>
.NavBar {
  position: relative;
  top: 0;
}
.main-container {
  /* background-position: bottom; */
  height: auto;
  min-height: 100vh;
  background-size: cover;
  background-image: url("../assets/background-image.png");
  background-repeat: no-repeat;
}

.form-container {
  width: 35%;
  padding: 23px 23px;
  background: #fff;
  border-radius: 2.125rem;
  box-shadow: 0 0 10px #451111;
}

.heading {
  text-align: center;
  font-size: 24px;
  color: #c10111;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 900;
  padding: 12px 14px 10px;
}

.input-group {
  position: relative;
}

.input-group button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.create_account {
  cursor: pointer;
  text-decoration: none;
  color: #c10111;
}

.forgotpass_span {
  float: right;
  margin-bottom: 10%;
  margin-right: 7%;
  color: #000 !important;
}

.line {
  flex-grow: 1;
  border: 0;
  border-top: 1px solid #d4d4d4;
}

.OR-Section {
  color: #d4d4d4;
  padding: 0 10px;
}

@media (max-width: 991px) {
  .form-container {
    width: 75% !important;
    margin-top: 1.5rem;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 1399px) {
  .form-container {
    width: 50%;
    margin-top: 1.5rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .container_form {
    justify-content: center !important;
  }
}

@media (max-width: 768px) {
  .form-container {
    width: 100% !important;
    margin-top: 1.5rem;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .main {
    width: 100%;
  }

  .container {
    margin-top: 0 !important;
  }
}
</style>
