<template>
  <div>
    <input type="file" ref="videoInput" @change="handleFileChange" multiple />
    <div v-for="(video, index) in selectedVideos" :key="index">
      <video
        ref="previewVideo"
        :src="video.previewUrl"
        style="width: 23%; padding: 0.5rem"
        controls
      ></video>
    </div>
    <button @click="uploadVideo">Upload</button>
  </div>
  <div>
    <button @click="getLocation">Get Location</button>
    <!-- 
    <ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" data-bs-toggle="tab" href="#tab1" :class="{ 'active': activeTab === 'tab1' }" @click="activeTab = 'tab1'">followers</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" data-bs-toggle="tab" href="#tab2" :class="{ 'active': activeTab === 'tab2' }" @click="activeTab = 'tab2'">following</a>
  </li>
 Add similar updates for the remaining tabs 
</ul> -->
  </div>

  <div class="container">
    <div class="alert alert-primary">This is a Bootstrap styled div!</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      activeTab: "tab1",
      selectedVideos: [],
      notifications: [],
    };
  },
  mounted() {
    this.getNotify();
  },
  methods: {
    handleFileChange(event) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const previewUrl = URL.createObjectURL(file);
        this.selectedVideos.push({
          file: file,
          previewUrl: previewUrl,
        });
      }
    },
    async uploadVideo() {
      const formData = new FormData();

      // for (let i = 0; i < this.selectedVideos.length; i++) {
      //   const video = this.selectedVideos[i];
      //   formData.append('videos[]', video);
      // }
      for (let i = 0; i < this.selectedVideos.length; i++) {
        const video = this.selectedVideos[i];
        const serializedVideo = JSON.stringify(video);
        formData.append("videos[]", serializedVideo);
      }

      try {
        const response = await axios.post(
          "https://api.garbajockey.com/api/entrant/posts",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log("Videos uploaded successfully:", response.data);
      } catch (error) {
        console.error("Error uploading videos:", error.response.data.message);
        // Handle the error
      } finally {
        // Revoke the object URLs after upload
        for (let i = 0; i < this.selectedVideos.length; i++) {
          const video = this.selectedVideos[i];
          URL.revokeObjectURL(video.previewUrl);
        }
        this.convertBlobUrlToFile();
      }
    },

    async convertBlobUrlToFile(blobUrl) {
      const response = await fetch(blobUrl);
      const blobData = await response.blob();
      const file = new File([blobData], "video.mp4", { type: blobData.type });
      return file;
    },
    getNotify() {
      axios
        .get("entrant/notifications")
        .then((response) => {
          // console.log(response.data.data);
          this.notifications = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
      // axios.get("gc/notifications").then(response =>{
      //    this.notifications = response.data.data;
      //   console.log(response.data.data);
      // }).catch(error =>{
      //   console.log(error)
      // })
    },

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess,
          this.handleError
        );
      } else {
        // console.log("Geolocation is not supported by this browser.");
      }
    },
    handleSuccess(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      // console.log("Latitude:", latitude);
      // console.log("Longitude:", longitude);
    },
    handleError(error) {
      console.log("Error:", error.message);
    },
  },
};
</script>
