import axios from "axios";
axios.defaults.headers.common["Authorization"] =
	"Bearer " + localStorage.getItem("token");

//  axios.defaults.baseURL = 'https://api.garbajockey.com/api';
//  const axiosfile= axios.defaults.baseURL = 'https://api.garbajockey.com/api/';
const instance = axios.create({
	// baseURL: "http://uatapi.garbajockey.com/public/api/",       // Old UAT Url
	// baseURL: "https://uatapi.garbajockey.com/api/",             // New UAT Url

	baseURL: "https://api.garbajockey.com/api/",

	// timeout: 10000,

	headers: {
		"Content-Type": "application/json",
	},
});

export default instance;
