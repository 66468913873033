<template>
  <div class="w-100 py-2 position-relative bottom-0 footer-div">
    <!-- <h3 class="text-white fs-5 text-center my-3">Footer Of GarbaJockey</h3> -->
  </div>
</template>

<script>
export default {
  name: "Footer_Dummy_Page",
};
</script>

<style scoped>
.footer-div {
  background-color: #e50e1f;
  margin-top: 8rem;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .footer-div {
    margin-top: 4rem;
  }
}
</style>