<template>
  <div class="d-flex align-items-center">
    <button
      type="button"
      class="btn-sm p-0 d-flex align-items-center"
      data-bs-toggle="modal"
      data-bs-target="#exampleModalShare"
      style="
        width: 25%;
        cursor: pointer;
        width: 25%;
        background: none;
        border: none;
      "
      @click="setClickedPost(postId)"
    >
      <i
        class="fa-solid fa-arrow-up-right-from-square my-auto"
        :class="{ 'animate__animated animate__tada': iconClicked }"
        style="font-size: 1.2rem; margin-top: 0.2rem"
      ></i>
      <span class="text-dark mx-1 px-2" style="font-size: 1rem">{{
        this.shares_count
      }}</span>
    </button>
  </div>

  <div
    class="modal fade"
    id="exampleModalShare"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Share to..</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="fb"
            style="
              width: 100%;
              border-bottom: 1px solid gainsboro;
              cursor: pointer;
            "
          >
            <img src="../assets/facebook.svg" alt="" style="width: 5%" />
            <a
              :href="getShareUrl('facebook')"
              target="_blank"
              style="color: black"
              @click.prevent="openShareLink('facebook')"
            >
              <label
                style="font-size: large; font-weight: 500; cursor: pointer"
              >
                Share to Facebook</label
              >
            </a>
            <br />
          </div>
          <div
            class="ln"
            style="
              width: 100%;
              border-bottom: 1px solid gainsboro;
              cursor: pointer;
            "
          >
            <img src="../assets/linkedin.svg" alt="" style="width: 5%" />
            <a
              :href="getShareUrl('linkedin')"
              target="_blank"
              style="color: black"
              @click.prevent="openShareLink('linkedin')"
            >
              <label
                style="font-size: large; font-weight: 500; cursor: pointer"
              >
                Share to linkedin</label
              >
            </a>

            <br />
          </div>
          <div
            class="tt"
            style="
              width: 100%;
              border-bottom: 1px solid gainsboro;
              cursor: pointer;
            "
          >
            <img src="../assets/tweet.svg" alt="" style="width: 5%" />
            <a
              :href="getShareUrl('twitter')"
              target="_blank"
              style="color: black"
              @click.prevent="openShareLink('twitter')"
            >
              <label
                style="font-size: large; font-weight: 500; cursor: pointer"
              >
                Share to twitter</label
              >
            </a>

            <br />
          </div>
          <div
            class="wp"
            style="
              width: 100%;
              border-bottom: 1px solid gainsboro;
              cursor: pointer;
            "
          >
            <img src="../assets/whatsapp.svg" alt="" style="width: 5%" />
            <a
              :href="getShareUrl('whatsapp')"
              target="_blank"
              style="color: black"
              @click.prevent="openShareLink('whatsapp')"
            >
              <label
                style="font-size: large; font-weight: 500; cursor: pointer"
              >
                Share to whatsapp</label
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "UsersharE",
  props: {
    postId: String,
    shares_count: String,
  },
  data() {
    return {
      clickedPostId: null,
      iconClicked: false,
    };
  },
  computed: {
    postLink() {
      return this.clickedPostId
        ? `${window.location.origin}/posts/${this.clickedPostId}`
        : null;
    },
  },
  methods: {
    shareIconClicked() {
      this.iconClicked = !this.iconClicked;

      setTimeout(() => {
        this.iconClicked = false;
      }, 1500);
    },
    getShareUrl(platform) {
      const link = this.postLink;
      if (!link) {
        // Handle the case where clickedPostId is null
        // console.error("Post ID is null. Cannot generate share link.");
        return "";
      }

      // console.log("Generated Link when Clicked Post:", link);

      switch (platform) {
        case "facebook":
          return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            link
          )}`;
        case "linkedin":
          return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
            link
          )}`;
        case "twitter":
          return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            link
          )}`;
        case "whatsapp":
          return `https://wa.me/?text=${encodeURIComponent(link)}`;
        default:
          return "";
      }
    },

    async setClickedPost(postId) {
      this.shareIconClicked();
      if (postId) {
        this.clickedPostId = postId;
        // console.log("Clicked Post ID:", this.clickedPostId);
      } else {
        console.error("Post ID is null. Cannot set clickedPostId.");
      }
    },

    async openShareLink(platform) {
      // Await the completion of setClickedPost
      await this.setClickedPost();

      // console.log("Current Clicked Post ID:", this.clickedPostId);

      const shareUrl = this.getShareUrl(platform);

      if (shareUrl) {
        // Open the share link in a new window
        window.open(shareUrl, "_blank");

        // Increment the share count via API for the clicked post
        await this.incrementShareCount(this.clickedPostId);
      } else {
        console.error("Share URL is empty. Check the getShareUrl method.");
      }
    },

    async incrementShareCount(postId) {
      const userType = this.$store.state.userType;

      // Define the base endpoint for sharing
      let endpoint = "";

      switch (userType) {
        case "1":
          endpoint = "entrant/posts/share";
          break;
        case "3":
          endpoint = "gc/posts/share";
          break;
        case "2":
          endpoint = "gj/posts/share";
          break;
        default:
          // Handle other user types or show a default set of shares
          console.error("Invalid user type");
          return;
      }

      try {
        const payload = {
          is_shared: "1",
          post_id: postId,
        };

        // Make an API request to increment share count for the specified post ID
        const response = await axios.post(endpoint, JSON.stringify(payload));

        if (response.status === 200) {
          // console.log(
          //   "Share count incremented successfully for post ID:",
          //   postId
          // );
        } else {
          console.error(
            "Failed to increment share count:",
            response.status,
            response.statusText
          );

          // Check if the response has a JSON content type
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            // If the response is JSON, parse it
            const responseBody = await response.json();
            console.error("Response body:", responseBody);
          } else {
            // If not JSON, log the raw response text
            const responseText = await response.text();
            console.error("Response text:", responseText);
          }
        }
      } catch (error) {
        console.error("Error during incrementShareCount:", error);
      }
    },

    // async incrementShareCount(postId) {
    //   try {
    //     // Make an API request to increment share count for the specified post ID
    //     const response = await axios.post("entrant/posts/share", {
    //       post_id: postId,
    //       is_shared: "1",
    //     });

    //     if (response.status === 200) {
    //       console.log("Share count incremented successfully for post ID:", postId);
    //     } else {
    //       console.error("Failed to increment share count:", response.status, response.statusText);

    //       // Check if the response has a JSON content type
    //       const contentType = response.headers.get("content-type");
    //       if (contentType && contentType.includes("application/json")) {
    //         // If the response is JSON, parse it
    //         const responseBody = await response.json();
    //         console.error("Response body:", responseBody);
    //       } else {
    //         // If not JSON, log the raw response text
    //         const responseText = await response.text();
    //         console.error("Response text:", responseText);
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error during incrementShareCount:", error);
    //   }
    // },
  },
};
</script>

<style scoped>
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  width: 100%;
}

.modal.show .modal-dialog {
  transform: none;
  margin-top: 10%;
}

.modal {
  --bs-modal-margin: 1.75rem;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  background-color: #ffffff78;
}
</style>
