<template>
  <div
    class="container d-flex vh-100 justify-content-center align-items-center"
  >
    <div
      class="main d-flex justify-content-end align-items-center"
      style="z-index: 1"
    >
      <div class="form float-right py-5">
        <h2 class="text-center">OTP Verification</h2>
        <p class="text-center">Enter the One-Time Password sent to Email</p>
        <form class="form-row">
          <div
            class="OTP1 d-flex justify-content-center align-items-center w-100 px-2 my-5"
          >
            <input
              type="text"
              v-modal="otpFirst"
              class="text1"
              id="t1"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
              maxlength="1"
              v-on:click="focus()"
              required
            />
            <input
              type="text"
              v-modal="otp - 2"
              class="text2"
              id="t2"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
              maxlength="1"
              required
            />
            <input
              type="text"
              v-modal="otp - 3"
              class="text3"
              id="t3"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
              maxlength="1"
              required
            />
            <input
              type="text"
              v-modal="otp - 4"
              class="text4"
              id="t4"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
              maxlength="1"
              required
            />
            <input
              type="text"
              v-modal="otp - 5"
              class="text5"
              id="t5"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
              maxlength="1"
              required
            />
            <input
              type="text"
              v-modal="otp - 6"
              class="text6"
              id="t6"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
              maxlength="1"
              required
            />
          </div>
          <span
            v-if="loading"
            class="resendOTP d-flex justify-content-end align-items-center px-2 my-3 w-100"
            >Resending OTP..</span
          >
          <span
            v-else
            v-on:click="resendEmail()"
            class="resendOTP d-flex justify-content-end align-items-center px-2 my-3 w-100"
            >Resend OTP</span
          >
          <div
            class="d-flex justify-content-center align-items-center w-100 px-2"
          >
            <button
              type="button"
              class="bg-danger text-white px-5 py-2 mt-3 mb-2 w-100 rounded btn btn-lg"
              v-on:click="verEmail()"
              :disabled="loading"
            >
              <span v-if="!loading">Submit</span>
              <!-- Show "Submit" when not loading -->
              <span v-if="loading">
                <!-- Show loading spinner when loading is true -->
                <!-- Add your loading spinner here (e.g., use a spinner component or an animated icon) -->
                Sending OTP..
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- <div>
      <img
        class="dandiya-sticks"
        src="images/dandiya-sticks-clipart_10.png"
        alt=""
      />
    </div>
    <div class="rangoli">
      <img class="rangoli" src="images/Asset 2@3x.png" alt="" />

      <div class="garba-jocky">
        <img src="images/Group 1945.png" alt="" />
      </div>
    </div>

    <div class="dancer">
      <img src="images/dandiya-dancers_23-2147967531.png" alt="" />
    </div>
    <div class="rangoli-1">
      <img src="images/Asset 2@3x.png" alt="" />
    </div>
    <div class="rangoli-2">
      <img src="images/Asset 2@3x.png" alt="" />
    </div>
    <img class="rangoli-3" src="images/Asset 2@3x.png" alt="" /> -->
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "OtpVerification",
  data() {
    return {
      email: "",
      email_otp: "",
      loading: false,
    };
  },

  methods: {
    focus() {
      let OTP1 = document.getElementsByClassName("OTP1")[0];
      OTP1.onkeyup = function (e) {
        let target = e.srcElement;
        let maxLength = parseInt(target.attributes["maxlength"].value, 10);
        let myLength = target.value.length;
        if (myLength >= maxLength) {
          let next = target;
          while ((next = next.nextElementSibling)) {
            if (next == null) break;
            if (next.tagName.toLowerCase() == "input") {
              next.focus();
              break;
            }
          }
        } else if (myLength === 0) {
          let previous = target;
          while ((previous = previous.previousElementSibling)) {
            if (previous == null) break;
            if (previous.tagName.toLowerCase() === "input") {
              previous.focus();
              break;
            }
          }
        }
      };
    },
    verEmail() {
      let n1 = document.getElementById("t1").value;
      let n2 = document.getElementById("t2").value;
      let n3 = document.getElementById("t3").value;
      let n4 = document.getElementById("t4").value;
      let n5 = document.getElementById("t5").value;
      let n6 = document.getElementById("t6").value;
      let newotp = n1 + n2 + n3 + n4 + n5 + n6;
      // console.log("otpFirst",n1+n2+n3+n4+n5+n6)
      axios
        .post("verify-email", {
          email: localStorage.getItem("email"),
          email_otp: newotp,

          // new_password: this.new_password,
          // confirm_password: this.confirm_password,
        })
        .then((response) => {
          if (response.data.status_code == 200) {
            this.$router.push("/login");
          }
        });
    },
    resendEmail() {
      this.loading = true;
      axios
        .post("resend-email-otp", {
          email: localStorage.getItem("email"),

          // new_password: this.new_password,
          // confirm_password: this.confirm_password,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  background-size: cover;
  background-image: url("../assets/background-image.png");
  background-repeat: no-repeat;
}

.form {
  overflow: hidden;
  background: #fff;
  border-radius: 2.125rem;
  width: 50%;
  box-shadow: 0 0 31px #451111;
}

.strength {
  border: 1px solid black;
  height: 10px;
}

.OTP1 {
  white-space: nowrap;
}

.text1 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 0%;
}

.text2 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 2%;
}

.text3 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 2%;
}

.text4 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 2%;
}

.text5 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 2%;
}

.text6 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 2%;
}

.dandiya-sticks {
  width: 17%;
  position: fixed;
  top: -56px;
  left: -67px;
}

.rangoli {
  width: 23%;
  position: fixed;
  top: 163px;
  left: 207px;
}

.garba-jocky img {
  width: 16%;
  position: fixed;
  left: 254px;
  top: 206px;
}

.dancer img {
  width: 16%;
  position: fixed;
  left: -7px;
  top: 501px;
}

.rangoli-1 img {
  width: 11%;
  position: fixed;
  left: 781px;
  top: 50px;
  z-index: -2;
}

.rangoli-2 img {
  position: fixed;
  width: 28%;
  top: -192px;
  left: 1173px;
  z-index: -2;
}

.rangoli-3 {
  top: 512px;
  left: 1208px;
  width: 7%;
  position: fixed;
  z-index: -2;
}

#btn {
  padding: 11px 121px 14px;
  border-radius: 8px;
}

h2 {
  color: #c10111;
}

.inputbox {
  width: 3vh;
  outline: none;
  height: 31px;
  margin: 0px 1px 20px;
  font-size: 25px;
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 3px solid #c10111;
}

.otp-box1 {
  margin: 127px 16px 27px -40px;
  padding: 9px;
  border-radius: 5px;
  background-color: #d4d4d4;
}

.otp-box2 {
  margin: 127px 16px 27px 2px;
  padding: 8px;
  border-radius: 5px;
  background-color: #d4d4d4;
}

.otp-box3 {
  margin: 127px 16px 27px 2px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d4d4d4;
}

.otp-box4 {
  margin: 127px 16px 27px 2px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d4d4d4;
}

.otp-box5 {
  margin: 127px 16px 27px 2px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d4d4d4;
}

.otp-box6 {
  margin: 127px 16px 27px 2px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d4d4d4;
}

@media screen and (max-width: 600px) {
  .text-center {
    margin-bottom: 1rem;
  }
  .form {
    float: right;
    width: 400px;
    height: 400px;
    margin: 107px 83px 10px;
    box-shadow: 0 0 7px #d4d4d4;
    padding: 12px 30px 21px;
    background: #ffff;
    border-radius: 2%;
  }

  .form-row {
    margin: -54px 36px 12px;
    /* display: flex; */
  }

  .otp_title {
    font-weight: 700;
    margin-top: 46px;
  }

  .strength {
    border: 1px solid black;
    height: 10px;
  }

  .dandiya-sticks {
    width: 17%;
    position: fixed;
    top: -56px;
    left: -67px;
    display: none;
  }

  .rangoli {
    width: 23%;
    position: fixed;
    top: 163px;
    left: 207px;
    display: none;
  }

  .garba-jocky img {
    width: 16%;
    position: fixed;
    left: 254px;
    top: 206px;
    display: none;
  }

  .dancer img {
    width: 16%;
    position: fixed;
    left: -7px;
    top: 501px;
    display: none;
  }

  .rangoli-1 img {
    width: 11%;
    position: fixed;
    left: 781px;
    top: 50px;
    z-index: -2;
    display: none;
  }

  .rangoli-2 img {
    position: fixed;
    width: 28%;
    top: -192px;
    left: 1173px;
    z-index: -2;
    display: none;
  }

  .rangoli-3 {
    top: 512px;
    left: 1208px;
    width: 7%;
    position: fixed;
    z-index: -2;
    display: none;
  }

  #btn {
    padding: 11px 121px 14px;
    border-radius: 8px;
  }

  h2 {
    color: #c10111;
  }

  .inputbox {
    width: 3vh;
    height: 31px;
    margin: 0px 1px 20px;
    font-size: 25px;
    text-align: center;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background-color: transparent;
    border-bottom: 3px solid #c10111;
  }

  .otp-box1 {
    margin: 127px 16px 27px -40px;
    padding: 9px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .otp-box2 {
    margin: 127px 16px 27px 2px;
    padding: 8px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .otp-box3 {
    margin: 127px 16px 27px 2px;
    padding: 10px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .otp-box4 {
    margin: 127px 16px 27px 2px;
    padding: 10px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .resendOTP {
    float: right;
    margin: -13px -25px 9px;
    font-size: 14px;
    cursor: pointer;
  }

  .submit-btn {
    background-color: #c10111;
    color: #ffffff;
    padding: 3px 135px 6px;
    margin: 9px -31px 10px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 993px) {
  .main {
    justify-content: center !important;
  }

  .form {
    width: 75%;
  }
}

@media screen and(max-width: 1250px) {
  .form {
    float: right;
    width: 400px;
    height: 400px;
    margin: 108px 5px 10px;
    box-shadow: 0 0 7px #d4d4d4;
    padding: 12px 30px 21px;
    background: #ffff;
    border-radius: 2%;
  }

  .form-row {
    margin: -54px 36px 12px;
  }

  .otp_title {
    font-weight: 700;
    margin-top: 46px;
  }

  .strength {
    border: 1px solid black;
    height: 10px;
  }

  .dandiya-sticks {
    width: 17%;
    position: fixed;
    top: -56px;
    left: -67px;
  }

  .rangoli {
    width: 23%;
    position: fixed;
    top: 163px;
    left: 207px;
  }

  .garba-jocky img {
    width: 16%;
    position: fixed;
    left: 254px;
    top: 206px;
  }

  .dancer img {
    width: 16%;
    position: fixed;
    left: -7px;
    top: 501px;
  }

  .rangoli-1 img {
    width: 11%;
    position: fixed;
    left: 781px;
    top: 50px;
    z-index: -2;
  }

  .rangoli-2 img {
    position: fixed;
    width: 30%;
    top: -184px;
    left: 1060px;
    z-index: -2;
  }

  .rangoli-3 {
    top: 512px;
    left: 1208px;
    width: 7%;
    position: fixed;
    z-index: -2;
  }

  #btn {
    padding: 11px 121px 14px;
    border-radius: 8px;
  }

  h2 {
    color: #c10111;
  }

  .inputbox {
    width: 3vh;
    height: 31px;
    margin: 0px 1px 20px;
    font-size: 25px;
    text-align: center;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background-color: transparent;
    border-bottom: 3px solid #c10111;
  }

  .otp-box1 {
    margin: 127px 16px 27px -40px;
    padding: 9px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .otp-box2 {
    margin: 127px 16px 27px 2px;
    padding: 8px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .otp-box3 {
    margin: 127px 16px 27px 2px;
    padding: 10px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .otp-box4 {
    margin: 127px 16px 27px 2px;
    padding: 10px;
    border-radius: 5px;
    background-color: #d4d4d4;
  }

  .resendOTP {
    float: right;
    margin: -13px -25px 9px;
    font-size: 14px;
    cursor: pointer;
  }

  .submit-btn {
    background-color: #c10111;
    color: #ffffff;
    padding: 3px 135px 6px;
    margin: 9px -31px 10px;
    border-radius: 8px;
  }
}

@media (min-width: 1600px) {
  .container {
    margin-right: 13rem;
  }
}
</style>
