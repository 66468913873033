<template>
  <div>
    <div class="sidebar w-25 vh-100" :class="{ 'is-open': isSidebarOpen }">
      <div class="mt-3 px-3 d-flex align-items-center justify-content-between">
        <h1 class="custom-text-color fs-1 ms-2 fw-bolder">Menu</h1>
        <button
          type="button"
          class="btn-close fw-bold fs-5"
          aria-label="Close"
          @click="$emit('closeSidebar')"
        ></button>
      </div>

      <ul class="py-0 px-1 my-3 mx-0">
        <li class="mx-3 my-3 w-75 list-unstyled">
          <!-- <img src="../Profile_Screens/assets/Home_selected.svg" alt=""/> -->
          <router-link to="/home" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              @click="colorChangeHome"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
            >
              <img
                src="../Profile_Screens/assets/Home_selected.svg"
                alt
                v-if="activeHome"
              />
              <img
                src="../Profile_Screens/assets/Home_unselected.svg"
                v-if="!activeHome"
                @click="toggleImage"
                alt
              />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block1"
                style="margin-left: 0.5rem"
              >
                - Home</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/Notification" class="text-decoration-none">
            <!-- <img src="../Profile_Screens/assets/bell.svg" alt="" /> -->
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              @click="colorChangeNotification"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
            >
              <img
                src="../Profile_Screens/assets/bell.svg"
                alt
                v-if="!activeHome"
              />
              <img
                src="../Profile_Screens/assets/unselected_bell.svg"
                v-if="activeHome"
                @click="toggleImage"
                alt
              />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block"
                style="margin-left: 0.38rem"
              >
                - Notification</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/GarbaPage" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
              @click="colorChangeGarbaClass"
            >
              <img src="../Profile_Screens/assets/speaker_selected.svg" alt />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block2"
                style="margin-left: 0.6rem"
              >
                - Garba Class</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/GarbaJockey" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
              @click="colorChangeGarbaJockey"
            >
              <img src="../Profile_Screens/assets/headphones_fill.svg" alt />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block3"
                style="margin-left: 0.85rem"
              >
                - Garba Jocky</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/IBTalks" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
              @click="colorChangeGarbaJockey"
            >
              <img src="../Profile_Screens/assets/microphone-342.svg" alt />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block3"
                style="margin-left: 0.85rem"
              >
                - IB Talks</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/AllBlogs" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
              @click="colorChangeGarbaJockey"
            >
              <img src="../Profile_Screens/assets/blogger.svg" alt />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block3"
                style="margin-left: 0.85rem"
              >
                - Blogs</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/tutorialPage" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
              @click="colorChangeGarbaJockey"
            >
              <img src="../Profile_Screens/assets/tutorial_icon_2.svg" alt />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block3"
                style="margin-left: 0.85rem"
              >
                - Tutorials</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-75 list-unstyled">
          <router-link to="/eventpage" class="text-decoration-none">
            <div
              class="home-logo d-flex justify-conent-center align-items-center ms-1"
              mouseover="label1"
              @mouseleave="hovered = false"
              role="link"
              :class="activeHome ? 'active-color' : 'non-activecolor'"
              @click="colorChangeEvent"
            >
              <img src="../Profile_Screens/assets/date_selected.svg" alt />
              <span
                class="fs-5 fw-bold custom-text-color"
                id="block4"
                style="margin-left: 0.8rem"
              >
                - Event</span
              >
            </div>
          </router-link>
        </li>

        <li class="mx-3 my-3 w-100 d-flex list-unstyled">
          <img
            :src="item.profile_photo ? item.profile_photo : defaultImage"
            class="rounded-3 mr-2 cursor-pointer"
            style="width: 2.2rem; height: 2.2rem"
            @click="Homemenu"
          />
          <div class="d-flex flex-column w-75 ms-0" id="dropdown-text">
            <router-link
              to="/HomePage/MyProfile"
              style="text-decoration: none; color: black"
            >
              <span class="fs-5 fw-bold custom-text-color" id="block4"
                >- My profile</span
              >
              <hr />
            </router-link>
            <router-link
              to="/HomePage/ChangePassword"
              style="text-decoration: none; color: black"
            >
              <span class="fs-5 fw-bold custom-text-color" id="block4"
                >- Change Password</span
              >
              <hr />
            </router-link>
            <router-link to="/" style="text-decoration: none; color: black">
              <span class="fs-5 fw-bold custom-text-color" id="block4"
                >- Logout</span
              >
              <hr />
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

// import { ref } from 'vue';
export default {
  name: "NavBar",
  data() {
    return {
      item: [],
      defaultImage: require("@/assets/profile-no-img.png"),
      isSidebarOpen: false,
    };
  },

  mounted() {
    // this.userSub();
    // this.getGCDe();
    this.getDa();

    // Add a click event listener to the document
    document.addEventListener("click", this.handleDocumentClick);
  },

  beforeUnmount() {
    // Remove the click event listener when the component is destroyed
    document.removeEventListener("click", this.handleDocumentClick);
  },

  methods: {
    openSidebar() {
      this.isSidebarOpen = true;
    },

    closeSidebar() {
      // console.log("Closing sidebar");
      this.isSidebarOpen = false;
    },

    homeIcone() {
      alert("you clicked on Home page");
    },

    navbarActiveFunction() {
      this.navbarActive = !this.navbarActive;
    },

    Homemenu() {
      var x = document.getElementById("dropdown-text");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },

    getDa() {
      try {
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);
        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;
          // Add more cases for other user types as needed
          default:
            endpoint = "search"; // or 'default-profile' if needed
            break;
        }

        axios.get(endpoint).then((response) => {
          this.item = response.data.data;
          // console.log("from nav bar", this.item);
          // alert(this.item);
        });
      } catch (err) {
        console.log(err);
      }
    },

    colorChangeHome() {
      this.activeHome = !this.activeHome;
      this.activeNotification = false;
      this.activeGarbaClass = false;
      this.activeGarbaJockey = false;
      this.activeEvent = false;
    },

    toggleImage() {
      this.showImage = !this.showImage;
    },

    handleDocumentClick(event) {
      if (this.isSidebarOpen && !this.$el.contains(event.target)) {
        this.closeSidebar();
      }
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.sidebar {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  transition: left 0.5s ease-in-out;
  z-index: 1000;
}

/* .sidebar.is-open {
    left: 0;
} */

.profile-logo {
  margin-left: 5rem;
  width: 5%;
  text-align: center;
  margin-top: 0%;
}

.custom-text-color {
  color: #252323;
}

@media (min-width: 993px) {
  .sidebar {
    display: none;
  }
}

@media (max-width: 992px) {
  .sidebar {
    width: 46% !important;
  }
}

@media (max-width: 650px) {
  .sidebar {
    width: 50% !important;
  }
}

@media (max-width: 499px) {
  .sidebar {
    width: 60% !important;
  }
}

@media (max-width: 422px) {
  .sidebar {
    width: 70% !important;
  }
}

@media (max-width: 380px) {
  .sidebar {
    width: 80% !important;
  }
}

@media (max-width: 320px) {
  .sidebar {
    width: 85% !important;
  }
}
</style>
