<template>
  <div class="row">
    <div
      class="col-md-9 d-flex justify-content-between align-items-center w-100"
    >
      <div class="img">
        <img
          class="rounded-circle my-2"
          :src="item.profile_photo ? item.profile_photo : defaultImage"
          style="width: 6rem; height: 6.1rem"
        />
        <!-- <img class="w-75 rounded-4 my-2" :src="item.profile_photo" style="height: 6rem;" /> -->
        <!-- <img class="w-75 rounded-4 my-2" src="../assets/profile-no-img.png" v-if="item.profile_photo === ''"
          style="height: 6rem;" /> -->
      </div>
      <div class="d-flex flex-column top-button position-absolute top-0 end-0">
        <button
          class="btn btn-danger"
          @click="toggleEditMode"
          :disabled="disableEditButton"
        >
          Edit
        </button>
        <button
          class="btn btn-outline-danger mt-3"
          @click="toggleEditMode"
          :disabled="disableCancelButton"
        >
          Cancel
        </button>
      </div>
    </div>

    <div class="col-md-12">
      <div class="profile-form">
        <div class="first-last-name row">
          <div class="col-md-6">
            <label for="first_name" class="form-label text-danger"
              >First name</label
            >
            <input
              type="text"
              class="form-control"
              id="first_name"
              required
              v-model="first_name"
              :readonly="!isEditMode"
            />
          </div>
          <div class="col-md-6">
            <label for="last_name" class="form-label text-danger"
              >Last name</label
            >
            <input
              type="text"
              class="form-control"
              id="last_name"
              required
              v-model="last_name"
              :readonly="!isEditMode"
            />
          </div>
        </div>

        <div class="email mb-3">
          <label for="email" class="form-label text-danger">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            required
            v-model="email"
            :readonly="!isEditMode"
          />
        </div>

        <div class="mobile-number mb-3">
          <label for="mobile" class="form-label text-danger"
            >Mobile number</label
          >
          <input
            type="number"
            class="form-control"
            id="mobile"
            maxlength="10"
            placeholder="+91 7084XXXXXX"
            required
            v-model="mobile"
            :readonly="!isEditMode"
          />
        </div>

        <div class="date-of-birth mb-3">
          <label for="birth_date" class="form-label text-danger"
            >Date Of Birth</label
          >
          <input
            type="date"
            class="form-control"
            id="birth_date"
            required
            v-model="birth_date"
            :readonly="!isEditMode"
          />
        </div>

        <div class="country-dtails row">
          <div class="col-md-4">
            <label for="country_id" class="form-label text-danger"
              >Country</label
            >
            <select
              v-model="country_id"
              @change="fetchStates"
              class="form-select"
              :disabled="!isEditMode"
            >
              <option value="" disabled>Select Country</option>
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="state_id" class="form-label text-danger">State</label>
            <select
              v-model="state_id"
              @change="fetchCities"
              class="form-select"
              :disabled="!isEditMode"
            >
              <option value="" disabled>Select State</option>
              <option v-for="state in states" :key="state.id" :value="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="city_id" class="form-label text-danger">City</label>
            <select
              v-model="city_id"
              class="form-select"
              :disabled="!isEditMode"
            >
              <option value="" disabled>Select City</option>
              <option v-for="city in cities" :key="city.id" :value="city.id">
                {{ city.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-save-button mt-3" v-if="isEditMode">
          <button class="btn btn-success" @click="proUp()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from "./NavBar.vue";

import axios from "@/axios";
export default {
  name: "MyProfile",
  components: {
    // NavBar
  },

  data() {
    return {
      GarbaClasses: [],
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      birth_date: "",
      country_id: "",
      state_id: "",
      city_id: "",
      item: [],
      countries: [],
      states: [],
      cities: [],
      userIdChangeInfo: "",
      isEditMode: false,
      defaultImage: require("@/assets/profile-no-img.png"),
    };
  },
  mounted() {
    this.getDa();
    this.fetchCountries();
  },
  computed: {
    disableEditButton() {
      // Disable the "Edit" button when in edit mode
      return this.isEditMode;
    },
    disableCancelButton() {
      // Disable the "Cancel" button when not in edit mode
      return !this.isEditMode;
    },
  },
  methods: {
    async getDa() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        // alert("edit btn")
        axios.get(endpoint).then((response) => {
          // this.id = response.data.data.id;
          // console.log((this.id = response.data.data.id));
          this.item = response.data.data;
          // console.log(this.item);

          this.userIdChangeInfo = this.item.id;
          this.first_name = this.item.first_name;
          this.last_name = this.item.last_name;
          this.email = this.item.email;
          this.mobile = this.item.mobile;
          this.birth_date = this.item.birth_date;
          this.country_id = this.item.country_id;
          this.state_id = this.item.state_id;
          this.city_id = this.item.city_id;

          // console.log(this.country_id);
          // console.log(this.state_id);
          // console.log(this.city_id);

          this.fetchStates();
          this.fetchCities();
        });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    chanG() {
      let old = document.getElementById("old_password").value;
      let newp = document.getElementById("new_password").value;
      let cnf = document.getElementById("confirm_password").value;
      axios
        .post("change-password", {
          headers: {
            DeviceID: "1111",
            versionName: "1.0.0",
            DeviceType: "0",
            // responseType:"arraybuffer"
          },
          old_password: old,
          new_password: newp,
          confirm_password: cnf,
        })
        .then((response) => {
          // console.log(response.data)
          this.item = response.data.data;
        });
    },

    proUp() {
      const endpoint = this.getUserProfileEndpoint();

      axios
        .post(endpoint, {
          headers: {
            DeviceID: "1111",
            versionName: "1.0.0",
            DeviceType: "0",
          },
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          mobile: this.mobile,
          birth_date: this.birth_date,
          country_id: this.country_id,
          state_id: this.state_id,
          city_id: this.city_id,
        })
        .then(() => {
          // After successfully saving, turn off the edit mode
          this.isEditMode = false;
        })
        .catch((err) => {
          console.log("error when update the user info : ", err);
        });
    },

    getUserProfileEndpoint() {
      const userType = this.$store.state.userType;
      switch (userType) {
        case "1":
          return `entrant/users/${this.userIdChangeInfo}`;
        case "3":
          return `gc/users/${this.userIdChangeInfo}`;
        case "2":
          return `gj/users/${this.userIdChangeInfo}`;
        default:
          return "default-profile";
      }
    },

    // GCproUp() {
    //   let a = document.getElementById("first_name").value;
    //   let b = document.getElementById("last_name").value;
    //   let c = document.getElementById("email").value;
    //   let d = document.getElementById("mobile").value;
    //   let e = document.getElementById("birth_date").value;
    //   let f = document.getElementById("country_id").value;
    //   let g = document.getElementById("state_id").value;
    //   let h = document.getElementById("city_id").value;

    //   axios.post(`gc/users/${this.id}`, {
    //     headers: {
    //       DeviceID: "1111",
    //       versionName: "1.0.0",
    //       DeviceType: "0",
    //       // responseType:"arraybuffer"
    //     },
    //     first_name: a,
    //     last_name: b,
    //     email: c,
    //     mobile: d,
    //     birth_date: e,
    //     country_id: f,
    //     state_id: g,
    //     city_id: h,
    //   });
    // },

    // GJproUp() {
    //   let a = document.getElementById("first_name").value;
    //   let b = document.getElementById("last_name").value;
    //   let c = document.getElementById("email").value;
    //   let d = document.getElementById("mobile").value;
    //   let e = document.getElementById("birth_date").value;
    //   let f = document.getElementById("country_id").value;
    //   let g = document.getElementById("state_id").value;
    //   let h = document.getElementById("city_id").value;

    //   axios.post(`gc/my-profile${this.id}`, {
    //     headers: {
    //       DeviceID: "1111",
    //       versionName: "1.0.0",
    //       DeviceType: "0",
    //       // responseType:"arraybuffer"
    //     },
    //     first_name: a,
    //     last_name: b,
    //     email: c,
    //     mobile: d,
    //     birth_date: e,
    //     country_id: f,
    //     state_id: g,
    //     city_id: h,
    //   });
    // },

    fetchCountries() {
      this.countries = []; // Clear existing countries

      const fetchPage = async (page) => {
        try {
          const response = await axios.get(`countries?page=${page}`);
          this.countries = [...this.countries, ...response.data.data];

          // Check if there are more pages
          const nextPageUrl = response.data.next_page_url;
          if (nextPageUrl) {
            const nextPage = new URL(nextPageUrl).searchParams.get("page");
            await fetchPage(nextPage);
          }
        } catch (error) {
          console.error("Error fetching countries:", error);
        }
      };

      fetchPage(1); // Start fetching from page 1
    },

    fetchStates() {
      // Use the selected country ID to fetch states
      const selectedCountryId = this.country_id;
      // console.log(selectedCountryId);
      if (!selectedCountryId) {
        return;
      }
      axios
        .get(`states_of_country?country_id=${selectedCountryId}`)
        .then((response) => {
          this.states = response.data.data;
          // console.log("country of sattes = ", this.states);
          this.fetchCities();
        });
    },

    fetchCities() {
      // Use the selected state ID to fetch cities
      const selectedStateId = this.state_id;
      if (!selectedStateId) {
        return;
      }
      axios
        .get(`cities_of_state?state_id=${selectedStateId}&is_light=1`)
        .then((response) => {
          this.cities = response.data.data;
        });
    },

    toggleEditMode() {
      this.isEditMode = !this.isEditMode;

      // If switching to edit mode, fetch data
      if (this.isEditMode) {
        this.getDa();
      }
    },
  },
};
</script>


