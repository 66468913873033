<template>
  <div class="custom-animation-gj">
    <NavBar />

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-column mb-4">
            <div class="d-flex justify-content-between">
              <h3 class="my-garba-class-heading text-danger">
                My Garba classes
              </h3>
              <router-link
                to="/UplodedDetails"
                class="text-decoration-none text-dark"
              >
                <img class="plus-icon-img" src="../assets/add_icon.svg" alt />
              </router-link>
            </div>
            <span>
              <hr />
              <div
                style="
                  border-bottom: 2px solid #dc3545;
                  position: relative;
                  top: -17px;
                  width: 30%;
                "
              ></div>
            </span>
          </div>

          <div v-if="$store.state.userType === '3'">
            <div class="garba-class-details">
              <!-- Display only the last Garba class -->
              <div
                v-for="(GarbaClass, index) in GarbaClasses.slice(
                  Math.max(GarbaClasses.length - 3, 0)
                )"
                :key="index"
                class="card d-flex flex-row align-items-center justify-content-between my-2"
              >
                <div>
                  <h3 class="card-body">{{ GarbaClass.garba_class_name }}</h3>
                </div>
                <div class="d-flex flex-row align-items-center mx-3">
                  <img
                    src="../assets/view_ic.svg"
                    alt="View Icon"
                    class="img-fluid mr-2"
                  />
                  <img
                    src="../assets/trash.svg"
                    alt="Trash Icon"
                    class="img-fluid"
                  />
                </div>
              </div>

              <!-- Display only the last three awards -->
              <div class="d-flex flex-column mt-4" v-if="Myawards.length > 0">
                <h3 class="text-danger">My Awards</h3>
                <div
                  class="mt-2"
                  style="border-bottom: 2px solid #dc3545; width: 20%"
                ></div>
                <div class="d-flex flex-row justify-content-center mt-2">
                  <div
                    v-for="(award, index) in Myawards.slice(
                      Math.max(Myawards.length - 3, 0)
                    )"
                    :key="index"
                    class="mr-2"
                  >
                    <img
                      :src="award.award_won_photo"
                      alt="My awards"
                      class="img-fluid mx-2"
                      style="max-width: 300px; max-height: 300px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Show Create own garba class button for user type 3 as well -->
      <div
        v-if="$store.state.userType === '1'"
        class="garbaclass-section mt-5 shadow-sm p-3 bg-white rounded"
      >
        <div
          class="garbaclass-container d-flex justify-content-center align-items-center flex-column"
        >
          <p class="garbaclass-instuction">
            Share your passion for Garba with the world by creating your own
            Garba class on GarbaJockey.
          </p>
          <router-link to="/UplodedDetails">
            <button class="btn btn-danger create-garba-button">
              Create own garba class
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
// import PostNav from "./PostNav.vue";

export default {
  name: "MyGarbaClass",
  components: {},
  data() {
    return {
      GarbaClasses: [],
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      birth_date: "",
      country_id: "",
      state_id: "",
      city_id: "",
      item: [],
      Myawards: [],
      Mypost: [],
      Gc_video: [],
      HiddenPost: [],
      video: null,
      title: "",
      description: "",
      video_preview: "",
      width: "",
      height: "",
      sr_no: "1",
      ShowVideos: [],
      gc_video: null,
      gc_id: "",
      userType: "",
    };
  },
  mounted() {
    // Access userType in your component
    this.userType = this.$store.state.userType;

    // console.log(this.userType);

    if (this.userType === "3") {
      this.GetMyGc();
    }

    this.GetMyAwards();
    // this.GetEntrantGc();
    this.GetMyPost();
  },
  methods: {
    GetMyGc() {
      axios
        .get("gc/my-garba-classes")
        .then((response) => {
          // console.log(response);
          this.GarbaClasses = response.data.data.garba_classes || [];

          // console.log(this.GarbaClasses);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
      this.GetMyPost();
    },

    // GetEntrantGc() {
    //   axios
    //     .get("entrant/my-garba-classes")
    //     .then((response) => {
    //       console.log(response.data.data);
    //       this.GarbaClasses = response.data.data.garba_classes;
    //       console.log(this.GarbaClasses);
    //     })
    //     .catch((error) => {
    //       console.log(error.response.data.message);
    //     });
    // },

    GetMyAwards() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = `entrant/awards?${this.id}`;
            break;
          case "3":
            endpoint = `gc/awards?${this.id}`;
            break;
          case "2":
            endpoint = `gj/awards?${this.id}`;
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-awards";
            break;
        }

        axios.get(endpoint).then((response) => {
          this.Myawards = response.data.data || [];
          // console.log("My Awards = ", this.Myawards);
        });
      } catch (err) {
        console.log("error of awrds = ", err);
      }
    },

    GetMyPost() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = `entrant/posts/my-posts`;
            break;
          case "3":
            endpoint = `gc/posts/my-posts`;
            break;
          case "2":
            endpoint = `gj/posts/my-posts`;
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-posts";
            break;
        }

        axios.get(endpoint).then((response) => {
          this.Mypost = response.data.data || [];
          // console.log(response.data);

          // if (response.data.data.videos != 5 || response.data.data.videos > 5) {
          //   // alert("hello");
          //   let hidegc = document.getElementById("mygarba");
          //   hidegc.style.display = "none";
          //   let showgc = document.getElementById("mygarba-head");
          //   showgc.style.display = "none";
          //   let showawards = document.getElementById("garba-awards")
          //   showawards.style.visibility = "none"
          // } else {
          //   let showgc = document.getElementById("mygarba");
          //   showgc.style.display = "block";
          //   let hidegc = document.getElementById("mygarba-head");
          //   hidegc.style.display = "flex";
          //   let showawards = document.getElementById("garba-awards")
          //   showawards.style.display = "block"
          // }
        });
      } catch (err) {
        console.log("error of my post's = ", err);
      }
    },
  },
};
</script>

<style scoped></style>
