<template>
  <div class="custom-signupImage w-100 pt-3">
    <!-- About Infinity Brains Section  -->

    <AboutInfinityBrains />

    <div
      class="ml-md-3 d-flex flex-column justify-content-center align-items-center"
    >
      <h1 class="fw-bold mt-4">
        <span class="text-danger">who</span> we are ?
      </h1>
      <h4 class="fw-semibold text-center">
        Garba Jockey: A Celebratory Odyssey of Rhythmic Bliss
      </h4>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start">
        <div class="col-md-6 bg-body p-0 cu-bg">
          <img
            src="../assets/contentImage/Arial_View_of_Garba_Mahotsav.jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6">
          <div
            class="ml-md-3 d-flex flex-column justify-content-center align-items-center"
          >
            <p class="fw-light fs-4">
              In the constantly shifting landscape of social media networks, where various communities carve out their unique spaces, one network emerges as a lively center for a festivity that crosses boundaries and cultures – Garba Jockey. Established on the 29th of September, 2019, Garba Jockey isn't merely a social media network; it's a cultural phenomenon devoted to the captivating dance form of Garba.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start flex-row-reverse">
        <div class="col-md-6 bg-body p-0 cu-bg-right">
          <img
            src="../assets/contentImage/16450.jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6 text-end">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              The inception of Garba Jockey in 2019 marked a pivotal moment for Garba enthusiasts globally. With a mission grounded in passion and dedication, the platform was designed to not only celebrate the beauty of Garba but also to preserve its rich cultural heritage. As the first social media network exclusively for Garba lovers, Garba Jockey aimed to create a virtual space where the vibrant rhythms of Garba could transcend geographical boundaries.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start">
        <div class="col-md-6 bg-body p-0 cu-bg">
          <img
            src="../assets/contentImage/16453.jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              Garba, a traditional dance form originating from the Indian state of Gujarat, is more than just a rhythmic expression. It is a cultural heritage passed down through generations, embodying the spirit of community, celebration, and devotion. Garba Jockey recognizes this profound significance and strives to be a torchbearer in the preservation and promotion of Garba's cultural essence. It is more than a network for Garba enthusiasts; it is a testament to the universal language of rhythm and festivity.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start flex-row-reverse">
        <div class="col-md-6 bg-body p-0 cu-bg-right">
          <img
            src="../assets/contentImage/garba1-blog-photo.jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6 text-end">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              Garba Jockey isn't merely a social media platform; it's an immersive experience designed to captivate and engage Garba enthusiasts. The platform offers a myriad of features, each meticulously crafted to provide a unique and unparalleled experience for its users. Future developments include enhanced virtual reality experiences, live streaming of Garba events worldwide, and partnerships with renowned Garba performers.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start">
        <div class="col-md-6 bg-body p-0 cu-bg">
          <img
            src="../assets/contentImage/webevent1180x700px2_0.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              At the heart of Garba Jockey is its ability to connect Garba
              enthusiasts worldwide. Through the platform, individuals from
              diverse backgrounds, cultures, and geographical locations come
              together to share their love for Garba. It serves as a global
              stage where the rhythmic beats of Garba unify a diverse community
              As Garba Jockey continues to evolve, it remains dedicated to
              pushing boundaries and exploring new frontiers.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start flex-row-reverse">
        <div class="col-md-6 bg-body p-0 cu-bg-right">
          <img
            src="../assets/contentImage/news-14-Garba-Dance.jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6 text-end">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              Garba Jockey goes beyond the conventional concept of social media
              by introducing virtual Garba arenas. Users can join these digital
              dance floors, immersing themselves in the joyous rhythm of Garba.
              The platform utilizes cutting-edge technology to recreate the
              ambiance of a live Garba celebration, allowing users to experience
              the dance in a virtual space.The impact of Garba Jockey is not
              confined to the digital space alone.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75 my-5">
      <div class="row g-5 align-items-start">
        <div class="col-md-6 bg-body p-0 cu-bg">
          <img
            src="../assets/contentImage/a1bfa179a8425da9bae7f4065ed8ccd5 (1).jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              Recognizing the richness of Garba's cultural heritage, Garba
              Jockey serves as a digital museum. It houses a comprehensive
              showcase of Garba's history, evolution, and regional variations.
              Through multimedia content, users can delve into the intricate
              details of Garba's journey, fostering a deeper appreciation for
              this art form.Garba Jockey, since its inception, has become more
              than just a platform; it's a community that thrives on shared
              enthusiasm and cultural pride.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid w-75 mt-5 pb-5">
      <div class="row g-5 align-items-start flex-row-reverse">
        <div class="col-md-6 bg-body p-0 cu-bg-right">
          <img
            src="../assets/contentImage/616fff847728f3e8e2b9653ad15faa6b.jpg"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
            style="
              width: 85%;
              height: 23rem;
              box-shadow: 0px 0px 9px black;
              border-radius: 3.5rem;
            "
          />
        </div>
        <div class="col-md-6 text-end">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              Garba Jockey is not just a spectator's platform; it is an educational hub for those eager to learn the art of Garba. The platform offers tutorials, workshops, and interactive sessions conducted by seasoned Garba experts. This aspect of Garba Jockey ensures that the dance form continues to thrive and evolve, passing on the skills to the next generation. Adding a competitive yet joyous element, Garba Jockey introduces celebratory challenges.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutInfinityBrains from "./AboutInfinityBrains.vue";

export default {
  name: "SignupSecond",
  components: {
    AboutInfinityBrains,
  },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=popins+Display&display=swap");
*/
* {
  font-weight: 500;
} 
.custom-signupImage {
  background-color: whitesmoke;
}

.my-5 {
  margin: 6.5rem auto !important;
}

.cu-bg,
.cu-bg-right {
  background: transparent !important;
}
.cu-bg-right {
  text-align: right;
}

@media (max-width: 767px) {
  .text-end {
    text-align: left !important;
  }
  .cu-bg,
  .cu-bg-right {
    text-align: center !important;
  }
}
</style>
