<template>
  <div>
    <!-- Add Comment Section -->
    <div class="input-group mb-3 d-flex align-items-center">
      <!-- <img src="img/user2.jpg" alt="User img" class="author-img author-img--small mr-2" /> -->
      <img
        class="addCommemt-img mx-1 me-2"
        :src="
          comment && comment.user && comment.user.profile_photo
            ? comment.user.profile_photo
            : defaultImage
        "
      />

      <input
        type="text"
        class="form-control"
        v-model="comment"
        placeholder="Add a comment..."
        id="comment"
        @keyup.enter="submitHandler"
      />
      <button
        @click="submitHandler"
        class="btn btn-danger rounded-3 ms-2"
        :class="{ 'animate__animated animate__tada': iconClicked }"
      >
        Send
      </button>
      <div class="input-group-append"></div>
    </div>

    <!-- View Comments Section -->
    <div
      class="d-flex justify-contnet-center align-items-center flex-column w-100"
      v-for="comment in displayedComments"
      :key="comment && comment.id"
    >
      <div class="d-flex w-100">
        <img
          class="comment-img mx-1 me-2"
          :src="
            comment.user.profile_photo
              ? comment.user.profile_photo
              : defaultImage
          "
        />
        <div
          class="d-flex flex-column w-100 rounded-4 mx-1 px-3"
          style="background: #f0f0f0"
        >
          <div>
            <span class="fs-6">{{ comment.user.first_name }}</span>
            <span class="px-2 fs-6">{{ comment.user.last_name }}</span>
          </div>
          <span class="fs-6">{{ comment.comment }}</span>
        </div>
      </div>
      <hr class="w-100" />
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
// import UserComT from "./UserComT.vue";

export default {
  name: "CommentViewAndAdd",
  props: {
    postId: String,
    post: Object,
  },
  data() {
    return {
      postt: [],
      comment: "",
      defaultImage: require("@/assets/profile-no-img.png"),
      displayedCommentsCount: 2,
      iconClicked: false,
    };
  },
  computed: {
    displayedComments() {
      return this.postt.slice(0, this.displayedCommentsCount);
    },
  },
  methods: {
    sendButtonClicked() {
      this.iconClicked = !this.iconClicked;

      setTimeout(() => {
        this.iconClicked = false;
      }, 1500);
    },
    async submitHandler() {
      this.sendButtonClicked();
      const userType = this.$store.state.userType;
      // console.log("user type =", userType);

      // Define the base endpoint for posts
      let endpoint = "";

      switch (userType) {
        case "1":
          endpoint = "entrant/comments";
          break;
        case "3":
          endpoint = "gc/comments";
          break;
        case "2":
          endpoint = "gj/comments";
          break;
        default:
          // Handle other user types or show a default set of posts
          endpoint = "default";
          break;
      }

      axios
        .post(endpoint, {
          comment: this.comment,
          post_id: this.postId,
        })
        .then(() => {
          // console.log(response);
          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
          // After adding a comment, refresh the comments
          this.showdata();
          // Clear the input field
          this.comment = "";
        });
    },

    showdata() {
      const userType = this.$store.state.userType;
      // console.log("user type =", userType);

      // Define the base endpoint for fetching comments
      let endpoint = "";

      switch (userType) {
        case "1":
          endpoint = `entrant/posts/comments?post=${this.postId}`;
          break;
        case "2":
          endpoint = `gj/posts/comments?post=${this.postId}`;
          break;
        case "3":
          endpoint = `gc/posts/comments?post=${this.postId}`;
          break;
        default:
          // Handle other user types or show a default set of comments
          endpoint = "default";
          break;
      }

      axios
        .post(endpoint, {
          post_id: this.postId,
        })
        .then((response) => {
          // console.log(response.data.data);
          this.postt = response.data.data.comments.reverse();
        });
    },

    loadMoreComments() {
      this.displayedCommentsCount += 2;
    },
  },
  mounted() {
    this.showdata();
  },
  // components: { UserComT }
};
</script>

<style scoped>
.post-block img.author-img.author-img--small {
  height: 40px;
  width: 35px;
  padding: 1%;
}

.addCommemt-img {
  width: 2.4rem;
  height: 2.3rem;
  border-radius: 50% !important;
}

.comment-img {
  width: 2.4rem;
  height: 2.3rem;
  border-radius: 50%;
}
</style>
