<template>
  <div>
    <div v-if="token === ''">
      <!-- <h1>Token does not exist, show something else or nothing</h1> -->
    </div>

    <div v-else>
      <DummyHomePage />
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import DummyHomePage from "./DummyHomePage.vue";

export default {
  name: "DummySignin",

  data() {
    return {
      dummyData: [],
      token: "",

      // apiUrl: "http://uatapi.garbajockey.com/public/api/",

      apiUrl: "https://api.garbajockey.com/api/",
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      axios
        .post(`${this.apiUrl}login`, {
          email: "garbajockey@gmail.com",
          password: "12345678",
          remember_me: "1",
        })
        .then((response) => {
          const authToken = response.data.data.authorization;

          localStorage.setItem("authToken", authToken);

          this.token = localStorage.getItem("authToken");

          const userType = response.data.data.user_type;
          this.$store.commit("setUserType", userType);

          // console.log(this.token);
        })
        .catch((error) => {
          console.log("not store the token : ", error);
        });
    },
  },
  components: { DummyHomePage },
};
</script>
  
  <style scoped></style>