<template>
  <nav
    class="col-12 col-sm-12 col-md-12 col-lg-12 navbar navbar-expand-lg navbar-light bg-red position-fixed"
    style="top: 0; left: 0; z-index: 1000"
  >
    <div class="container">
      <!-- Logo -->
      <router-link class="navbar-brand" to="/home">
        <img
          src="../Profile_Screens/assets/logo.png"
          alt="Logo"
          class="logo"
          style="height: 2.5rem"
        />
      </router-link>

      <!-- Toggler/collapsible Button -->
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        @click="toggleSideNavigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navbar Items -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <!-- Left Side: Search Input -->
        <form class="form-inline mr-auto" @submit.prevent="handleSearch">
          <div class="input-group">
            <input
              v-model="searchQuery"
              @input="handleSearch"
              class="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div class="input-group-append">
              <i
                class="bi bi-search input-group-text btn btn-outline-light"
              ></i>
            </div>
          </div>
        </form>

        <!-- Search Results Dropdown -->
        <div
          v-if="users.length > 0 && searchQuery.trim() !== ''"
          class="dropdown d-flex position-absolute top-100 w-25"
        >
          <ul
            class="dropdown-menu show"
            style="
              position: absolute;
              width: 100%;
              height: 665px;
              overflow: auto;
            "
          >
            <div class="people-section">
              <h5 class="px-3">People</h5>
              <li
                v-for="(user, index) in displayedUsers"
                :key="index"
                class="dropdown-text p-0"
              >
                <div
                  class="d-flex justify-content-between align-items-center px-3"
                >
                  <div class="w-25">
                    <img
                      :src="
                        user.profile_photo ? user.profile_photo : defaultImage
                      "
                      alt="user-image"
                      class="w-100 rounded-3"
                      style="height: 4rem; object-fit: cover"
                    />
                  </div>
                  <div class="w-100 mx-2">
                    <span>{{ user.first_name }}{{ user.last_name }}</span>
                  </div>
                  <div>
                    <button
                      @click="toggleFollow(user)"
                      type="button"
                      class="btn btn-danger"
                    >
                      {{ user.follows_flag === "0" ? "Follow" : "Unfollow" }}
                    </button>
                  </div>
                </div>
                <hr />
              </li>

              <div
                class="d-flex justify-content-center align-items-center px-3"
              >
                <button @click="toggleShowAll" class="btn btn-danger w-100">
                  {{ showAllUsers ? "See Less" : "See All" }}
                </button>
              </div>
            </div>

            <hr />

            <div class="Garba-Class-section mt-4">
              <h5 class="px-3">Garba Class</h5>
              <li
                v-for="(gc, index) in displayedGarbaClass"
                :key="index"
                class="dropdown-text p-0"
              >
                <div
                  class="d-flex justify-content-between align-items-center px-3"
                >
                  <div class="w-25">
                    <img
                      :src="
                        gc.garba_class_logo ? gc.garba_class_logo : defaultImage
                      "
                      alt="user-image"
                      class="w-100 rounded-3"
                      style="height: 4rem; object-fit: cover"
                    />
                  </div>
                  <div class="w-100 mx-2">
                    <span>{{ gc.garba_class_name }}</span>
                  </div>
                  <div>
                    <button type="button" class="btn btn-danger">
                      Suscribe
                    </button>
                  </div>
                </div>
                <hr />
              </li>

              <div
                class="d-flex justify-content-center align-items-center px-3"
              >
                <button @click="toggleShowAllGc" class="btn btn-danger w-100">
                  {{ showAllGc ? "See Less" : "See All" }}
                </button>
              </div>
            </div>

            <hr />

            <div class="Post-section mt-4">
              <h5 class="px-3">Post</h5>
              <li
                v-for="(post, index) in displayedPosts"
                :key="index"
                class="dropdown-text p-0"
              >
                <div
                  class="d-flex justify-content-between align-items-center flex-column px-3"
                >
                  <div
                    class="d-flex w-100 my-3 justify-content-center align-items-center"
                  >
                    <div class="w-50">
                      <img
                        :src="
                          post.user.profile_photo
                            ? post.user.profile_photo
                            : defaultImage
                        "
                        alt="user-image"
                        class="w-100 rounded-3"
                        style="height: 4rem; object-fit: cover"
                      />
                    </div>
                    <div class="w-100 mx-2 d-flex flex-column">
                      <span>{{ post.user.first_name }}</span>
                      <span>{{ formatTimestamp(post.created_at) }}</span>
                    </div>
                    <div class="d-flex w-100">
                      <LisT :postId="post.id" />
                    </div>
                  </div>
                  <div class="w-100">
                    <video
                      :src="post.videos[0].video"
                      controls
                      class="w-100 rounded-3"
                      style="height: 12rem"
                    ></video>
                  </div>
                </div>
                <hr />
              </li>

              <div
                class="d-flex justify-content-center align-items-center px-3"
              >
                <button
                  @click="toggleShowAllPosts"
                  class="btn btn-danger w-100"
                >
                  {{ showAllPosts ? "See Less" : "See All" }}
                </button>
              </div>
            </div>
          </ul>
        </div>

        <!-- Right Side: Home, About, Contact Links -->
        <ul
          class="navbar-nav ms-auto d-flex justify-content-end align-items-center"
        >
          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/home"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/Home_selected.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Home</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/Notification"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/bell.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Notification</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/GarbaPage"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/speaker_selected.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Garba-Class</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/GarbaJockey"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/headphones_fill.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Garba-Jockey</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/IBTalks"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/microphone-342.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">IB-Talks</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/AllBlogs"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/blogger.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Blogs</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/tutorialPage"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/tutorial_icon_2.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Tutorials</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div class="nav-link text-white fw-bolder home-dropdown">
              <router-link
                to="/eventpage"
                class="d-flex justify-content-center align-items-center m-0"
              >
                <div
                  class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2"
                >
                  <img
                    src="../Profile_Screens/assets/date_selected.svg"
                    alt="Home"
                    class="btn btn-light dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span class="dropdown-item">Event's</span>
                  </div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="nav-item active">
            <div
              class="nav-link text-white fw-bolder home-dropdown d-flex justify-content-center align-items-center"
            >
              <div
                class="home-logo d-flex justify-content-center align-items-center w-50 h-50 dropdown rounded-3 p-2 profile-img-div"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    :src="
                      items.profile_photo ? items.profile_photo : defaultImage
                    "
                    class="dropdown-toggle rounded-1"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="width: 31px; height: 25px; object-fit: cover"
                  />
                </div>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <router-link to="/HomePage/MyProfile" class="dropdown-item">
                    My Profile
                  </router-link>
                  <hr />
                  <router-link
                    to="/HomePage/ChangePassword"
                    class="dropdown-item"
                  >
                    Change Password
                  </router-link>
                  <hr />
                  <router-link to="/" class="dropdown-item">
                    Logout
                  </router-link>
                  <hr />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Use the SideNavigation component -->
    <Sidebar v-if="isSideNavigationOpen" @closeSidebar="closeSidebar" />
  </nav>
</template>

<script>
import axios from "@/axios";

import LisT from "../lisT.vue";
import Sidebar from "./Sidebar.vue";

export default {
  name: "NavBar",
  data() {
    return {
      items: "",
      city: "",
      searchQuery: "",
      searchResults: [],
      garbaClasses: [],
      userPosts: [],
      users: [],
      initialDisplayCountUser: 3,
      showAllUsers: false,
      initialDisplayCountGc: 2,
      showAllGc: false,
      initialDisplayCountPosts: 2,
      showAllPosts: false,

      isSideNavigationOpen: false,

      defaultImage: require("@/assets/defaultPersonImage.jpg"),
    };
  },
  mounted() {
    this.getDa();
  },
  created() {
    this.getDa();
  },
  computed: {
    displayedUsers() {
      // Return a subset of users based on the current state
      return this.showAllUsers
        ? this.users
        : this.users.slice(0, this.initialDisplayCountUser);
    },
    displayedGarbaClass() {
      // Return a subset of users based on the current state
      return this.showAllGc
        ? this.garbaClasses
        : this.garbaClasses.slice(0, this.initialDisplayCountGc);
    },
    displayedPosts() {
      // Return a subset of users based on the current state
      return this.showAllPosts
        ? this.userPosts
        : this.userPosts.slice(0, this.initialDisplayCountPosts);
    },
  },
  methods: {
    getDa() {
      try {
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);
        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;
          // Add more cases for other user types as needed
          default:
            endpoint = "search"; // or 'default-profile' if needed
            break;
        }
        axios.get(endpoint).then((response) => {
          this.items = response.data.data;
          this.city = response.data.data.city;
          // console.log("from nav bar", this.items);
          // alert(this.item);
          if (localStorage.getItem("token") || response.data.Status == 200) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          } else {
            this.$router.push("/login");
          }
          this.handleSearch();
        });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    handleSearch() {
      if (this.searchQuery.trim() !== "") {
        axios
          .get(`serach?search=${this.searchQuery}`)
          .then((response) => {
            // console.log("Response:", response.data);
            this.searchResults = response.data.data;
            this.garbaClasses = this.searchResults.garba_classes;
            this.userPosts = this.searchResults.posts;
            this.users = this.searchResults.users;
            // console.log("serch result = ", this.searchResults);
            // console.log("Garba Class serch result = ", this.garbaClasses);
            // console.log("User Post serch result = ", this.userPosts);
            // console.log("User serch result = ", this.users);
          })
          .catch((error) => {
            console.log("Error:", error.response.data);
          });
      } else {
        // Clear search results if searchQuery is empty
        this.searchResults = [];
      }
    },

    toggleFollow(user) {
      try {
        let followEndpoint = "";
        const userType = this.$store.state.userType;

        switch (userType) {
          case "1":
            followEndpoint =
              user.follows_flag === "1" ||
              user.follows_flag === "2" ||
              user.follows_flag === "3" ||
              user.follows_flag === "4"
                ? "entrant/follows/unfollow"
                : "entrant/follows/follow";
            break;
          case "3":
            followEndpoint =
              user.follows_flag === "1" ||
              user.follows_flag === "2" ||
              user.follows_flag === "3" ||
              user.follows_flag === "4"
                ? "gc/follows/unfollow"
                : "gc/follows/follow";
            break;
          case "2":
            followEndpoint =
              user.follows_flag === "1" ||
              user.follows_flag === "2" ||
              user.follows_flag === "3" ||
              user.follows_flag === "4"
                ? "gj/follows/unfollow"
                : "gj/follows/follow";
            break;
          default:
            break;
        }

        if (followEndpoint) {
          axios
            .post(followEndpoint, { followee_id: user.id })
            .then(() => {
              user.follows_flag = user.follows_flag === "1" ? "0" : "1";
            })
            .catch((error) => {
              console.log("Error:", error.response.data);
            });
        }
      } catch (err) {
        console.log("Error = ", err);
      }
    },

    toggleShowAll() {
      this.showAllUsers = !this.showAllUsers;
    },

    toggleShowAllGc() {
      this.showAllGc = !this.showAllGc;
    },

    toggleShowAllPosts() {
      this.showAllPosts = !this.showAllPosts;
    },

    formatTimestamp(timestamp) {
      const now = new Date();
      const createdDate = new Date(timestamp);
      const timeDifference = now - createdDate;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else {
        return "Just now";
      }
    },

    toggleSideNavigation() {
      // Toggle the side navigation component
      // this.$data.isSideNavigationOpen = !this.$data.isSideNavigationOpen;
      this.isSideNavigationOpen = !this.isSideNavigationOpen;
    },

    closeSidebar() {
      this.isSideNavigationOpen = false;
    },
  },
  components: { LisT, Sidebar },
};
</script>

<style scoped>
.profile-img-div {
  width: 3.2rem !important;
  height: 2.42rem !important;
  background: #f8f9fa;
  cursor: pointer;
}

.profile-img-div:hover {
  background: #d3d4d5;
}
.bg-red {
  background-color: #c90000;
}

.logo {
  height: 30px; /* Adjust the height as needed */
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

@media only screen and (max-width: 577px) {
  .container {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}
</style>
