<template>
  <!-- <HomeHeader /> -->
  <div class="custom-animation-gj">
    <NavBar />
    <div class="container p-0 m-auto w-75">
      <div
        class="row w-100 d-flex justify-content-center m-0 p-0 position-relative custom-row-container home-div"
      >
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <LeftSideContent />

          <RaiseTicket />

          <LeftAdBanner />
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="post w-100 px-2 py-2">
            <div class="post_image d-flex justify-content-between">
              <AddVideo />
            </div>
          </div>

          <!-- <UserPost /> -->
          <!-- <div v-for="(post, index) in posts" :key="post.id" class="postw" ref="postElements" @click="updateCurrentPost(index)"> -->
          <AllPost />

          <div class="Top-Profile w-100 my-2">
            <h6 class="m-0 py-1 px-1">TopProfile</h6>
            <hr />
            <div class="profiles">
              <div class="m-2">
                <img class="profiles_img" src="img/user1.jpg" alt />
                <p class="profiles-name">John Doe</p>
                <p class="profiles-Designation">Graphics Designer</p>
                <button class="follow-btn btn btn-danger">Follow</button>
              </div>

              <div class="m-2">
                <img class="profiles_img" src="img/user1.jpg" alt />
                <p class="profiles-name">Charlie Chango</p>
                <p class="profiles-Designation">Graphics Designer</p>
                <button class="follow-btn btn btn-danger">Follow</button>
              </div>

              <div class="m-2">
                <img class="profiles_img" src="img/user1.jpg" alt />
                <p class="profiles-name">Golden Garba...</p>
                <p class="profiles-Designation">All Types of step...</p>
                <button class="follow-btn btn btn-danger">Follow</button>
              </div>
            </div>
          </div>

          <!-- <infinite-loading @infinite="getPost(), getGC_Post()"></infinite-loading> -->
          <infinite-loading @infinite="getPost()"></infinite-loading>

          <!-- <UserPost /> -->
        </div>

        <div class="col-12 col-sm-12 col-md-2 col-lg-3">
          <RightSideContent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import { ref } from "vue";
// import UserPost from "./post.vue";
// import UserlikeP from "./UserlikeP.vue";
// import HomeHeader from "./Header-Homepage.vue";
// HomeHeader
import AddVideo from "./Add-Video.vue";
// import UsecomT from "./UserComT.vue";/
// import UsersharE from "./UsersharE.vue";
// import UservieW from "./UservieW.vue";
// import addCommT from "./addCommT.vue";
// import commenT from "./commenT.vue";
// import commentList from './CommentList.vue'
// import likeList from "./likeList.vue";
// import lisT from "./lisT.vue";
import NavBar from "./Profile_Screens/NavBar.vue";
// import PassReset from './Pass_Reset_success.vue'
import axios from "@/axios";
// import instance from "@/axios"
import InfiniteLoading from "vue-infinite-loading";
import LeftSideContent from "./LeftSideContent.vue";
import RightSideContent from "./RightSideContent.vue";
import RaiseTicket from "./RaiseTicket.vue";
import AllPost from "./AllPost.vue";
import LeftAdBanner from "./LeftAdBanner.vue";
export default {
  name: "BodySection",
  props: {
    postId: String,
    // postId: String,
    comments_count: String,
    numberOfVideos: {
      type: Number,
      // default: 2, // Set a default value if needed
    },
  },

  data() {
    return {
      item: [],
      posts: [],
      page: [],
      Page: 1,
      lastPage: 1,
      suggestions: [],
      moment: moment,
      liked: false,
      comment: "",
      city: "",
      isVisible: false,
      defaultImage: require("@/assets/profile-no-img.png"),
      modalOpen: false,
    };
  },

  components: {
    NavBar,
    AddVideo,
    // UserlikeP,
    // UsecomT,
    // UsersharE,
    // UservieW,
    // addCommT,
    // // commenT,
    // lisT,
    InfiniteLoading,
    LeftSideContent,
    RightSideContent,
    RaiseTicket,
    AllPost,
    LeftAdBanner,
  },

  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },

  computed: {},

  methods: {
    closePop() {
      let mode = document.getElementById("mode");
      mode.classList.remove("mode-all");
    },

    // like() {
    //   this.liked = !this.liked;
    // },

    // async getDe() {
    //   await axios.get("entrant/my-profile").then((response) => {
    //     if (response.data.user_type !== 2) {
    //       this.item = response.data.data;
    //       this.city = response.data.data.city;
    //       console.log(response.data.data.city);

    //       if (localStorage.getItem("token") || response.data.Status == 200) {
    //         axios.defaults.headers.common["Authorization"] =
    //           " Bearer " + localStorage.getItem("token");
    //       } else {
    //         this.$router.push("/");
    //       }
    //     } else if (response.data.user_type === 3) {
    //       this.getGCDe();
    //     } else {
    //       this.$router.push("/");
    //     }
    //   });

    //   //   // GC user Data
    //   // },
    // },

    // getGCDe() {
    //   axios
    //     .get("gc/my-profile")
    //     .then((response) => {
    //       console.log(response.data.data);
    //       this.item = response.data.data;
    //       console.log("call from gc", this.item);
    //       this.city = response.data.data.city;

    //       if (localStorage.getItem("token") || response.data.Status == 200) {
    //         axios.defaults.headers.common["Authorization"] =
    //           " Bearer " + localStorage.getItem("token");
    //       } else {
    //         this.$router.push("/");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    menu() {
      setTimeout(() => {
        $(".kebab-menu").click(function () {
          $(".dropdown-text").toggle();
        });
      }, 1000);
    },

    rasiTick() {
      let ticket = document.getElementById("ticket");
      ticket.classList.add("ticket-all");
      let tick = document.getElementById("tick");
      tick.classList.add("tick-all");
    },

    raisClose() {
      let ticket = document.getElementById("ticket");
      ticket.classList.remove("ticket-all");
      let tick = document.getElementById("tick");
      tick.classList.remove("tick-all");
    },

    // async getPost() {
    //   await axios
    //     .get(`entrant/posts?per_page=5&page=${this.Page}`, {
    //       headers: {
    //         DeviceID: "1111",
    //         versionName: "1.0.0",
    //         DeviceType: "0",
    //         // responseType:"arraybuffer"
    //       },
    //     })
    //     .then((response) => {
    //       // localStorage.setItem("Post_id", response.data.data[0,2].videos[0].post_id)
    //       this.posts.push(...response.data.data);
    //       this.lastPage = response.last_page;
    //       console.log(this.lastPage);
    //       // console.log(this.Post_id)
    //       if (localStorage.getItem("token")) {
    //         axios.defaults.headers.common["Authorization"] =
    //           " Bearer " + localStorage.getItem("token");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response.data.message);
    //     });
    // },

    // async getPost($state) {
    //   /* eslint-disable no-alert, no-debugger */
    //   // debugger;

    //   const response = await axios.get(
    //     `entrant/posts?per_page=5&page=${this.Page}`
    //   );
    //   if (response.data.user_type !== 2) {
    //     const data = response.data.data;
    //     console.log(data);
    //     if (data.length > 0) {
    //       this.posts = [...this.posts, ...data];
    //       // this.posts = data.map(post => ({ ...post, autoplay: false }));
    //       // this.observePostVisibility();
    //       this.Page++;
    //       if ($state) {
    //         $state.loaded();
    //       }
    //     } else {
    //       this.hasMore = false;
    //       if ($state) {
    //         $state.complete();
    //       }
    //     }
    //   } else if (response.data.user_type === 3) {
    //     this.getGC_Post();
    //   } else {
    //     this.$router.push("/");
    //   }
    // },

    // async getGC_Post($state) {
    //   /* eslint-disable no-alert, no-debugger */
    //   // debugger;
    //   const response = await axios.get(`gc/posts/gj?per_page=10${this.Page}`);

    //   const data = response.data.data;
    //   console.log(data);
    //   if (data.length > 0) {
    //     this.posts = [...this.posts, ...data];
    //     this.Page++;
    //     if ($state) {
    //       $state.loaded();
    //     }
    //   } else {
    //     this.hasMore = false;
    //     if ($state) {
    //       $state.complete();
    //     }
    //   }
    // },

    // suggestion() {
    //   axios.get("suggestions").then((response) => {
    //     console.log(response.data.data);
    //     this.suggestions = response.data.data.users;
    //     console.log(this.suggestion);
    //   });
    // },

    // Send_FollowRequest(id) {
    //   axios
    //     .post("entrant/follows/follow", {
    //       followee_id: id,
    //     })
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       alert(error.response.data.message);
    //     });
    // },

    async getPost($state) {
      try {
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/posts";
            break;
          case "3":
            endpoint = "gc/posts";
            break;
          case "2":
            endpoint = "gj/posts";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(
          `${endpoint}?per_page=5&page=${this.Page}`
        );

        if (response.data.user_type !== 2) {
          const data = response.data.data;
          // console.log(data);
          if (data.length > 0) {
            this.posts = [...this.posts, ...data];
            this.Page++;
            if ($state) {
              $state.loaded();
            }
          } else {
            this.hasMore = false;
            if ($state) {
              $state.complete();
            }
          }
        } else {
          // Handle other cases or redirect to the home page
          this.$router.push("/login");
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
        // Handle the error appropriately
      }
    },

    onPostDeleted(postId) {
      // Update the posts array to remove the deleted post
      this.posts = this.posts.filter((post) => post.id !== postId);
    },

    handleVideoPlay(postId) {
      // Check if the view has already been counted for this post
      const post = this.posts.find((post) => post.id === postId);
      if (post && !post.viewCounted) {
        // Increment the view count when the video is played
        post.views_count++;
        // Set the flag to true to indicate that the view has been counted
        post.viewCounted = true;

        // Update localStorage to reflect the new view count
        const storedViews =
          JSON.parse(localStorage.getItem("post_views")) || {};
        // storedViews[postId] = (storedViews[postId] || 0) + 1;
        // localStorage.setItem('post_views', JSON.stringify(storedViews));

        setTimeout(() => {
          // Increment the view count after the timeout
          post.views_count++;

          // Update localStorage again to reflect the new view count (increment by 1)
          storedViews[postId] = (storedViews[postId] || 0) + 1;
          localStorage.setItem("post_views", JSON.stringify(storedViews));

          // Emit another event to update the view count in UservieW component
          this.$emit("increment-view-count", postId);
        }, 5000);
      }
    },

    incrementViewCount() {
      // const post = this.posts.find((post) => post.id === postId);
      // if (post) {
      //   post.views_count++;
      // }
      // console.log(`Incrementing view count for post ${postId}`);
    },

    getStoredViews(postId) {
      // Retrieve the stored view count from localStorage
      const storedViews = JSON.parse(localStorage.getItem("post_views")) || {};
      return storedViews[postId] || 0;
    },
  },

  mounted() {
    // console.log("working properly");
    // console.log("this is locoal storage token", localStorage.getItem("token"));
    this.getPost();
    // this.getDe();
    // this.getGCDe();
    // this.getGC_Post();
    // this.suggestion();
  },
};
</script>

<style scoped>
.home-div {
  top: 8rem;
}
.modal.show .modal-dialog {
  transform: none;
  margin-top: 6%;
  margin-left: 38%;
}

.modal-footer > * {
  /* margin: calc(var(--bs-modal-footer-gap) * .5); */
  margin-right: 6%;
}

.box {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background-color: #ffff;
}

.header {
  background-color: #c10111;
  height: 10%;
  text-align: center;
}

.profile-pic {
  width: 35%;
  position: relative;
  top: 43px;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 1rem;
  height: 4.5rem;
}

.profile-details {
  margin: 53px 0;
  padding: 10px;
}

.post {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  visibility: visible;
}

.post_image img {
  width: 13%;
  padding: 0 6px;
  border-radius: 3rem;
  height: 3rem;
}

.video {
  float: right;
  margin: -1rem -3rem 5px;
}

.video img {
  margin: 4px 11px 6px;
  cursor: pointer;
}

.Top-Profile {
  box-shadow: 0 0 8px #d4d4d4;
  border-radius: 4px;
  background-color: #ffffff;
}

.profiles {
  display: flex;
  padding: 10px 10px 10px;
  text-align: center;
}

.profiles_img {
  width: 72%;
  padding: 5px;
}

.profiles div {
  border: 1px solid #d4d4d4;
  align-items: center;
  padding: 0px 0px 10px;
}

.profiles-Designation {
  font-size: 13px;
  padding: 1px;
  color: #d4d4d4;
}

.follow-btn {
  width: 90%;
  border-radius: 15px;
  padding: 1px;
}

.suggestion-box {
  background-color: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  box-shadow: 0 0 7px #d4d4d4;
}

.suggestion-box p {
  font-size: 10px;
  margin: 5px 0px 7px 26px;
}

.suggestion-box span {
  font-size: 15px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 9px 6px;
}

.user-1 div:nth-child(2n + 1) {
  border-right: 3px solid #828587;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:nth-child(2n + 2) {
  border-right: none;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:last-child {
  border-bottom: none;
}

.suggestion_img {
  width: 60%;
  padding: 0.3rem;
}

.suggestion-btn {
  margin: 11px 2px 10px;
  padding: 0px 7px 2px;
  width: 14vh;
}

.box-2 {
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background: white;
  background-color: #ffff;
  padding: 10px 10px 8px;
}

.right-side {
  float: right;
  padding: 6px;
}

.left-side {
  padding: 6px;
}

.images {
  height: 78px;
  margin: 17px 6px 4px;
}

.Profile {
  border: 1px solid black;
  height: 70px;
  width: 16%;
}

.location {
  font-size: 10px;
}

.Profile-name {
  font-size: 10px;
  height: 73px;
  width: 85%;
  margin: 1px 7px 5px;
}

.main-content {
  margin: -198px 33px 10px;
  padding: 105px 95px 15px;
}

.post-block {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 30px #d4d4d4;
}

.post-block img.author-img {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  margin-right: 12px;
}

.post-block img.author-img.author-img--small {
  width: 11%;
  padding: 1%;
}

.post-block__content img,
.post-block__content video,
.post-block__content audio {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
}

.kebab-menu {
  cursor: pointer;
}

.dropdown .dropdown-text {
  display: none;
  width: 120px;
  background-color: #ffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* opacity: 0; */
  top: 100%;
  right: 28%;
  margin-left: -60px;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 7px #d4d4d4;
  cursor: pointer;
}

.post-block .comment-view-box__comment {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px;
}

input.form-control {
  border-radius: 10px;
}

.comment-btn {
  border: none;
  background-color: #ffffff;
}

.suggestion-title {
  height: 42px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 165px;
  margin: 7px -13px 10px;
  padding: 3px 5px 4px 5px;
}

.user_action {
  padding: 0px 0px 0px 56px;
  margin: -3px 5px 10px;
}

.user_action span {
  margin: -3px 10px 10px;
  padding: 0px 20px 0px;
}

.about-section {
  margin-left: -2%;
}

.fullname span::before {
  content: "";
  position: absolute;
  top: 18%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.fullname input:focus ~ label,
.fullname input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.fullname input:focus ~ span::before,
.fullname input:valid ~ span::before {
  width: 84%;
}

.email span::before {
  content: "";
  position: absolute;
  top: 25.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.email input:focus ~ label,
.email input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.email input:focus ~ span::before,
.email input:valid ~ span::before {
  width: 84%;
}

.contactUs span::before {
  content: "";
  position: absolute;
  top: 33%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.contactUs input:focus ~ label,
.contactUs input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.contactUs input:focus ~ span::before,
.contactUs input:valid ~ span::before {
  width: 84%;
}

.Address span::before {
  content: "";
  position: absolute;
  top: 40.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.Address input:focus ~ label,
.Address input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.Address input:focus ~ span::before,
.Address input:valid ~ span::before {
  width: 84%;
}

.country span::before {
  content: "";
  position: absolute;
  top: 47.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.country input:focus ~ label,
.country input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.country input:focus ~ span::before,
.country input:valid ~ span::before {
  width: 37.5%;
}

.state span::before {
  content: "";
  position: absolute;
  top: 47.3%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.state input:focus ~ label,
.state input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.state input:focus ~ span::before,
.state input:valid ~ span::before {
  width: 37.5%;
}

.city span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.city input:focus ~ label,
.city input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.city input:focus ~ span::before,
.city input:valid ~ span::before {
  width: 37.5%;
}

.pincode span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.pincode input:focus ~ label,
.pincode input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.pincode input:focus ~ span::before,
.pincode input:valid ~ span::before {
  width: 37.5%;
}

.upload span::before {
  content: "";
  position: absolute;
  top: 66.8%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.upload input:focus ~ label,
.upload input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.upload input:focus ~ span::before,
.upload input:valid ~ span::before {
  width: 84%;
}

.dropdown-toggle::after {
  display: none;
}

.post_video {
  width: 100%;
  object-fit: fill;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .profile-details h2 {
    font-size: 1em !important;
  }

  .about-of-profile-details {
    font-size: 0.9em;
  }

  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .home-div {
    top: 5.5rem;
  }

  .col-lg-6 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
</style>
