<template>
  <div class="custom-animation-gj">
    <NavBar />

    <div class="container" style="margin-top: 6rem">
      <form class="">
        <div class="row mt-3">
          <div class="col-sm-12 col-md-4">
            <div class="garba-class-dtail shadow rounded-3">
              <div class="garba-class-heading p-3">
                <h2>Garba class</h2>
              </div>

              <div class="w-100" style="border-bottom: 2px solid black"></div>

              <div class="garba-class-form p-3">
                <div
                  class="garba-class-image d-flex justify-content-center align-items-center my-5 p-5"
                  style="border: dotted; border-color: red"
                >
                  <div v-if="gcDetailImagePreview" class="position-relative">
                    <img
                      :src="gcDetailImagePreview"
                      alt="Preview"
                      style="max-width: 100%; max-height: 100%"
                    />
                    <button
                      type="button"
                      class="close position-absolute top-0 end-0"
                      aria-label="Close"
                      @click="gcDetailRemoveImage"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div id="garba-class-logo-input" v-else>
                    <input
                      type="file"
                      name="image"
                      ref="gcInputImage"
                      accept="image/*"
                      id="image"
                      class="form-control mb-3"
                      @change="handleFileSelectGarbaClassLogo"
                      style="display: none"
                    />
                    <img
                      src="./assets/video_icon.svg"
                      class="cursor-pointer"
                      @click="gcOpenFileInput('image')"
                    />
                    <label
                      @click="gcOpenFileInput('image')"
                      style="cursor: pointer"
                      >Garba class Logo</label
                    >
                  </div>
                </div>

                <div class="garba-class-name">
                  <label for="gcName" class="form-label text-danger"
                    >Class Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="gcData.gcName"
                    id="form-gcName"
                  />
                </div>

                <div class="garba-class-Fees mt-2">
                  <label for="gcFees" class="form-label text-danger"
                    >Fees</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="gcData.gcFees"
                    id="form-gcFees"
                  />
                </div>

                <div class="garba-class-address mt-2">
                  <label for="gcAddress" class="form-label text-danger"
                    >Address</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="gcData.gcAddress"
                    id="form-gcAddress"
                  />
                </div>

                <div class="garba-class-sales-manager-code mt-2">
                  <label for="gcSalesMangCode" class="form-label text-danger"
                    >Sales Manager Code</label
                  >
                  <!-- <input type="text" v-model="garbaClassMobileNo" /> -->
                  <input
                    type="text"
                    class="form-control"
                    name="gcSalesMangCode"
                    maxlength="10"
                    pattern="\d{10}"
                    required
                    placeholder="7084XXXXXX"
                    id="form-gcSalesMangCode"
                    v-model="gcData.gcSalesMangCode"
                  />
                </div>

                <div class="garba-class-mobile-no mt-2">
                  <label for="gcMobileNo" class="form-label text-danger"
                    >Mobile No.</label
                  >
                  <!-- <input type="text" v-model="garbaClassMobileNo" /> -->
                  <input
                    type="text"
                    class="form-control"
                    name="mobileNo"
                    maxlength="10"
                    pattern="\d{10}"
                    required
                    placeholder="+91 7084XXXXXX"
                    id="form-gcMobileNo"
                    v-model="gcData.gcMobileNo"
                  />
                </div>

                <div name="garba-class-year form1 mt-2">
                  <label for="gcYears" class="form-label text-danger"
                    >Year{{ gcData.gcYears }}</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="pincode"
                    maxlength="4"
                    id="form-gcYears"
                    pattern="\d{4}"
                    required
                    v-model="gcData.gcYears"
                  />
                </div>

                <div class="row mt-2">
                  <div class="col">
                    <label for="gcCountry" class="form-label text-danger"
                      >Country</label
                    >
                    <select
                      v-model="gcData.gcCountry"
                      @change="loadStates"
                      class="form-control"
                      id="form-gcCountry"
                      required
                    >
                      <option disabled value="">Select Country</option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.id"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="gcState" class="form-label text-danger"
                      >State</label
                    >
                    <select
                      v-model="gcData.gcState"
                      @change="loadCities"
                      class="form-control"
                      id="form-gcState"
                      required
                    >
                      <option disabled value="">Select State</option>
                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col">
                    <label for="gcCity" class="form-label text-danger"
                      >City</label
                    >
                    <select
                      v-model="gcData.gcCity"
                      class="form-control"
                      id="form-gcCity"
                      required
                    >
                      <option disabled value="">Select City</option>
                      <option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="gcCityPinCode" class="form-label text-danger"
                      >City Pin-Code</label
                    >
                    <input
                      v-model="gcData.gcCityPinCode"
                      type="text"
                      class="form-control"
                      id="form-gcCityPinCode"
                      required
                    />
                  </div>
                </div>

                <div class="trailer-video mt-2" id="trailer-video">
                  <label class="form-label text-danger">Trailer video</label>
                  <input
                    type="file"
                    ref="trailVideoFileInput"
                    accept="video/*"
                    required
                    class="form-control"
                    @change="handleFileSelectGarbaClassLogo"
                  />
                  <!-- <video
                  v-if="trailVideoPreview"
                  :src="trailVideoPreview"
                  controls
                  style="width: 100%; padding: 0.1rem"
                ></video> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="garba-class-dtail">
              <div class="garba-class-document rounded-3 shadow">
                <div class="garba-class-form">
                  <h2 class="p-3">Documents</h2>

                  <div
                    class="w-100"
                    style="border-bottom: 2px solid black"
                  ></div>

                  <div class="file-upload mt-3 p-4">
                    <h6 class="mb-3">
                      Select Pdf or Image Of Your Adhar-Card & Id-Proff of
                      current address
                    </h6>
                    <input
                      type="file"
                      id="documentFile"
                      name="documentFile"
                      accept="image/*, application/pdf"
                      class="form-control"
                      @change="handleFileSelectDocuments"
                      multiple
                    />
                    <p v-if="selectedFiles.length > 0" class="mt-2">
                      Selected Files:
                    </p>
                    <ul class="list-group list-group-flush">
                      <li
                        v-for="(file, index) in selectedFiles"
                        :key="index"
                        class="list-group-item"
                      >
                        <div
                          class="d-flex justify-content-between align-items-start"
                        >
                          <span>
                            {{ file.name }}
                          </span>
                          <button
                            @click="removeFile(index)"
                            type="button"
                            class="btn-close btn-close-black"
                            aria-label="Close"
                          ></button>
                        </div>
                        <hr v-if="selectedFiles.length > 0" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="garba-class-bank-details rounded-3 p-3 shadow mt-4">
                <h2 class="fs-5">Bank Details</h2>
                <hr />

                <div class="bank-details">
                  <div class="row mb-3">
                    <div class="col">
                      <label
                        for="gcBankBranchName"
                        class="form-label text-danger"
                        >Bank Name</label
                      >
                      <input
                        v-model="bankData.gcBankBranchName"
                        type="text"
                        class="form-control"
                        id="form-gcBankBranchName"
                        name="form-gcBankBranchName"
                        required
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col">
                      <label
                        for="gcBankAccountHolderName"
                        class="form-label text-danger"
                        >Account Holder Name</label
                      >
                      <input
                        v-model="bankData.gcBankAccountHolderName"
                        type="text"
                        class="form-control"
                        id="form-gcBankAccountHolderName"
                        required
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col">
                      <label
                        for="gcBankAccountNumber"
                        class="form-label text-danger"
                        >Account Number</label
                      >
                      <input
                        v-model="bankData.gcBankAccountNumber"
                        type="number"
                        min="1"
                        max="12"
                        class="form-control"
                        id="form-gcBankAccountNumber"
                        name="form-gcBankAccountNumber"
                        required
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col">
                      <label
                        for="gcBankBranchAddress"
                        class="form-label text-danger"
                        >Bank Address</label
                      >
                      <input
                        v-model="bankData.gcBankBranchAddress"
                        type="text"
                        class="form-control"
                        id="form-gcBankBranchAddress"
                        name="form-gcBankBranchAddress"
                        required
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col">
                      <label for="gcBankIfcCode" class="form-label text-danger"
                        >IFC Code</label
                      >
                      <input
                        v-model="bankData.gcBankIfcCode"
                        type="text"
                        class="form-control"
                        id="form-gcBankIfcCode"
                        name="form-gcBankIfcCode"
                        required
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <label
                        class="form-label text-danger"
                        for="gcUploadBankProof"
                        >upload Bank Proof</label
                      >
                      <input
                        @change="handleFileselectBankIdProof"
                        type="file"
                        name="form-gcUploadBankProof"
                        class="form-control"
                        id="form-gcUploadBankProof"
                        ref="gcUploadBankProof"
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="garba-class-dtail shadow rounded-3">
              <div class="garba-class-heading p-3">
                <h2>Upload Awards</h2>
              </div>

              <div class="w-100" style="border-bottom: 2px solid black"></div>

              <div class="garba-class-form p-3">
                <div>
                  <span class="fs-5 text-danger"
                    >Select Garba Class Awards</span
                  >
                  <div
                    class="garba-class-image d-flex justify-content-center align-items-center my-5 p-5"
                    style="border: dotted; border-color: red"
                  >
                    <div v-if="awardImagePreview" class="position-relative">
                      <img
                        :src="awardImagePreview"
                        alt="Preview"
                        style="max-width: 100%; max-height: 100%"
                      />
                      <button
                        type="button"
                        class="close position-absolute top-0 end-0"
                        aria-label="Close"
                        @click="removeAwardImage"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div id="garba-class-awards-details" v-else>
                      <input
                        type="file"
                        name="image"
                        ref="awardInputImage"
                        accept="image/*"
                        id="image"
                        class="form-control mb-3"
                        @change="handleFileSelectGarbaClassAwards"
                        style="display: none"
                      />
                      <img
                        src="./assets/video_icon.svg"
                        class="cursor-pointer"
                        @click="awardOpenFileInput('image')"
                      />
                      <label
                        @click="awardOpenFileInput('image')"
                        style="cursor: pointer"
                        >Garba class Awards</label
                      >
                    </div>
                  </div>
                </div>

                <div class="garba-class-form">
                  <div class="garba-class-awards mt-2">
                    <label for="gcAwardName" class="form-label text-danger"
                      >Award Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="gcAwardData.gcAwardName"
                      id="form-gcAwardName"
                    />
                  </div>

                  <div class="garba-class-year form1 mt-2">
                    <label for="gcAwardYears" class="form-label text-danger"
                      >Year</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="pincode"
                      maxlength="4"
                      id="form-gcAwardYears"
                      pattern="\d{4}"
                      required
                      v-model="gcAwardData.gcAwardYears"
                    />
                  </div>

                  <div class="trailer-video mt-2" id="trailer-video">
                    <div class="mb-3">
                      <label
                        for="writeAboutAwards"
                        class="form-label text-danger"
                        >Write About Awards</label
                      >
                      <textarea
                        class="form-control"
                        id="form-writeAboutAwards"
                        rows="3"
                        v-model="gcAwardData.writeAboutAwards"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-danger mt-2"
            id="trailer-upload-button"
            @click="createGC"
          >
            Upload
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import NavBar from "./NavBar.vue";

// import {ref} from 'vue'
export default {
  props: {
    userId: String,
  },

  data() {
    return {
      countries: [],
      states: [],
      cities: [],

      gcDetailImagePreview: "",
      trailVideoPreview: "",
      gcData: {
        gcDetailLogoImage: null,
        gcName: "",
        gcFees: "",
        gcAddress: "",
        gcSalesMangCode: "",
        gcMobileNo: "",
        gcYears: "",
        gcCountry: "101",
        gcState: "",
        gcCity: "",
        gcCityPinCode: "",
        trailVideo: "",
      },

      bankData: {
        gcBankBranchName: "",
        gcBankAccountHolderName: "",
        gcBankAccountNumber: "",
        gcBankBranchAddress: "",
        gcBankIfcCode: "",
        gcUploadBankProof: null,
      },

      selectedFiles: [],
      //   selectedFileName: "",

      gcAwardData: {
        gcAwardName: "",
        gcAwardYears: "",
        writeAboutAwards: "",
      },
      awardImagePreview: "",
    };
  },

  mounted() {
    // Load countries on component mount
    this.loadCountries();
  },

  watch: {
    "gcData.gcCountry": "loadStates",
    "gcData.gcState": "loadCities",
  },

  components: {
    NavBar,
  },

  methods: {
    gcOpenFileInput(type) {
      if (type === "image" && this.$refs.gcInputImage) {
        this.$refs.gcInputImage.click();
      }
    },

    handleFileSelectGarbaClassLogo(event) {
      const file = event.target.files[0];

      if (file.type.startsWith("image/")) {
        // Handle image file
        this.gcData.gcDetailLogoImage = file;
        this.gcDetailImagePreview = URL.createObjectURL(file);
        // this.gcDetailImagePreview = file;
        // console.log("image = ", this.gcData.gcDetailLogoImage);
        // console.log("image type = ", this.gcData.gcDetailLogoImage.type);
      } else if (file.type.startsWith("video/")) {
        // Handle video file
        this.gcData.trailVideo = file;
        this.trailVideoPreview = URL.createObjectURL(file);
        // this.trailVideoPreview = file;
      }
    },

    gcDetailRemoveImage() {
      this.gcDetailImagePreview = ""; // Use consistent variable name
      if (this.$refs.gcInputImage) {
        this.$refs.gcInputImage.value = null;
      }
    },

    async loadCountries() {
      try {
        let allCountries = [];
        let shouldContinue = true;
        let page = 1;

        while (shouldContinue) {
          const response = await axios.get(`countries?page=${page}`);
          // console.log("Response:", response); // Log the response

          const countries = response.data && response.data.data;

          if (!countries || countries.length === 0) {
            // No more countries to fetch, break the loop
            shouldContinue = false;
          } else {
            allCountries = [...allCountries, ...countries];
            page++;
          }
        }

        this.countries = allCountries;
        // console.log("All Countries:", this.countries); // Log the final list of countries
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    },

    async loadStates() {
      try {
        const response = await axios.get(
          `states_of_country?country_id=${this.gcData.gcCountry}`
        );
        this.states = response.data.data; // Update states array
        // console.log("states = ", this.states);
        this.gcData.gcState = ""; // Reset state value
        this.gcData.gcCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading states:", error);
      }
    },

    async loadCities() {
      try {
        const response = await axios.get(
          `cities_of_state?state_id=${this.gcData.gcState}`
        );
        this.cities = response.data.data; // Update cities array
        // console.log("states = ", this.cities);
        this.gcData.gcCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading cities:", error);
      }
    },

    handleFileSelectDocuments(event) {
      const files = event.target.files;

      if (files && files.length > 0) {
        // Handle the files (validate, store, etc.)
        // console.log("Selected Files:", files);

        // Clear previous selection
        // this.selectedFiles = [];
        // this.selectedFileName = files.name;

        // Add each selected file to the array
        for (let i = 0; i < files.length; i++) {
          this.selectedFiles.push(files[i]);
        }
      }
    },

    handleFileselectBankIdProof(event) {
      const file = event.target.files[0];

      if (file.type.startsWith("image/")) {
        // Handle image file
        // this.image = file;
        // this.gcUploadBankProof = URL.createObjectURL(file);
        this.gcUploadBankProof = file;
        // console.log("gcUploadBankProof = ", this.gcUploadBankProof);
        // console.log("gcUploadBankProof type = ", this.gcUploadBankProof.type);
      }
    },

    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },

    awardOpenFileInput(type) {
      if (type === "image" && this.$refs.awardInputImage) {
        this.$refs.awardInputImage.click();
      }
    },

    handleFileSelectGarbaClassAwards(event) {
      const file = event.target.files[0];

      if (file.type.startsWith("image/")) {
        // Handle image file
        this.image = file;
        this.awardImagePreview = URL.createObjectURL(file);
        // console.log("image = ", this.image);
        // console.log("image type = ", this.image.type);
      }
    },

    removeAwardImage() {
      this.awardImagePreview = ""; // Use consistent variable name
      if (this.$refs.inputImage) {
        this.$refs.inputImage.value = null;
      }
    },

    createGC() {
      try {
        const form = new FormData();
        form.append("garba_class_logo", this.gcData.gcDetailLogoImage);
        form.append("garba_class_name", this.gcData.gcName);
        form.append("address", this.gcData.gcAddress);
        form.append("sales_manager_code", this.gcData.gcSalesMangCode);
        form.append("garba_class_mobile", this.gcData.gcMobileNo);
        form.append("garba_class_year", this.gcData.gcYears);
        form.append("garba_class_fees", this.gcData.gcFees);
        form.append("trailer_video", this.gcData.trailVideo);
        form.append("trailer_video_preview", this.gcData.gcDetailLogoImage);
        form.append("country_id", this.gcData.gcCountry);
        form.append("state_id", this.gcData.gcState);
        form.append("city_id", this.gcData.gcCity);
        form.append("pin_code", this.gcData.gcCityPinCode);

        // here if add desctipetion then chnage
        form.append(
          "garba_class_description",
          this.gcAwardData.writeAboutAwards
        );

        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/garba-classes";
            break;
          case "3":
            endpoint = "gc/garba-classes";
            break;
          case "2":
            endpoint = "gj/garba-classes";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        axios
          .post(endpoint, form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.Gc_id = response.data.data.id;
            // console.log("garbaclass id", this.Gc_id);
            // console.log(response.data.data);

            // Reset the form after successful upload
            this.resetForm();
          })
          .catch((error) => {
            console.log(error.response.data.message);
            // alert(error.response.data.message);
          });
      } catch (err) {
        console.log("Error of creating gc : ", err);
      }
    },

    resetForm() {
      this.gcDetailImagePreview = "";
      this.trailVideoPreview = "";

      // Reset form data
      this.gcData = {
        gcDetailLogoImage: null,
        gcName: "",
        gcFees: "",
        gcAddress: "",
        gcSalesMangCode: "",
        gcMobileNo: "",
        gcYears: "",
        gcCountry: "101",
        gcState: "",
        gcCity: "",
        gcCityPinCode: "",
        trailVideo: "",
      };

      this.bankData = {
        gcBankBranchName: "",
        gcBankAccountHolderName: "",
        gcBankAccountNumber: "",
        gcBankBranchAddress: "",
        gcBankIfcCode: "",
        gcUploadBankProof: null,
      };

      this.selectedFiles = [];

      this.gcAwardData = {
        gcAwardName: "",
        gcAwardYears: "",
        writeAboutAwards: "",
      };

      this.awardImagePreview = "";
    },
  },
};
</script>

<style scoped>
@media (max-width: 1199px) {
  .container {
    margin-top: 7rem !important;
  }
}
</style>
