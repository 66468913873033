<template>
  <div class="animate__animated animate__fadeIn">
    <DummyNavbar />
    <div class="container p-0 m-auto w-75">
      <div
        class="row w-100 d-flex justify-content-center m-0 p-0 position-relative custom-row-container row-div"
      >
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <DummyLeftSide />

          <LeftAdBanner />
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="post w-100 px-2 py-2">
            <div class="post_image d-flex justify-content-between">
              <AddVideo />
            </div>
          </div>

          <!-- <UserPost /> -->
          <DummyCenterPostVue />

          <div class="Top-Profile w-100 my-2">
            <h6 class="m-0 py-1 px-1">TopProfile</h6>
            <hr />
            <div class="profiles">
              <div class="m-2">
                <img class="profiles_img" src="img/user1.jpg" alt />
                <p class="profiles-name">John Doe</p>
                <p class="profiles-Designation">Graphics Designer</p>
                <button
                  class="follow-btn btn btn-danger"
                  @click="this.$router.push({ name: 'Login' })"
                >
                  Follow
                </button>
              </div>

              <div class="m-2">
                <img class="profiles_img" src="img/user1.jpg" alt />
                <p class="profiles-name">Charlie Chango</p>
                <p class="profiles-Designation">Graphics Designer</p>
                <button
                  class="follow-btn btn btn-danger"
                  @click="this.$router.push({ name: 'Login' })"
                >
                  Follow
                </button>
              </div>

              <div class="m-2">
                <img class="profiles_img" src="img/user1.jpg" alt />
                <p class="profiles-name">Golden Garba...</p>
                <p class="profiles-Designation">All Types of step...</p>
                <button
                  class="follow-btn btn btn-danger"
                  @click="this.$router.push({ name: 'Login' })"
                >
                  Follow
                </button>
              </div>
            </div>
          </div>

          <!-- <infinite-loading @infinite="getPost(), getGC_Post()"></infinite-loading> -->
          <infinite-loading @infinite="true"></infinite-loading>

          <!-- <UserPost /> -->
        </div>

        <div class="col-12 col-sm-12 col-md-2 col-lg-3">
          <DummyRightSide />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import { ref } from "vue";
// HomeHeader
import AddVideo from "./Add-Video.vue";
// import axios from "@/axios";
import InfiniteLoading from "vue-infinite-loading";
import DummyLeftSide from "./DummyLeftSide.vue";
import DummyRightSide from "./DummyRightSide.vue";
import DummyNavbar from "./DummyNavbar.vue";
// import DummyAllPostDetail from "./DummyAllPostDetail.vue";
import DummyCenterPostVue from "./DummyCenterPost.vue";
import LeftAdBanner from "./LeftAdBanner.vue";
export default {
  name: "BodySection",
  props: {},

  data() {
    return {
      moment: moment,
      defaultImage: require("@/assets/profile-no-img.png"),
      modalOpen: false,
    };
  },

  components: {
    AddVideo,
    InfiniteLoading,
    DummyLeftSide,
    DummyRightSide,
    DummyNavbar,
    // DummyAllPostDetail,
    DummyCenterPostVue,
    LeftAdBanner,
  },

  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },

  computed: {},

  methods: {
    menu() {
      setTimeout(() => {
        $(".kebab-menu").click(function () {
          $(".dropdown-text").toggle();
        });
      }, 1000);
    },
  },

  mounted() {},
};
</script>

<style scoped>
.row-div {
  margin-top: 8.6rem !important;
}
.modal.show .modal-dialog {
  transform: none;
  margin-top: 6%;
  margin-left: 38%;
}

.modal-footer > * {
  /* margin: calc(var(--bs-modal-footer-gap) * .5); */
  margin-right: 6%;
}

.box {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background-color: #ffff;
}

.header {
  background-color: #c10111;
  height: 10%;
  text-align: center;
}

.profile-pic {
  width: 35%;
  position: relative;
  top: 43px;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 1rem;
  height: 4.5rem;
}

.profile-details {
  margin: 53px 0;
  padding: 10px;
}

.post {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  visibility: visible;
}

.post_image img {
  width: 13%;
  padding: 0 6px;
  border-radius: 3rem;
  height: 3rem;
}

.video {
  float: right;
  margin: -1rem -3rem 5px;
}

.video img {
  margin: 4px 11px 6px;
  cursor: pointer;
}

.Top-Profile {
  box-shadow: 0 0 8px #d4d4d4;
  border-radius: 4px;
  background-color: #ffffff;
}

.profiles {
  display: flex;
  padding: 10px 10px 10px;
  text-align: center;
}

.profiles_img {
  width: 72%;
  padding: 5px;
}

.profiles div {
  border: 1px solid #d4d4d4;
  align-items: center;
  padding: 0px 0px 10px;
}

.profiles-Designation {
  font-size: 13px;
  padding: 1px;
  color: #d4d4d4;
}

.follow-btn {
  width: 90%;
  border-radius: 15px;
  padding: 1px;
}

.suggestion-box {
  background-color: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  box-shadow: 0 0 7px #d4d4d4;
}

.suggestion-box p {
  font-size: 10px;
  margin: 5px 0px 7px 26px;
}

.suggestion-box span {
  font-size: 15px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 9px 6px;
}

.user-1 div:nth-child(2n + 1) {
  border-right: 3px solid #828587;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:nth-child(2n + 2) {
  border-right: none;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:last-child {
  border-bottom: none;
}

.suggestion_img {
  width: 60%;
  padding: 0.3rem;
}

.suggestion-btn {
  margin: 11px 2px 10px;
  padding: 0px 7px 2px;
  width: 14vh;
}

.box-2 {
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background: white;
  background-color: #ffff;
  padding: 10px 10px 8px;
}

.right-side {
  float: right;
  padding: 6px;
}

.left-side {
  padding: 6px;
}

.images {
  height: 78px;
  margin: 17px 6px 4px;
}

.Profile {
  border: 1px solid black;
  height: 70px;
  width: 16%;
}

.location {
  font-size: 10px;
}

.Profile-name {
  font-size: 10px;
  height: 73px;
  width: 85%;
  margin: 1px 7px 5px;
}

.main-content {
  margin: -198px 33px 10px;
  padding: 105px 95px 15px;
}

.post-block {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 30px #d4d4d4;
}

.post-block img.author-img {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  margin-right: 12px;
}

.post-block img.author-img.author-img--small {
  width: 11%;
  padding: 1%;
}

.post-block__content img,
.post-block__content video,
.post-block__content audio {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
}

.kebab-menu {
  cursor: pointer;
}

.dropdown .dropdown-text {
  display: none;
  width: 120px;
  background-color: #ffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* opacity: 0; */
  top: 100%;
  right: 28%;
  margin-left: -60px;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 7px #d4d4d4;
  cursor: pointer;
}

.post-block .comment-view-box__comment {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px;
}

input.form-control {
  border-radius: 10px;
}

.comment-btn {
  border: none;
  background-color: #ffffff;
}

.suggestion-title {
  height: 42px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 165px;
  margin: 7px -13px 10px;
  padding: 3px 5px 4px 5px;
}

.user_action {
  padding: 0px 0px 0px 56px;
  margin: -3px 5px 10px;
}

.user_action span {
  margin: -3px 10px 10px;
  padding: 0px 20px 0px;
}

.about-section {
  margin-left: -2%;
}

.fullname span::before {
  content: "";
  position: absolute;
  top: 18%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.fullname input:focus ~ label,
.fullname input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.fullname input:focus ~ span::before,
.fullname input:valid ~ span::before {
  width: 84%;
}

.email span::before {
  content: "";
  position: absolute;
  top: 25.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.email input:focus ~ label,
.email input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.email input:focus ~ span::before,
.email input:valid ~ span::before {
  width: 84%;
}

.contactUs span::before {
  content: "";
  position: absolute;
  top: 33%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.contactUs input:focus ~ label,
.contactUs input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.contactUs input:focus ~ span::before,
.contactUs input:valid ~ span::before {
  width: 84%;
}

.Address span::before {
  content: "";
  position: absolute;
  top: 40.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.Address input:focus ~ label,
.Address input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.Address input:focus ~ span::before,
.Address input:valid ~ span::before {
  width: 84%;
}

.country span::before {
  content: "";
  position: absolute;
  top: 47.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.country input:focus ~ label,
.country input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.country input:focus ~ span::before,
.country input:valid ~ span::before {
  width: 37.5%;
}

.state span::before {
  content: "";
  position: absolute;
  top: 47.3%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.state input:focus ~ label,
.state input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.state input:focus ~ span::before,
.state input:valid ~ span::before {
  width: 37.5%;
}

.city span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.city input:focus ~ label,
.city input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.city input:focus ~ span::before,
.city input:valid ~ span::before {
  width: 37.5%;
}

.pincode span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.pincode input:focus ~ label,
.pincode input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.pincode input:focus ~ span::before,
.pincode input:valid ~ span::before {
  width: 37.5%;
}

.upload span::before {
  content: "";
  position: absolute;
  top: 66.8%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.upload input:focus ~ label,
.upload input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.upload input:focus ~ span::before,
.upload input:valid ~ span::before {
  width: 84%;
}

.dropdown-toggle::after {
  display: none;
}

.post_video {
  width: 100%;
  object-fit: fill;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .profile-details h2 {
    font-size: 1em !important;
  }

  .about-of-profile-details {
    font-size: 0.9em;
  }

  .custom-responsive-img {
    overflow: scroll;
  }

  .row-div {
    margin-top: 8rem !important;
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }

  .row-div {
    margin-top: 8rem !important;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
  .row-div {
    margin-top: 9rem !important;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .row-div {
    margin-top: 2rem !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
  .row-div {
    margin-top: 2rem !important;
  }
}
</style>
