<template>
  <div class="custom-animation-gj">
    <NavBar />

    <div class="container" style="margin: 5rem auto">
      <div class="row">
        <div
          class="col-md-9 my-3 w-100 rounded-3 p-3"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <span>Navi Navratri - 2021</span>
        </div>
        <!-- left side div -->
        <div
          class="col-md-6 mb-5 rounded-3 p-3"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <div class="img-container">
            <img
              src="../assets/994e09f0285161507794fbe5b3a1c59f.png"
              alt=""
              class="w-100"
              style="height: 15rem"
            />
          </div>
          <div class="left-container">
            <div class="d-flex justify-content-between align-items-cenetr my-3">
              <div class="event-left-side">
                <span class="fs-5">navli navratri - 2021</span>
              </div>
              <div class="event-right-side">
                <span>posted on 21.09.2021</span>
              </div>
            </div>
            <p class="description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur a laudantium consequuntur adipisci, totam sed nulla
              sint soluta repellendus non aperiam voluptatum saepe eum incidunt,
              impedit voluptates minima accusamus. Error!
            </p>
          </div>
        </div>

        <!-- right side div -->
        <div
          class="col-md-6 mb-5 rounded-3 p-3"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <div class="container">
            <!-- Your form inputs go here -->
            <div>
              <h4 class="text-danger">Event Name</h4>
              <p>Navli Navratri - 2021</p>
            </div>

            <div>
              <h4 class="text-danger">Organizer Name</h4>
              <p>Navli Navratri - 2021</p>
            </div>

            <div>
              <h4 class="text-danger">Event Description</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
                dignissimos veniam, sapiente consequatur amet aut?
              </p>
            </div>

            <div>
              <h4 class="text-danger">Pass Rate</h4>
              <p>1000/-</p>
            </div>

            <div>
              <h4 class="text-danger">Event Address</h4>
              <p>
                Rangoli Party Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Totam, laudantium.
              </p>
            </div>

            <div>
              <div class="row">
                <div class="col">
                  <h4 class="text-danger">Country</h4>
                  <p>India</p>
                </div>
                <div class="col">
                  <h4 class="text-danger">Country</h4>
                  <p>Lucknow</p>
                </div>
              </div>
            </div>

            <div>
              <div class="row">
                <div class="col">
                  <h4 class="text-danger">Start Date</h4>
                  <p>07th Oct, 2021</p>
                </div>
                <div class="col">
                  <h4 class="text-danger">End Date</h4>
                  <p>15th Oct, 2021</p>
                </div>
              </div>
            </div>

            <div>
              <h4 class="text-danger">Organizing Since How Many Years?</h4>
              <p>3</p>
            </div>

            <div>
              <div class="row">
                <div class="col">
                  <h4 class="text-danger">Last Year Attendance</h4>
                  <p>13000</p>
                </div>
                <div class="col">
                  <h4 class="text-danger">Capacity (Number of Entry)</h4>
                  <p>15000</p>
                </div>
              </div>
            </div>

            <div>
              <button class="btn btn-danger w-100">Book</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Add spacing between left and right content -->
      <div class="row my-4"></div>
    </div>
  </div>
</template>

<script>
import NavBar from "./Profile_Screens/NavBar.vue";

/* eslint-disable */
export default {
  name: "viewevent",
  components: { NavBar },
};
</script>

<style scoped>
/* Your global styles go here */

/* You may not need specific styles for each div-1 and div-2, as Bootstrap provides a responsive grid system */
</style>
