<template>
  <div
    class="container d-flex vh-100 justify-content-center align-items-center"
  >
    <div class="main d-flex justify-content-end align-items-center">
      <div class="form float-right p-5">
        <h3 class="heading">Create new Password</h3>
        <p class="sub-title">
          Your New Password must be diffrent from
          <br />your previous password
        </p>
        <form class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <label for="Password" class="Password">New Pasword</label>
            <input
              type="password"
              class="password form-control"
              id="password"
              @input="PassStrenght"
              v-model="password"
            />

            <div class="password-validation" id="password-validation">
              <div class="valid-box" id="valid-box"></div>
              <div class="valid-box" id="valid-box"></div>
              <div class="valid-box" id="valid-box"></div>
              <div class="valid-box" id="valid-box"></div>
            </div>

            <div class="my-3">
              <span
                >Use 8 or more characters with a mix of letters, number &
                symbols.</span
              >
            </div>
          </div>
          <div>
            <label for="confirmpassword" class="cpwd form-label"
              >Confirm Password</label
            >
            <input
              type="password"
              class="form-control"
              id="password_confirmation"
              v-model="password_confirmation"
            />
            <p id="message"></p>
            <button
              type="button"
              class="btn btn-danger btn-md mt-3 w-100"
              id="btn"
              v-on:click="resetPassword()"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>

    <div>
      <img
        class="dandiya-sticks"
        src="images/dandiya-sticks-clipart_10.png"
        alt
      />
    </div>
    <div class="rangoli">
      <img class="rangoli" src="images/Asset 2@3x.png" alt />

      <div class="garba-jocky">
        <img src="images/Group 1945.png" alt />
      </div>
    </div>

    <div class="dancer">
      <img src="images/dandiya-dancers_23-2147967531.png" alt />
    </div>
    <div class="rangoli-1">
      <img src="images/Asset 2@3x.png" alt />
    </div>
    <div class="rangoli-2">
      <img src="images/Asset 2@3x.png" alt />
    </div>
    <img class="rangoli-3" src="images/Asset 2@3x.png" alt />
  </div>
</template>

<script>
import axios from "@/axios";
import $ from "jquery";

export default {
  name: "NewPassword",

  data() {
    return {
      password: "",
      password_confirmation: null, // It's better to initialize this as an empty string
      gender: null, // Unused data property
      emailopt: "", // Unused data property
    };
  },
  mounted() {
    this.PassStrenght();
  },
  methods: {
    resetPassword() {
      if (this.password === this.password_confirmation) {
        const payload = {
          token: this.$route.query.token, // Access token from the route
          email: localStorage.getItem("email"), // Access email from localStorage
          password: this.password,
          password_confirmation: this.password_confirmation,
        };

        axios
          .post("password/reset", payload)
          .then((response) => {
            // console.log("Password reset response:", response);
            if (response.status === 200) {
              localStorage.setItem("token", response.data.token);
              this.$router.push("/login");
            } else {
              console.error(
                "Password reset failed. Status code:",
                response.status
              );
              alert("Password reset failed. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error during password reset:", error);
            if (error.response && error.response.status === 422) {
              // Display the validation error messages returned by the server
              const errorMessages = error.response.data.errors;
              alert("Validation error: " + JSON.stringify(errorMessages));
            } else {
              console.error("Other network or server error:", error);
              alert("An error occurred. Please try again later.");
            }
          });
      } else {
        alert("Password and Confirm Password do not match");
      }
    },

    checkPassword() {
      let password = document.getElementById("password").value;
      let password_confirmation = document.getElementById(
        "password_confirmation"
      ).value;
      let message = document.getElementById("message");
      // console.log(
      //   " password:",
      //   password,
      //   "\n",
      //   "Confirm Password:",
      //   password_confirmation
      // );

      if (password.length != 0) {
        if (password == password_confirmation) {
          // message.textContent = "Passwords match";
        } else {
          message.textContent = " Password don't match";
          message.style.color = "#c10111";
        }
      }
    },

    // Unused checkForm() method
    checkForm() {
      $(".form").submit(function (e) {
        e.preventDefault();
        var New_password = $("#pwd").val(); // There's no element with id "pwd" in your HTML
        var Cpassword = $("#cpwd").val(); // There's no element with id "cpwd" in your HTML

        $(".error").remove();

        if (New_password.length < 8) {
          $("#pwd").after(
            '<span class="error">Password must be at least 8 characters long</span>'
          );
        }
        if (Cpassword.length < 8) {
          $("#cpwd").after(
            '<span class="error">Password must be at least 8 characters long</span>'
          );
        }
        if ($('input[name="gender"]:checked').length == 0) {
          $("gender_label").after(
            '<span class="error">please select the gender</span>'
          );
        }
      });
    },

    PassStrenght() {
      $(document).ready(function () {
        $("#password").on("input", function () {
          var password = $(this).val();
          var strength = 0;
          const regex = /[$-/:-?{-~!"^_\[\]]/g;

          if (password.length >= 8) {
            strength += 1;
          }
          if (/[A-Z]/.test(password)) {
            strength += 1;
          }
          if (/[a-z]/.test(password)) {
            strength += 1;
          }
          if (/[0-9]/.test(password)) {
            strength += 1;
          }
          if (regex.test(password)) {
            strength += 1;
          }
          var meter = $("#password-validation .valid-box");
          meter.removeClass("weak medium strong");

          if (strength === 0) {
            meter.css("width", "0");
          } else if (strength === 1) {
            meter.addClass("weak").css("width", "25%");
          } else if (strength === 2) {
            meter.addClass("medium").css("width", "50%");
          } else if (strength === 3) {
            meter.addClass("strong").css("width", "75%");
          } else {
            meter.addClass("strong").css("width", "100%");
          }
        });
      });
    },
  },
};
</script>


<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.form {
  overflow: hidden;
  background: #fff;
  border-radius: 2.125rem;
  width: 50%;
  box-shadow: 0 0 31px #451111;
}

.form-control {
  width: 100%;
  padding: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #d4d4d4;
}

.strength {
  border: 1px solid black;
  height: 10px;
}

.indicator {
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.indicator span {
  width: 100%;
  height: 64%;
  background-color: #d4d4d4;
  border-radius: 8px;
  padding-left: 50px;
  margin-left: 5px;
  position: relative;
}

.indicator span.better {
  margin: 0 5px;
}

.heading {
  /* margin: 40px 0px 12px; */
  color: #c10111;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 600;
  text-align: center;
}

.sub-title {
  text-align: center;
  margin-bottom: 15px;
}

.dandiya-sticks {
  width: 17%;
  position: fixed;
  top: -58px;
  left: -62px;
}

.rangoli {
  width: 23%;
  position: fixed;
  top: 137px;
  left: 215px;
}

.garba-jocky img {
  width: 15%;
  position: fixed;
  left: 274px;
  top: 197px;
}

.dancer img {
  width: 16%;
  position: fixed;
  left: -1px;
  top: 506px;
}

.rangoli-1 img {
  width: 11%;
  position: fixed;
  left: 781px;
  top: 50px;
  z-index: -2;
}

.rangoli-2 img {
  position: fixed;
  width: 28%;
  top: -192px;
  left: 1173px;
  z-index: -2;
}

.rangoli-3 {
  top: 551px;
  left: 1211px;
  width: 6%;
  position: fixed;
  z-index: -2;
}

/* #btn {
  padding: 5px 121px 9px;
  border-radius: 8px;
  background-color: #c10111 !important;
} */

.weak {
  height: 0.5rem;
  width: 13vw;
  margin: 0.5rem;
  border-radius: 00.5rem;
  background-color: red;
}

.medium {
  height: 0.5rem;
  width: 13vw;
  margin: 0.5rem;
  border-radius: 00.5rem;
  background-color: orange;
}

.strong {
  height: 0.5rem;
  width: 13vw;
  margin: 0.5rem;
  border-radius: 00.5rem;
  background-color: green;
}

.valid-box {
  width: 13vw;
  margin: 0.5rem;
  border-radius: 00.5rem;
  height: 0.4rem;
}

.password-validation {
  display: flex;
  justify-content: space-between;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .form {
    width: 100%;
    /* Adjust as needed for smaller screens */
  }

  .dandiya-sticks,
  .rangoli,
  .garba-jocky,
  .dancer img,
  .rangoli-1 img,
  .rangoli-2 img,
  .rangoli-3 {
    opacity: 0.1;
    /* Hide these images on smaller screens */
  }
}

@media (max-width: 438px) {
  p {
    font-size: 0.9em;
  }

  .form {
    padding: 23px 23px !important;
  }
}
</style>
