<template>
  <div
    class="container d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="main">
      <div class="form float-right p-5">
        <div class="alert alert-danger mt-3" v-if="alertMessage">
          {{ alertMessage }}
        </div>

        <div class="alert alert-success mt-3" v-if="successMessage">
          {{ successMessage }}
        </div>

        <h2 class="text-center text-danger font-weight-bold">
          Forgot Password ?
        </h2>
        <p class="text-center p-2 m-1">
          Please enter the email address associated
          <br />with your email. We will email you a link to reset your
          password.
        </p>
        <form>
          <div class="row">
            <div class="col-md-12">
              <label for="email" class="form-label">Email</label>
              <input
                type="email"
                name="email"
                class="form-input bg-light p-3 form-control"
                v-model="email"
                id="email"
              />
            </div>
            <div class>
              <button
                class="bg-danger border-0 rounded p-1 px-4 py-2 m-3 btn text-center w-100"
                type="button"
                v-on:click="sendResetLink"
              >
                Send Link
              </button>
            </div>
            <div class>
              <router-link to="/login">
                <button
                  class="Cancel-btn p-1 px-5 py-2 m-3 bg-light shadow btn text-center w-100"
                >
                  Cancel
                </button>
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
    <img
      class="dandiya-sticks"
      src="images/dandiya-sticks-clipart_10.png"
      alt
    />

    <img class="rangoli" src="images/Asset 2@3x.png" alt />

    <img class="garba-jocky" src="images/Group 1945.png" alt />
    <div class="dancer">
      <img class="dancer" src="images/dandiya-dancers_23-2147967531.png" alt />
    </div>
    <div class="rangoli-1">
      <img src="images/Asset 2@3x.png" alt />
    </div>
    <div class="rangoli-2">
      <img src="images/Asset 2@3x.png" alt />
    </div>
    <img class="rangoli-3" src="images/Asset 2@3x.png" alt />
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "ForgotPass",
  data() {
    return {
      email: "",
      alertMessage: "",
      successMessage: "",
    };
  },

  methods: {
    // forGot() {
    //   let n1 = document.getElementById("email").value;
    //   axios
    //     .post("forgot-password", {
    //       email: n1,
    //     })
    //     .then((response) => {
    //       localStorage.setItem("email", n1);
    //       if (response.data.status_code == 200) {
    //         this.$router.push("/reset-password");
    //       } else {
    //         this.alertMessage = "You don't have an account. Please create your account.";
    //         console.log("Alert message set:", this.alertMessage); // Log the alert message
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error while making the request:", error); // Log any errors
    //     });
    // },

    sendResetLink() {
      if (!this.email || !/^.+@.+\..+$/.test(this.email)) {
        this.alertMessage = "Please enter a valid email address.";
        setTimeout(() => {
          this.alertMessage = ""; // Clear the alert message after 2 seconds
        }, 2000);
        return; // Exit the method if the email is empty or invalid
      }

      axios
        .post("forgot-password", {
          email: this.email,
        })
        .then((response) => {
          // console.log("Response:", response);
          if (response.data.status_code == 200) {
            this.sendConfirmationEmail(response.data.resetToken);
            this.successMessage = "Reset email sent successfully!";
            setTimeout(() => {
              this.successMessage = ""; // Clear the success message after 2 seconds
              this.email = ""; // Clear the email input
            }, 2000);
          } else {
            this.alertMessage =
              "You don't have an account. Please create your account.";
            setTimeout(() => {
              this.alertMessage = ""; // Clear the alert message after 2 seconds
            }, 2000);
          }
        })
        .catch((error) => {
          console.error("Error while making the request:", error);
        });
    },

    sendConfirmationEmail(resetToken) {
      const confirmationLink = `${window.location.origin}/reset-password?token=${resetToken}&email=${this.email}`;
      // eslint-disable-next-line no-unused-vars
      const subject = "Password Reset Link";

      // eslint-disable-next-line no-unused-vars
      const mailContent = `Click on the following link to reset your password: ${confirmationLink}`;

      // You can send the `mailContent` via your email service (Nodemailer, SendGrid, etc.) to the user's email address.
      // Here, I'm just logging it to the console as an example.
      // console.log(`Subject: ${subject}`);
      // console.log(`Mail Content: ${mailContent}`);

      // You can also log the token and email separately if needed
      // console.log(`Reset Token: ${resetToken}`);
      // console.log(`Email: ${this.email}`);
    },
  },
};
</script>


<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.form {
  overflow: hidden;
  background: #fff;
  border-radius: 2.125rem;
  width: 50%;
  box-shadow: 0 0 31px #451111;
}

h2 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 10px;
}

.Cancel-btn {
  box-shadow: 1px 2px 7px #d4d4d4;
}

.form-input {
  margin: 23px 13px;
}

.dandiya-sticks {
  width: 17%;
  position: fixed;
  top: -3rem;
  left: -4rem;
}

.rangoli {
  width: 23%;
  position: fixed;
  left: 174px;
  top: 144px;
}

.garba-jocky {
  width: 16%;
  position: fixed;
  left: 224px;
  top: 192px;
}

.dancer img {
  width: 16%;
  position: fixed;
  left: -7px;
  top: 506px;
}

.rangoli-1 img {
  width: 11%;
  position: fixed;
  left: 787px;
  top: 50px;
  z-index: -2;
}

.rangoli-2 img {
  position: fixed;
  width: 28%;
  top: -192px;
  left: 1173px;
  z-index: -2;
}

.rangoli-3 {
  top: 501px;
  left: 1214px;
  width: 7%;
  position: fixed;
  z-index: -2;
}

#btn {
  padding: 11px 121px 14px;
  margin: 10px 10px 10px;
  border-radius: 8px;
  box-shadow: 0 0 6px;
}

#btn .btn-white {
  box-shadow: 0 0 7px #d4d4d4;
  padding-right: 137px;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .form {
    width: 100%;
    /* Adjust as needed for smaller screens */
  }

  .dandiya-sticks,
  .rangoli,
  .garba-jocky,
  .dancer img,
  .rangoli-1 img,
  .rangoli-2 img,
  .rangoli-3 {
    opacity: 0.1;
    /* Hide these images on smaller screens */
  }
}

@media (max-width: 438px) {
  p {
    font-size: 0.9em;
  }

  .form {
    padding: 3px 23px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    margin-right: 13rem;
  }
}
</style>
