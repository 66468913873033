<template>
  <div class="box" v-if="profile">
    <div class="header">
      <img
        class="profile-pic"
        :src="profile.profile_photo ? profile.profile_photo : defaultImage"
      />
    </div>
    <div class="profile-details text-center">
      <h2 class="fs-5">{{ profile.first_name + " " + profile.last_name }}</h2>
      <span class="about-of-profile-details">
        {{ profile.about_me }}
        <br />
        {{ city }}
      </span>
      <br />
      <RouterLink
        v-if="profile.user_type === '1'"
        to="/Login"
        v-slot="{ navigate }"
      >
        <button
          v-on:click="navigate"
          role="link"
          class="btn btn-danger center mb-3"
          @click="this.$router.push({ name: 'Login' })"
        >
          View Profile
        </button>
      </RouterLink>
      <RouterLink
        v-else-if="profile.user_type === '3'"
        to="/Login"
        v-slot="{ navigate }"
      >
        <button
          v-on:click="navigate"
          role="link"
          class="btn btn-danger center mb-3"
          @click="this.$router.push({ name: 'Login' })"
        >
          View Profile
        </button>
      </RouterLink>
      <RouterLink
        v-else-if="profile.user_type === '2'"
        to="/Login"
        v-slot="{ navigate }"
      >
        <button
          v-on:click="navigate"
          role="link"
          class="btn btn-danger center mb-3"
          @click="this.$router.push({ name: 'Login' })"
        >
          View Profile
        </button>
      </RouterLink>
    </div>
  </div>

  <div v-else>
    <p>No profile available.</p>
  </div>

  <div class="box-2 w-100">
    <p class="d-flex align-items-center justify-content-between">
      Post
      <!-- <span  v-on:click="navigate" style="cursor: pointer">See All</span> -->
      <router-link to="/Login" class="text-decoration-none text-dark"
        >See All</router-link
      >
    </p>
    <div class="custom-responsive-img" style="overflow: auto">
      <div class="d-flex">
        <img
          class="images"
          src="images/Photos/994e09f0285161507794fbe5b3a1c59f.png "
          width="100%"
          alt="Photos"
        />
        <img
          class="images"
          src="images/Photos/994e09f0285161507794fbe5b3a1c59f.png "
          width="100%"
          alt="Photos"
        />
        <img
          class="images"
          src="images/Photos/994e09f0285161507794fbe5b3a1c59f.png "
          width="100%"
          alt="Photos"
        />
      </div>

      <div class="d-flex">
        <img
          class="images"
          src="images/Photos/994e09f0285161507794fbe5b3a1c59f.png "
          width="100%"
          alt="Photos"
        />
        <img
          class="images"
          src="images/Photos/994e09f0285161507794fbe5b3a1c59f.png "
          width="100%"
          alt="Photos"
        />
        <img
          class="images"
          src="images/Photos/994e09f0285161507794fbe5b3a1c59f.png "
          width="100%"
          alt="Photos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import AdBanner from "./AdBanner.vue";
export default {
  name: "DummyLeftSide",
  data() {
    return {
      token: "",
      profile: [],
      defaultImage: require("./assets/profile-no-img.png"),
      // apiUrl: "http://uatapi.garbajockey.com/public/api/",
      apiUrl: "https://api.garbajockey.com/api/",
    };
  },
  components: {},
  methods: {
    async getDe() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(`${this.apiUrl + endpoint}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.profile = response.data.data;
        // console.log("User Profile:", this.profile);

        if (localStorage.getItem("authToken") || response.data.Status == 200) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("authToken");
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    lodData() {
      this.token = localStorage.getItem("authToken");
      // console.log("this is home page show token", this.token);
    },
  },
  created() {
    this.lodData();
  },
  mounted() {
    this.getDe();
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background-color: #ffff;
}

.header {
  background-color: #c10111;
  height: 10%;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.profile-pic {
  width: 6.8rem;
  height: 6rem;
  position: relative;
  top: 43px;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 50%;
}

.profile-details {
  margin: 53px 0;
  padding: 10px;
}

.post {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 7px #d4d4d4;
  visibility: visible;
}

.post_image img {
  width: 13%;
  padding: 0 6px;
  border-radius: 3rem;
  height: 3rem;
}

.video {
  float: right;
  margin: -1rem -3rem 5px;
}

.video img {
  margin: 4px 11px 6px;
  cursor: pointer;
}

.Top-Profile {
  box-shadow: 0 0 8px #d4d4d4;
  border-radius: 4px;
  background-color: #ffffff;
}

.profiles {
  display: flex;
  padding: 10px 10px 10px;
  text-align: center;
}

.profiles_img {
  width: 72%;
  padding: 5px;
}

.profiles div {
  border: 1px solid #d4d4d4;
  align-items: center;
  padding: 0px 0px 10px;
}

.profiles-Designation {
  font-size: 13px;
  padding: 1px;
  color: #d4d4d4;
}

.follow-btn {
  width: 90%;
  border-radius: 15px;
  padding: 1px;
}

.box-2 {
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background: white;
  background-color: #ffff;
  padding: 10px 10px 8px;
}

.images {
  height: 78px;
  margin: 17px 6px 4px;
}

.Profile {
  border: 1px solid black;
  height: 70px;
  width: 16%;
}

.location {
  font-size: 10px;
}

.Profile-name {
  font-size: 10px;
  height: 73px;
  width: 85%;
  margin: 1px 7px 5px;
}

.main-content {
  margin: -198px 33px 10px;
  padding: 105px 95px 15px;
}

.post-block {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 30px #d4d4d4;
}

.post-block img.author-img {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  margin-right: 12px;
}

.post-block img.author-img.author-img--small {
  width: 11%;
  padding: 1%;
}

.post-block__content img,
.post-block__content video,
.post-block__content audio {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
}

.kebab-menu {
  cursor: pointer;
}

.dropdown .dropdown-text {
  display: none;
  width: 120px;
  background-color: #ffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* opacity: 0; */
  top: 100%;
  right: 28%;
  margin-left: -60px;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 7px #d4d4d4;
  cursor: pointer;
}

.post-block .comment-view-box__comment {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px;
}

input.form-control {
  border-radius: 10px;
}

.comment-btn {
  border: none;
  background-color: #ffffff;
}

.suggestion-title {
  height: 42px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 165px;
  margin: 7px -13px 10px;
  padding: 3px 5px 4px 5px;
}

.user_action {
  padding: 0px 0px 0px 56px;
  margin: -3px 5px 10px;
}

.user_action span {
  margin: -3px 10px 10px;
  padding: 0px 20px 0px;
}

.about-section {
  margin-left: -2%;
}

.dropdown-toggle::after {
  display: none;
}

.post_video {
  width: 100%;
  object-fit: fill;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .profile-details h2 {
    font-size: 1em !important;
  }

  .about-of-profile-details {
    font-size: 0.9em;
  }

  .custom-responsive-img {
    overflow: scroll;
  }

  .profile-pic {
    height: 5rem;
    width: 5.8rem;
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .profile-pic {
    height: 7rem;
    width: 7.8rem;
  }
}
</style>