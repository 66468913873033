<template>
  <div class="d-flex align-items-center">
    <button
      type="button"
      class="btn-sm p-0 d-flex align-items-center"
      style="
        width: 25%;
        cursor: pointer;
        width: 25%;
        background: none;
        border: none;
      "
      @click="this.$router.push({ name: 'Login' })"
    >
      <!-- data-bs-toggle="modal" -->
      <!-- data-bs-target="#exampleModalShare" -->
      <!-- @click="setClickedPost(postId)" -->
      <i
        class="fa-solid fa-arrow-up-right-from-square"
        style="font-size: 1.2rem"
      ></i>
      <span class="text-dark mx-1 px-2" style="font-size: 1rem">{{
        this.shares_count
      }}</span>
    </button>
  </div>
</template>

<script>
// import axios from "@/axios";

export default {
  name: "UsersharE",
  props: {
    postId: String,
    shares_count: String,
  },
  data() {
    return {
      clickedPostId: null,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  width: 100%;
}

.modal.show .modal-dialog {
  transform: none;
  margin-top: 10%;
}

.modal {
  --bs-modal-margin: 1.75rem;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  background-color: #ffffff78;
}
</style>
