<template>
  <div class="container changepassword">
    <div class="row">
      <div class="col-md-12">
        <!-- Profile Info -->
        <div class="profilepic-password d-flex align-items-center">
          <img :src="item ? item.profile_photo : defaultImage" class="w-25" />
          <h2 class="change-password-name ml-3">
            {{ item ? item.first_name + " " + item.last_name : "" }}
          </h2>
        </div>

        <!-- Message Section -->
        <div
          v-if="showPasswordMismatch || showIncorrectOldPassword"
          class="mt-2"
        >
          <div
            v-if="showPasswordMismatch"
            class="alert alert-danger"
            role="alert"
          >
            <span>{{ messageOldPass }}</span>
          </div>
          <div
            v-if="showIncorrectOldPassword"
            class="alert alert-danger"
            role="alert"
          >
            <span>{{ messageNewPassMiss }}</span>
          </div>
        </div>

        <!-- Password Change Form -->
        <div class="password-detail mt-4">
          <div class="curent-password mb-3">
            <label for="old_password" class="current-password-label"
              >Current password</label
            >
            <div class="current-password-input d-flex align-items-center">
              <input type="password" id="old_password" class="form-control" />
              <div class="password-icon ml-2">
                <span class="password" v-on:click="pass_toggle_current">
                  <i
                    class="fas fa-eye-slash"
                    id="eye2"
                    style="cursor: pointer"
                  ></i>
                </span>
              </div>
            </div>
          </div>

          <div class="curent-password mb-3">
            <label for="new_password" class="current-password-label"
              >New password</label
            >
            <div class="current-password-input d-flex align-items-center">
              <input
                type="password"
                id="new_password"
                @input="PassStrenght"
                class="form-control"
              />
              <div class="password-icon ml-2">
                <span class="password" v-on:click="pass_toggle1">
                  <i
                    class="fas fa-eye-slash"
                    id="eye1"
                    style="cursor: pointer"
                  ></i>
                </span>
              </div>
            </div>
          </div>

          <div class="password-validation d-flex justify-content-between">
            <div class="valid-box"></div>
            <div class="valid-box"></div>
            <div class="valid-box"></div>
            <div class="valid-box"></div>
          </div>

          <p class="password-instruction mt-2">
            Use 8 or more characters with a mix of letters, numbers & symbols
          </p>

          <div class="curent-password mb-3">
            <label for="confirm_password" class="current-password-label"
              >Confirm password</label
            >
            <div class="current-password-input d-flex align-items-center">
              <input
                type="password"
                id="confirm_password"
                class="form-control"
              />
              <div class="password-icon ml-2">
                <span class="password" v-on:click="pass_toggle">
                  <i class="fas fa-eye-slash" id="eye"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="password-button text-right mt-4">
          <button
            class="btn btn-danger change-password-button w-50"
            v-on:click="chanG()"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import $ from "jquery";
export default {
  name: "ChangePassword",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      birth_date: "",
      country_id: "",
      state_id: "",
      city_id: "",
      item: [],
      defaultImage: require("@/assets/profile-no-img.png"),
      showPasswordMismatch: false,
      showIncorrectOldPassword: false,
      messageOldPass: "",
      messageNewPassMiss: "",
    };
  },
  components: {},
  methods: {
    pass_toggle() {
      $("#eye").click(function () {
        if ($(this).hasClass("fa-eye-slash")) {
          $(this).removeClass("fa-eye-slash");

          $(this).addClass("fa-eye");

          $("#confirm_password").attr("type", "text");
        } else {
          $(this).removeClass("fa-eye");

          $(this).addClass("fa-eye-slash");

          $("#confirm_password").attr("type", "password");
        }
      });
    },

    pass_toggle1() {
      $("#eye1").click(function () {
        if ($(this).hasClass("fa-eye-slash")) {
          $(this).removeClass("fa-eye-slash");

          $(this).addClass("fa-eye");

          $("#new_password ").attr("type", "text");
        } else {
          $(this).removeClass("fa-eye");

          $(this).addClass("fa-eye-slash");

          $("#new_password ").attr("type", "password");
        }
      });
    },

    pass_toggle_current() {
      $("#eye2").click(function () {
        if ($(this).hasClass("fa-eye-slash")) {
          $(this).removeClass("fa-eye-slash");

          $(this).addClass("fa-eye");

          $("#old_password ").attr("type", "text");
        } else {
          $(this).removeClass("fa-eye");

          $(this).addClass("fa-eye-slash");

          $("#old_password ").attr("type", "password");
        }
      });
    },

    PassStrenght() {
      $(document).ready(function () {
        /* eslint-disable no-alert, no-debugger */

        $("#new_password").on("input", function () {
          var password = $(this).val();
          var strength = 0;
          /*eslint-disable*/
          const regex = /[$-/:-?{-~!"^_\[\]]/g;

          // Check length of password
          if (password.length >= 8) {
            strength += 1;
          }

          // Check for uppercase letters
          if (/[A-Z]/.test(password)) {
            strength += 1;
          }

          // Check for lowercase letters
          if (/[a-z]/.test(password)) {
            strength += 1;
          }

          // Check for numbers
          if (/[0-9]/.test(password)) {
            strength += 1;
          }

          // Check for special characters
          if (regex.test(password)) {
            strength += 1;
          }
          var meter = $("#password-validation .valid-box");
          meter.removeClass("weak medium  strong");

          if (strength === 0) {
            meter.css("width", "0");
          } else if (strength === 1) {
            meter.addClass("weak").css("width", "25%");
          } else if (strength === 2) {
            meter.addClass("medium").css("width", "50%");
          } else if (strength === 3) {
            meter.addClass("strong").css("width", "75%");
          } else {
            meter.addClass("strong").css("width", "100%");
          }
        });
      });
    },

    chanG() {
      let old = document.getElementById("old_password").value;
      let newp = document.getElementById("new_password").value;
      let cnf = document.getElementById("confirm_password").value;

      // Reset error messages
      this.showPasswordMismatch = false;
      this.showIncorrectOldPassword = false;

      axios
        .post("change-password", {
          headers: {
            DeviceID: "1111",
            versionName: "1.0.0",
            DeviceType: "0",
            // responseType:"arraybuffer"
          },
          old_password: old,
          new_password: newp,
          confirm_password: cnf,
        })
        .then((response) => {
          // console.log("response of confim", response.data);
          if (response.data.status === true) {
            // Password change successful
            // this.item = response.data.data; // Update user details
            // console.log("items ===== asdfkjasbkj");

            // Reset form fields
            document.getElementById("old_password").value = "";
            document.getElementById("new_password").value = "";
            document.getElementById("confirm_password").value = "";
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.message ===
              "The new password and confirm password must match."
          ) {
            // Handle password mismatch
            this.showPasswordMismatch = true;
            // console.log("Password mismatch");
            this.messageNewPassMiss = error.response.message;
            setTimeout(() => {
              this.showPasswordMismatch = false;
            }, 5000);
          } else if (
            error.response &&
            error.response.message === "The Old password is incorrect."
          ) {
            // Handle incorrect old password
            this.showIncorrectOldPassword = true;
            this.messageOldPass = error.response.message;
            // console.log("Incorrect old password");
            setTimeout(() => {
              this.showIncorrectOldPassword = false;
            }, 5000);
          }
        });
    },

    getDa() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = `entrant/my-profile`;
            break;
          case "3":
            endpoint = `gc/my-profile`;
            break;
          case "2":
            endpoint = `gj/my-profile`;
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-awards";
            break;
        }

        axios.get(endpoint).then((response) => {
          // this.id = response.data.data.id;
          // console.log((this.id = response.data.data.id));
          this.item = response.data.data;
          // console.log(this.item);
        });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    // resetFormFields() {
    //   // Clear the values of password fields
    //   old_password
    // },
  },
  mounted() {
    this.getDa();
  },
};
</script>

<style scoped></style>
