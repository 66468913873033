<template>
  <div class="dropdown">
    <button
      class="dropdown"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="border: none; background: none"
    >
      <!-- <img src="images/Icons/Group 2108.svg" width="20" height="15" /> -->
      <i class="bi bi-three-dots"></i>
    </button>
    <ul
      class="dropdown-menu"
      aria-labelledby="dropdownMenuButton1"
      style="
        position: absolute;
        inset: 0px auto auto -153px;
        margin: 0px;
        transform: translate(0px, 30px);
      "
    >
      <li>
        <a class="dropdown-item" id="hide_post" href="#" @click="onHide"
          >Hide</a
        >
      </li>
      <li>
        <a class="dropdown-item" href="#" @click="onCopyLink(postId)"
          >Copy Link</a
        >
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          @click="onReport"
          >Report Post</a
        >
      </li>
      <li>
        <a class="dropdown-item" href="#" @click="onDelete(postId, itemIndex)"
          >Delete</a
        >
      </li>
    </ul>
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column"
          id="modes"
        >
          <img src="../assets/Group89.svg" alt="" style="width: 100%" />
          <span class="my-2">This Post is Successfully Reported!</span>
          <button
            type="button"
            class="btn btn-danger btn-sm w-100"
            data-bs-dismiss="modal"
          >
            &lt;- Back to Home
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "lisT",
  props: {
    postId: String,
    itemIndex: Number,
  },
  methods: {
    onReport() {
      // console.log('Reported Button was clicked')
      const userType = this.$store.state.userType;
      // console.log("user type =", userType);

      // Define the base endpoint for posts
      let endpoint = "";

      switch (userType) {
        case "1": // Entrant
          endpoint = `entrant/posts/report/${this.postId}`;
          break;

        case "3": // Garba Class
          endpoint = `gc/posts/report/${this.postId}`;
          break;

        case "2": // Garba Jockey
          endpoint = `gj/posts/report/${this.postId}`;
          break;

        default:
          // console.log("Unknown user type.");
          return;
      }

      axios
        .post(endpoint, {
          post_id: this.postId,
          is_report: 0,
          headers: {
            DeviceID: "1111",
            versionName: "1.0.0",
            DeviceType: "0",
            // responseType:"arraybuffer"
          },
        })
        .then((response) => {
          if (response.data && response.data.status_code === "200") {
            // Check if the 'url' property exists before accessing it
            // this.page = response.data.data.url;
            // console.log("Report successful");
          } else {
            console.error("Invalid response format:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error reporting post:", error);
        });
    },

    onCopyLink(postId) {
      var baseUrl = window.location.origin;
      navigator.clipboard.writeText(baseUrl + "/posts/" + postId);
    },

    onHide() {
      const userType = this.$store.state.userType;
      // console.log("user type =", userType);

      // Define the base endpoint for posts
      let endpoint = "";

      switch (userType) {
        case "1": // Entrant
          endpoint = `entrant/posts/hide/${this.postId}`;
          break;

        case "3": // Garba Class
          endpoint = `gc/posts/hide/${this.postId}`;
          break;

        case "2": // Garba Jockey
          endpoint = `gj/posts/hide/${this.postId}`;
          break;

        default:
          // console.log("Unknown user type.");
          return;
      }

      axios
        .post(endpoint, {
          post_id: this.postId,
          is_hide: 1,
        })
        .then((response) => {
          // console.log(response.data);
          if (this.is_hide == 0) {
            this.post_id = !this.post_id;
            // console.log("Post is hidden successfully");
          }
        })
        .catch((error) => {
          console.error("Error hiding post:", error);
        });
    },

    onDelete(postId) {
      const userType = this.$store.state.userType;
      // console.log("user type =", userType);

      // Define the base endpoint for posts
      let endpoint = "";

      switch (userType) {
        case "1": // Entrant
          endpoint = `entrant/posts/${postId}`;
          break;

        case "3": // Garba Class
          endpoint = `gc/posts/${postId}`;
          break;

        case "2": // Garba Jockey
          endpoint = `gj/posts/${postId}`;
          break;

        default:
          // console.log("Unknown user type.");
          return;
      }

      if (confirm("Are you sure you want to delete this post?")) {
        axios
          .delete(endpoint)
          .then(() => {
            // Emit an event or update the data in the parent component
            // Example using event:
            this.$emit("post-deleted", postId);
            // console.log("Post deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting post:", error);
          });
      }
    },
  },
};
</script>

<style scoped>
.dropdown-item {
  font-size: 1rem;
}
</style>
