<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div style="margin-top: 8rem">
      <div
        v-if="garbaClass"
        :key="garbaClass.id"
        class="d-flex flex-column m-auto w-75"
      >
        <div
          class="d-flex align-items-center rounded-3 w-100 custom-top-content mb-3"
          style="box-shadow: black 0px 0px 13px -4px"
        >
          <img
            class="rounded-4 w-50 p-3"
            :src="defaultGCImage"
            alt="Garba Class Image"
            style="height: auto"
          />
          <div class="inner-text d-flex flex-column p-3">
            <h4 class="m-0">{{ garbaClass.garba_class_name }}</h4>
            <span
              ><i class="bi bi-geo-alt-fill"></i> {{ garbaClass.address }}</span
            >
            <span class="w-100">5K subscribers</span>
            <div
              class="d-flex align-items-center justify-content-between p-2 rounded-3 border border-danger w-100 custom-rating-text"
              style="box-shadow: 0px 0px 13px -6px black"
            >
              <span class="px-2 fs-7 text-bg-danger text-center rounded-pill">
                {{ garbaClass.rating_average }}
                <i class="fa fa-star" style="color: white"></i>
              </span>
              <span class="d-flex align-items-center">
                {{ garbaClass.rating_count }}
                <p class="px-2 m-0">Rating</p>
              </span>
            </div>
          </div>
        </div>

        <div
          class="mb-3 d-flex flex-column flex-lg-row"
          style="box-shadow: black 0px 0px 13px -4px"
        >
          <div class="p-3 col-lg-8">
            <p style="border-bottom: 1px solid black">About</p>
            <p class="w-100">{{ garbaClass.garba_class_description }}</p>
            <div>
              <h3>Video Contents</h3>
              <div v-if="videos.length > 0">
                <div
                  v-for="(video, index) in videos.slice(0, limit)"
                  :key="index"
                  @click="toggleVideo(index)"
                >
                  <!-- Custom video player interface -->
                  <div
                    class="d-flex justify-content-between align-items-center p-3 rounded-3"
                    style="
                      box-shadow: black 0px 0px 13px -4px;
                      background: #fee2e4;
                      cursor: pointer;
                    "
                  >
                    <!-- Left side: Video -->
                    <div class="custom-play-button">
                      <i class="bi bi-play-circle-fill"></i>
                    </div>

                    <!-- Middle: Text content -->
                    <div class="text-content">
                      <p>{{ video.title }}</p>
                    </div>

                    <!-- Right side: Video duration -->
                    <div class="video-duration">
                      <span> {{ getVideoDurationDisplay(index) }}</span>
                    </div>
                  </div>

                  <!-- Show the video below when clicked -->
                  <div v-if="showVideo === index" class="full-width-video">
                    <!-- <video :ref="`video${index}`" :src="video.video" class="w-100" autoplay controls
                      style="height: 16rem;"></video> -->
                    <video
                      :ref="`video${index}`"
                      :src="video.video"
                      class="w-100"
                      autoplay
                      controls
                      style="height: 16rem"
                    ></video>
                  </div>
                </div>
                <!-- Load More button -->
                <span
                  v-if="videos.length > limit"
                  @click="loadMoreVideos"
                  class="w-100 d-flex justify-content-center align-items-center p-3"
                  style="
                    box-shadow: black 0px 0px 13px -4px;
                    background: #fee2e4;
                    cursor: pointer;
                  "
                >
                  Load More....
                </span>
              </div>
              <div v-else>
                <p>No videos available for this Garba class.</p>
              </div>
            </div>
          </div>

          <!-- Trailer Video Section -->
          <div class="p-3 col-lg-4">
            <div class="d-flex align-items-center justify-content-center w-100">
              <video
                :src="garbaClass.trailer_video"
                controls
                class="w-100"
                style="height: auto"
              ></video>
            </div>
            <div class="w-100">
              <div class="d-flex align-items-center my-2">
                <span>Trailer video</span>
                <span>&#x20B9; {{ garbaClass.our_price }}</span>
              </div>
              <router-link
                :to="{
                  name: 'Subcription',
                  params: { id: garbaClass.id } + userId,
                }"
              >
                <button
                  class="btn btn-outline-danger my-2"
                  style="width: 100%"
                  @click="userSub"
                >
                  Subscribe
                </button>
              </router-link>
            </div>
            <div class="Review d-flex flex-column">
              <h6>Review & Rating</h6>
              <div
                v-for="reviewRating in ratings"
                :key="reviewRating.id"
                class="d-flex flex-column"
              >
                <div class="d-flex">
                  <img
                    class="rounded-3"
                    :src="
                      reviewRating.user.profile_photo
                        ? reviewRating.user.profile_photo
                        : defaultUserImage
                    "
                    alt
                    style="width: 20%; height: 3rem"
                  />
                  <!-- <img class="rounded-3" src="../assets/user1.jpg" alt style="width: 25%; height: 4rem;" /> -->
                  <div
                    class="rounded-3 w-100"
                    style="background: rgb(195, 192, 192); padding: 10px"
                  >
                    <h4 class="fs-5">
                      {{ reviewRating.user.first_name }}
                      {{ reviewRating.user.last_name }}
                    </h4>
                    <!-- <h4>sonu Sahani</h4> -->
                    <p class="user_review fs-6 m-0">
                      {{ reviewRating.review }}
                    </p>
                    <!-- <p class="user_review m-0">Lorem ipsum dolor sit amet.</p> -->
                    <div class="star-rating">
                      <template v-for="n in 5" :key="n">
                        <i
                          class="fa fa-star"
                          :style="{
                            color:
                              n <= reviewRating.points
                                ? 'gold'
                                : 'rgb(76, 76, 75)',
                          }"
                        ></i>
                      </template>
                    </div>
                  </div>
                </div>
                <hr class="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import NavBar from "./Profile_Screens/NavBar.vue";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
  name: "AboutGC",
  data() {
    return {
      garbaClass: [],
      showVideo: null,
      videos: [],
      currentVideoElement: null,
      ratings: [],
      defaultGCImage: require("./Profile_Screens/assets/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"),
      defaultUserImage: require("../assets/user1.jpg"),
      limit: 3,
    };
  },
  components: {
    NavBar,
  },

  mounted() {
    this.fetchGarbaClassDetails();
    this.getGcVideo();
  },

  watch: {
    garbaClass: {
      handler: "reviewRatingDetails",
      immediate: true, // Trigger on component mount
    },
  },

  methods: {
    async fetchGarbaClassDetails() {
      try {
        const userType = this.$store.state.userType;
        const userId = this.$route.params.id;
        let endpoint = "";

        switch (userType) {
          case "1":
            endpoint = `entrant/garba-classes/${userId}`;
            break;
          case "3":
            endpoint = `gc/garba-classes/${userId}`;
            break;
          case "2":
            endpoint = `gj/garba-classes/${userId}`;
            break;
          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(endpoint);
        this.garbaClass = response.data.data;
        // console.log("garba-class = ", this.garbaClass);
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async getGcVideo() {
      try {
        const userType = this.$store.state.userType;
        const garbaClassId = this.$route.params.id;
        let endpoint = "";

        switch (userType) {
          case "1":
            endpoint = `entrant/garba-class-videos?filter={"garba_class_id": "${garbaClassId}"}`;
            break;
          case "3":
            endpoint = `gc/garba-class-videos?filter={"garba_class_id": "${garbaClassId}"}`;
            break;
          case "2":
            endpoint = `gj/garba-class-videos?filter={"garba_class_id": "${garbaClassId}"}`;
            break;
          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(endpoint);

        if (response.status === 200 && response.data.status) {
          this.videos = response.data.data;
          // console.log("video of array = ", this.videos);
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    toggleVideo(index) {
      if (this.currentVideoElement) {
        this.currentVideoElement.pause();
      }

      const currentVideo = this.$refs[`video${index}`];

      if (
        currentVideo &&
        currentVideo.length > 0 &&
        typeof currentVideo[0].play === "function"
      ) {
        if (this.showVideo !== index) {
          currentVideo[0].play();
          this.currentVideoElement = currentVideo[0];
        } else {
          currentVideo[0].pause();
          this.currentVideoElement = null;
        }
      } else {
        console.error(`Video element not found for index ${index}`);
      }

      this.showVideo = this.showVideo === index ? null : index;
    },

    loadMoreVideos() {
      this.limit += 5;
      this.getGcVideo();
    },

    async reviewRatingDetails() {
      try {
        const userType = this.$store.state.userType;
        // const userId = this.$route.params.id;
        let endpoint = "";

        switch (userType) {
          case "1":
            endpoint = `entrant/ratings`;
            break;
          case "3":
            endpoint = `gc/ratings`;
            break;
          case "2":
            endpoint = `gj/ratings`;
            break;
          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(endpoint);
        this.ratings = response.data.data;
        // console.log("garba-class-ratings = ", endpoint, this.ratings);
      } catch (error) {
        console.error("Error:", error);
      }
    },

    getVideoDurationDisplay(index) {
      const videoRefKey = `video${index}`;
      // console.log("video ref = ", videoRefKey);

      // Check if the video array exists and has at least one element
      if (this.$refs[videoRefKey] && this.$refs[videoRefKey].length > 0) {
        const videoElement = this.$refs[videoRefKey][0];

        // console.log("video element = ", videoElement);

        // Check if the video element is available and is a valid video element
        if (videoElement instanceof HTMLVideoElement) {
          // Update the duration in seconds
          const durationInSeconds = Math.floor(videoElement.duration);

          // Use the formatDuration function to get the formatted duration
          return this.formatDuration(durationInSeconds);
        } else {
          console.error(`Invalid or missing video element for index ${index}`);
          return "00:00:00";
        }
      } else {
        console.error(`Video array not found for index ${index}`);
        return "00:00:00";
      }
    },

    formatDuration(durationInSeconds) {
      try {
        // Check if the duration is a valid number
        if (!isNaN(durationInSeconds)) {
          const formattedDuration = new Date(durationInSeconds * 1000)
            .toISOString()
            .substr(11, 8);
          return formattedDuration;
        } else {
          console.error(`Invalid duration value: ${durationInSeconds}`);
          return "00:00:00";
        }
      } catch (error) {
        console.error(
          `Error formatting duration for ${durationInSeconds} seconds:`,
          error
        );
        return "00:00:00";
      }
    },
  },
};
</script>

<style scoped>
.inner-text {
  font-family: "Gill Sans -reguler, semibold";
  color: black;
}

.text-content {
  width: 30%;
  padding: 0 10px;
}

.video-duration {
  width: 20%;
  text-align: right;
}

.full-width-video {
  margin-top: 20px;
}

@media (max-width: 899px) {
  .custom-top-content {
    flex-direction: column;
  }

  .custom-top-content img {
    width: 100% !important;
  }

  .custom-rating-text {
    width: 75% !important;
  }
}

@media (max-width: 449px) {
  .custom-rating-text {
    width: 100% !important;
  }
}

@media (max-width: 950px) {
  .flex-direction {
    flex-direction: column !important;
  }
}
</style>
