<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div class="container p-0 m-auto w-75">
      <div
        class="row w-100 d-flex justify-content-center m-0 p-0 position-relative"
        style="top: 8rem"
      >
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <LeftSideContent />

          <LeftAdBanner />
        </div>

        <div
          class="col-12 col-sm-12 col-md-6 col-lg-6 rounded-4"
          style="box-shadow: 0px 0px 20px whitesmoke"
        >
          <div class="notification">
            <!-- Entrant Notifications -->

            <h4>Entrant Notifications</h4>
            <div
              v-if="
                notifications.some(
                  (notification) => notification.userType === 'entrant'
                )
              "
              class="user_notifications d-flex justify-content-center align-items-center flex-column w-100 my-3"
            >
              <div
                v-for="notification in notifications
                  .filter((notification) => notification.userType === 'entrant')
                  .slice(0, 2)"
                :key="notification.id"
                class="d-flex align-items-center w-100"
              >
                <div class="d-flex align-items-center w-100 my-2">
                  <img
                    class="rounded-4 w-25"
                    :src="
                      notification.data.user.profile_photo
                        ? notification.data.user.profile_photo
                        : defaultImage
                    "
                    style="height: 5.6rem; object-fit: cover"
                  />

                  <div class="px-2 d-flex align-items-center w-100">
                    <!-- <span class="fs-5">{{ notification.data.user.first_name }} {{ notification.data.user.last_name }}</span> -->
                    <span class="pl-2 fs-5">{{
                      notification.data.notification_text
                    }}</span>
                  </div>

                  <!-- Display post image and creation time if available -->
                  <div v-if="notification.data.post">
                    <img
                      :src="notification.data.post.image"
                      alt="Post Image"
                      style="width: 100%"
                    />
                    <span>{{ formatDate(notification.created_at) }}</span>
                  </div>
                </div>
                <NotfiList />
              </div>
              <hr class="mx-2" style="width: 100%" />
            </div>

            <!-- Garba Class Notifications -->

            <h4>Garba Class Notifications</h4>
            <div
              v-if="
                notifications.some(
                  (notification) => notification.userType === 'garbaClass'
                )
              "
              class="user_notifications d-flex justify-content-center align-items-center flex-column w-100 my-3"
            >
              <div
                v-for="notification in notifications
                  .filter(
                    (notification) => notification.userType === 'garbaClass'
                  )
                  .slice(0, 2)"
                :key="notification.id"
                class="d-flex align-items-center my-2 w-100"
              >
                <div class="d-flex align-items-center w-100">
                  <img
                    class="rounded-4 w-25"
                    :src="
                      notification.data.user.profile_photo
                        ? notification.data.user.profile_photo
                        : defaultImage
                    "
                    style="height: 5.6rem; object-fit: cover"
                  />

                  <div class="px-2 d-flex align-items-center w-100">
                    <!-- <span class="fs-5">{{ notification.data.user.first_name }} {{ notification.data.user.last_name }}</span> -->
                    <span class="pl-2 fs-5">{{
                      notification.data.notification_text
                    }}</span>
                  </div>

                  <!-- Display post image and creation time if available -->
                  <div v-if="notification.data.post">
                    <img
                      :src="notification.data.post.image"
                      alt="Post Image"
                      style="width: 100%"
                    />
                    <span>{{ formatDate(notification.created_at) }}</span>
                  </div>
                </div>
                <NotfiList />
              </div>
              <hr class="mx-2" style="width: 100%" />
            </div>

            <!-- Garba Jockey Notifications -->

            <h4>Garba Jockey Notifications</h4>
            <div
              v-if="
                notifications.some(
                  (notification) => notification.userType === 'garbaJockey'
                )
              "
              class="user_notifications d-flex justify-content-center align-items-center flex-column w-100 my-3"
            >
              <div
                v-for="notification in notifications
                  .filter(
                    (notification) => notification.userType === 'garbaJockey'
                  )
                  .slice(0, 2)"
                :key="notification.id"
                class="d-flex align-items-center my-2 w-100"
              >
                <div class="d-flex align-items-center w-100">
                  <img
                    class="rounded-4 w-25"
                    :src="
                      notification.data.user.profile_photo
                        ? notification.data.user.profile_photo
                        : defaultImage
                    "
                    style="height: 5.6rem; object-fit: cover"
                  />

                  <div class="px-2 d-flex align-items-center w-100">
                    <!-- <span class="fs-5">{{ notification.data.user.first_name }} {{ notification.data.user.last_name }}</span> -->
                    <span class="pl-2 fs-5">{{
                      notification.data.notification_text
                    }}</span>
                  </div>

                  <!-- Display post image and creation time if available -->
                  <div v-if="notification.data.post">
                    <img
                      :src="notification.data.post.image"
                      alt="Post Image"
                      style="width: 100%"
                    />
                    <span>{{ formatDate(notification.created_at) }}</span>
                  </div>
                </div>
                <NotfiList />
              </div>
              <hr class="mx-2" style="width: 100%" />
            </div>

            <!-- No Notifications Message -->

            <div v-else>
              <p>No notifications available.</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-2 col-lg-3">
          <RightSideContent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./Profile_Screens/NavBar.vue";
import NotfiList from "./NotificationList.vue";
import axios from "@/axios";
import RightSideContent from "./RightSideContent.vue";
import LeftSideContent from "./LeftSideContent.vue";
import LeftAdBanner from "./LeftAdBanner.vue";

export default {
  name: "NotificationsP",
  props: {
    UserId: String,
    ActionType: String,
  },
  components: {
    NavBar,
    NotfiList,
    RightSideContent,
    LeftSideContent,
    LeftAdBanner,
  },

  data() {
    return {
      profile: [],
      notifications: [],
      suggestions: [],
      defaultImage: require("@/assets/profile-no-img.png"),
    };
  },
  methods: {
    // getDe() {
    //   axios.get("entrant/my-profile")
    //     .then((response) => {
    //       this.profile = response.data.data;
    //       console.log(this.profile);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching entrant profile:", error);
    //     });

    //   axios.get("gc/my-profile")
    //     .then((response) => {
    //       this.profile = response.data.data;
    //       console.log("gc user", this.profile);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching garba class profile:", error);
    //     });

    //   axios.get("gj/my-profile")
    //     .then((response) => {
    //       this.profile = response.data.data;
    //       console.log("gj user", this.profile);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching garba jockey profile:", error);
    //     });
    // },

    // getNotify() {
    //   // Fetch entrant notifications
    //   axios.get("entrant/notifications")
    //     .then((response) => {
    //       this.notifications = this.notifications.concat(response.data.data.map(notification => ({
    //         ...notification,
    //         userType: 'entrant', // Add userType property
    //       })));
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching entrant notifications:", error);
    //     });

    //   // Fetch garba class notifications
    //   axios.get("gc/notifications?page=1&per_page=3")
    //     .then((response) => {
    //       this.notifications = this.notifications.concat(response.data.data.map(notification => ({
    //         ...notification,
    //         userType: 'garbaClass', // Add userType property
    //       })));
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching garba class notifications:", error);
    //     });

    //   // Fetch garba jockey notifications
    //   axios.get("gj/notifications")
    //     .then((response) => {
    //       this.notifications = this.notifications.concat(response.data.data.map(notification => ({
    //         ...notification,
    //         userType: 'garbaJockey', // Add userType property
    //       })));
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching garba jockey notifications:", error);
    //     });
    // },

    getNotify() {
      const userType = this.$store.state.userType;
      let endpoint = "";
      let userTypeText = "";

      switch (userType) {
        case "1":
          endpoint = "entrant/notifications";
          userTypeText = "entrant";
          break;
        case "3":
          endpoint = "gc/notifications?page=1&per_page=3";
          userTypeText = "garbaClass";
          break;
        case "2":
          endpoint = "gj/notifications";
          userTypeText = "garbaJockey";
          break;

        // Add more cases for other user types as needed

        default:
          endpoint = "default-profile";
          break;
      }

      // Fetch notifications based on user type and endpoint
      axios
        .get(endpoint)
        .then((response) => {
          this.notifications = this.notifications.concat(
            response.data.data.map((notification) => ({
              ...notification,
              userType: userTypeText, // Add userType property
            }))
          );
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    },

    acceptNotification() {
      axios
        .post(`entrant/follows/action-type`, {
          follower_id: this.UserId,
          action_type: this.ActionType,
        })
        .then(() => {
          // console.log(response.data);
        });
    },

    RejectNotification() {
      axios
        .post(`entrant/follows/action-type`, {
          follower_id: this.UserId,
          action_type: this.ActionType,
        })
        .then(() => {
          // console.log(response.data);
        });
    },

    // suggestion() {
    //   axios.get("suggestions").then((response) => {
    //     console.log(response.data.data);
    //     this.suggestions = response.data.data.users;
    //     console.log(this.suggestions);
    //   });
    // },

    // Send_FollowRequest(id) {
    //   axios
    //     .post("entrant/follows/follow", {
    //       followee_id: id,
    //     })
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       alert(error.response.data.message);
    //     });
    // },
  },
  mounted() {
    // this.getDe();
    this.getNotify();
    // this.suggestion();
  },
};
</script>

<style scoped>
.modal.show .modal-dialog {
  transform: none;
  margin-top: 6%;
  margin-left: 38%;
}

.modal-footer > * {
  /* margin: calc(var(--bs-modal-footer-gap) * .5); */
  margin-right: 6%;
}

.box {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background-color: #ffff;
}

.header {
  background-color: #c10111;
  height: 10%;
  text-align: center;
}

.profile-pic {
  width: 35%;
  position: relative;
  top: 43px;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 1rem;
  height: 4.5rem;
}

.profile-details {
  margin: 53px 0;
  padding: 10px;
}

.post {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 7px #d4d4d4;
  visibility: visible;
}

.post_image img {
  width: 13%;
  padding: 0 6px;
  border-radius: 3rem;
  height: 3rem;
}

.video {
  float: right;
  margin: -1rem -3rem 5px;
}

.video img {
  margin: 4px 11px 6px;
  cursor: pointer;
}

.Top-Profile {
  box-shadow: 0 0 8px #d4d4d4;
  border-radius: 4px;
  background-color: #ffffff;
}

.profiles {
  display: flex;
  padding: 10px 10px 10px;
  text-align: center;
}

.profiles_img {
  width: 72%;
  padding: 5px;
}

.profiles div {
  border: 1px solid #d4d4d4;
  align-items: center;
  padding: 0px 0px 10px;
}

.profiles-Designation {
  font-size: 13px;
  padding: 1px;
  color: #d4d4d4;
}

.follow-btn {
  width: 90%;
  border-radius: 15px;
  padding: 1px;
}

.suggestion-box {
  background-color: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  box-shadow: 0 0 7px #d4d4d4;
}

.suggestion-box p {
  font-size: 10px;
  margin: 5px 0px 7px 26px;
}

.suggestion-box span {
  font-size: 15px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 9px 6px;
}

.user-1 div:nth-child(2n + 1) {
  border-right: 3px solid #828587;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:nth-child(2n + 2) {
  border-right: none;
  border-bottom: 3px solid #828587;
  padding: 13px 13px;
}

.user-1 div:last-child {
  border-bottom: none;
}

.suggestion_img {
  width: 60%;
  padding: 0.3rem;
}

.suggestion-btn {
  margin: 11px 2px 10px;
  padding: 0px 7px 2px;
  width: 14vh;
}

.box-2 {
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background: white;
  background-color: #ffff;
  padding: 10px 10px 8px;
}

.right-side {
  float: right;
  padding: 6px;
}

.left-side {
  padding: 6px;
}

.images {
  height: 78px;
  margin: 17px 6px 4px;
}

.Profile {
  border: 1px solid black;
  height: 70px;
  width: 16%;
}

.location {
  font-size: 10px;
}

.Profile-name {
  font-size: 10px;
  height: 73px;
  width: 85%;
  margin: 1px 7px 5px;
}

.main-content {
  margin: -198px 33px 10px;
  padding: 105px 95px 15px;
}

.post-block {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 30px #d4d4d4;
}

.post-block img.author-img {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  margin-right: 12px;
}

.post-block img.author-img.author-img--small {
  width: 11%;
  padding: 1%;
}

.post-block__content img,
.post-block__content video,
.post-block__content audio {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
}

.kebab-menu {
  cursor: pointer;
}

.dropdown .dropdown-text {
  display: none;
  width: 120px;
  background-color: #ffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* opacity: 0; */
  top: 100%;
  right: 28%;
  margin-left: -60px;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 7px #d4d4d4;
  cursor: pointer;
}

.post-block .comment-view-box__comment {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px;
}

input.form-control {
  border-radius: 10px;
}

.comment-btn {
  border: none;
  background-color: #ffffff;
}

.suggestion-title {
  height: 42px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 165px;
  margin: 7px -13px 10px;
  padding: 3px 5px 4px 5px;
}

.user_action {
  padding: 0px 0px 0px 56px;
  margin: -3px 5px 10px;
}

.user_action span {
  margin: -3px 10px 10px;
  padding: 0px 20px 0px;
}

.about-section {
  margin-left: -2%;
}

.fullname span::before {
  content: "";
  position: absolute;
  top: 18%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.fullname input:focus ~ label,
.fullname input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.fullname input:focus ~ span::before,
.fullname input:valid ~ span::before {
  width: 84%;
}

.email span::before {
  content: "";
  position: absolute;
  top: 25.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.email input:focus ~ label,
.email input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.email input:focus ~ span::before,
.email input:valid ~ span::before {
  width: 84%;
}

.contactUs span::before {
  content: "";
  position: absolute;
  top: 33%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.contactUs input:focus ~ label,
.contactUs input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.contactUs input:focus ~ span::before,
.contactUs input:valid ~ span::before {
  width: 84%;
}

.Address span::before {
  content: "";
  position: absolute;
  top: 40.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.Address input:focus ~ label,
.Address input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.Address input:focus ~ span::before,
.Address input:valid ~ span::before {
  width: 84%;
}

.country span::before {
  content: "";
  position: absolute;
  top: 47.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.country input:focus ~ label,
.country input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.country input:focus ~ span::before,
.country input:valid ~ span::before {
  width: 37.5%;
}

.state span::before {
  content: "";
  position: absolute;
  top: 47.3%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.state input:focus ~ label,
.state input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.state input:focus ~ span::before,
.state input:valid ~ span::before {
  width: 37.5%;
}

.city span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.city input:focus ~ label,
.city input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.city input:focus ~ span::before,
.city input:valid ~ span::before {
  width: 37.5%;
}

.pincode span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.pincode input:focus ~ label,
.pincode input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.pincode input:focus ~ span::before,
.pincode input:valid ~ span::before {
  width: 37.5%;
}

.upload span::before {
  content: "";
  position: absolute;
  top: 66.8%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.upload input:focus ~ label,
.upload input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.upload input:focus ~ span::before,
.upload input:valid ~ span::before {
  width: 84%;
}

.dropdown-toggle::after {
  display: none;
}

.post_video {
  width: 100%;
  object-fit: fill;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .profile-details h2 {
    font-size: 1em !important;
  }

  .about-of-profile-details {
    font-size: 0.9em;
  }

  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
</style>
