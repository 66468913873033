<!-- UservieW.vue -->
<template>
  <div class="d-flex align-items-center me-1">
    <button
      type="button"
      class="btn-sm p-0 d-flex align-items-center"
      style="width: 25%; background: none; border: none"
      @click="handleViewCountIncrement"
    >
      <i
        class="fa fa-eye"
        :class="{ 'animate__animated animate__tada': iconClicked }"
        style="font-size: 1.3em"
      ></i>
      <span class="text-dark mx-1 px-2" style="font-size: 1rem">{{
        localViewsCount
      }}</span>
    </button>
  </div>
</template>
  
<script>
export default {
  props: {
    postId: String,
    views_count: Number,
  },
  data() {
    return {
      localViewsCount: this.views_count,
      timerId: null, // Store the timer ID
      iconClicked: false,
    };
  },
  methods: {
    viewIconClicked() {
      this.iconClicked = !this.iconClicked;

      setTimeout(() => {
        this.iconClicked = false;
      }, 1500);
    },
    handleViewCountIncrement() {
      this.viewIconClicked();
      if (!this.hasBeenViewed()) {
        this.$emit("increment-view-count", this.postId);
        this.localViewsCount++;

        // Set a timer to increment the view count after 5 seconds
        this.timerId = setTimeout(() => {
          this.localViewsCount++;
          this.updateLocalStorage();
        }, 5000);
      }
    },
    hasBeenViewed() {
      return localStorage.getItem(`viewed_${this.postId}`) === "true";
    },
    updateLocalStorage() {
      localStorage.setItem(`viewed_${this.postId}`, "true");
    },
  },
  beforeUnmount() {
    // Clear the timer when the component is unmounted
    clearTimeout(this.timerId);
  },
  mounted() {
    if (this.hasBeenViewed()) {
      this.localViewsCount++;
    }
  },
};
</script>
  