<template>
  <router-view class="custom-animation-gj"> </router-view>
</template>

<script>
// import BodySection from './components/Home-Body.vue'
// import UserLikes from './components/Like_Post.vue'n
// import AddVideo from './components/Add-Video.vue'
export default {
  name: "App",
};
</script>

<style>
.custom-animation-gj {
  animation: fadeIn;
  animation-duration: 1.2s;
}

.custom-post-animation-gj {
  animation: zoomIn;
  animation-duration: 1.2s;
}

* {
  font-weight: 600;
}
.input-group-focus,
.form-control {
  box-shadow: none !important;
}

input:focus {
  border: 0.1px solid #cd0f0fae !important;
}
</style>


