<template>
  <div class="about-container">
    <div class="about-text-div">
      <h3>About <span>Infinitybrains</span></h3>
      <p>
        Infinity Brains is an accredited IT Company, which is a venture of
        Redjinni Industries Pvt. & taking Projects from all over India, with The
        latest technologies & digitally Transforming all kind of business. We
        believe in building and Maintaining long term relationships With all our
        clients.
      </p>
    </div>
    <div class="about-cards-div">
      <div class="about-card">
        <div class="about-card-img-div">
          <img src="../assets/earth.svg" alt="country" />
        </div>
        <p>8 Country</p>
      </div>
      <div class="about-card">
        <div class="about-card-img-div">
          <img src="../assets/city.svg" alt="city" id="city-img" />
        </div>
        <p>74 Cities</p>
      </div>
      <div class="about-card">
        <div class="about-card-img-div">
          <img src="../assets/single_user.svg" alt="user" id="user-img" />
        </div>
        <p>80,000+ Customers</p>
      </div>
      <div class="about-card">
        <div class="about-card-img-div">
          <img
            src="../assets/user_group.svg"
            alt="user-group"
            id="user-group-img"
          />
        </div>
        <p>3.5 Million People Reached</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutInfinityBrains",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.about-container {
  width: 100%;
  height: auto;
  margin: 3rem auto;
  background-color: whitesmoke;
}

.about-text-div {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.about-text-div h3 {
  width: fit-content;
  height: auto;
  font-size: 2.3rem;
  font-weight: 600;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.about-text-div span {
  color: #012a71;
}

.about-text-div p {
  width: 65%;
  height: auto;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: justify;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.about-cards-div {
  width: 100%;
  max-width: 1640px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.about-card {
  width: 290px;
  height: 160px;
  border: 1.8px solid #012a71;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 1rem;
}

.about-card-img-div {
  width: 100%;
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-card-img-div img {
  width: 3.7rem;
}

#city-img {
  margin-top: 0.1rem;
}

#user-img {
  width: 3.1rem;
}

#user-group-img {
  width: 4.2rem;
}

.about-card p {
  width: fit-content;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

@media screen and (max-width: 577px) {
  .about-text-div p {
    width: 90% !important;
  }
}

@media screen and (max-width: 992px) {
  .about-text-div h3 {
    font-size: 2rem;
  }
  .about-text-div p {
    width: 80%;
    font-size: 1.1rem;
  }
}
</style>
