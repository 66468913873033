<template>
  <NavBar />
  <div class="container">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <p class="mb-4">Please Fill the Details of the Problem</p>

      <div class="mb-3 fullname w-100">
        <label for="rtName" class="text-danger">Full Name</label>
        <input
          class="form-control"
          v-model="rtName"
          id="form-rtName"
          type="text"
          required
        />
      </div>

      <div class="mb-3 email w-100 p-0">
        <label for="rtEmail" class="text-danger">Email Address</label>
        <input
          v-model="rtEmail"
          class="form-control"
          type="email"
          id="form-rtEmail"
          required
        />
      </div>

      <div class="mb-3 contactUs w-100">
        <label for="rtContactNumber" class="text-danger">Contact Number</label>
        <input
          v-model="rtContactNumber"
          id="rtContactNumber"
          class="form-control"
          type="text"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
          maxlength="10"
          required
        />
      </div>

      <div class="mb-3 Address w-100">
        <label for="rtAddress" class="text-danger">Address</label>
        <input
          v-model="rtAddress"
          id="from-rtAddress"
          class="form-control"
          type="text"
          required
        />
      </div>

      <div class="row mb-3 Address w-100">
        <div class="col p-0">
          <label for="rtCountry" class="form-label text-danger"
            >Event Country</label
          >
          <select
            v-model="rtCountry"
            @change="loadStates"
            class="form-control"
            id="form-rtCountry"
            required
          >
            <option disabled value="">Select Country</option>
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
        &nbsp;
        <div class="col p-0">
          <label for="rtState" class="form-label text-danger"
            >Event State</label
          >
          <select
            v-model="rtState"
            @change="loadCities"
            class="form-control"
            id="form-rtState"
            required
          >
            <option disabled value="">Select State</option>
            <option v-for="state in states" :key="state.id" :value="state.id">
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-3 Address w-100">
        <div class="col p-0">
          <label for="rtCity" class="form-label text-danger">Event City</label>
          <select
            v-model="rtCity"
            class="form-control"
            id="form-rtCity"
            required
          >
            <option disabled value="">Select City</option>
            <option v-for="city in cities" :key="city.id" :value="city.id">
              {{ city.name }}
            </option>
          </select>
        </div>
        &nbsp;
        <div class="col p-0">
          <label for="rtPinCode" class="form-label text-danger"
            >City Pin-Code</label
          >
          <input
            v-model="rtPinCode"
            type="text"
            class="form-control"
            id="form-rtPinCode"
            required
          />
        </div>
      </div>

      <div class="file-upload w-100">
        <div class="d-flex justify-content-between">
          <label
            for="documentFile"
            class="form-label text-danger"
            style="cursor: pointer"
          >
            Upload Image
          </label>
          <img src="../assets/Group4826.png" alt="upload-image-logo" />
        </div>
        <div class="mt-2" style="border-bottom: 2px solid #dd3949"></div>

        <input
          type="file"
          id="documentFile"
          name="documentFile"
          accept="image/*, application/pdf"
          class="form-control visually-hidden"
          @change="handleFileSelectDocuments"
          multiple
        />
        <div class="d-flex flex-column mt-2">
          <p v-if="selectedFiles.length > 0" class="mb-1">Selected Files:</p>
          <ul class="list-group list-group-flush">
            <li
              v-for="(file, index) in selectedFiles"
              :key="index"
              class="list-group-item"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ file.name }}</span>
                <button
                  @click="removeFile(index)"
                  type="button"
                  class="btn-close btn-close-black"
                  aria-label="Close"
                ></button>
              </div>
              <hr v-if="selectedFiles.length > 0" />
            </li>
          </ul>
        </div>
      </div>

      <div class="mb-3 message">
        <label for="rtDescribeProblem" class="text-danger"
          >Describe the Problem</label
        >
        <textarea
          v-model="rtDescribeProblem"
          id="from-rtDescribeProblem"
          class="form-control fs-6"
          cols="53"
          rows="4"
          required
        ></textarea>
      </div>

      <div class="modal-footer w-100">
        <button
          type="button"
          class="btn btn-danger w-100"
          data-bs-toggle="modal1"
          data-bs-target="#exampleModal2"
          @click="submitForm"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import NavBar from "./Profile_Screens/NavBar.vue";

export default {
  name: "RaiseTicket",
  data() {
    return {
      //   imagePreview: [],
      selectedFiles: [],
      countries: [],
      states: [],
      cities: [],
      rtName: "",
      rtEmail: "",
      rtContactNumber: "",
      rtAddress: "",
      rtCountry: "",
      rtState: "",
      rtCity: "",
      rtPinCode: "",
      rtDescribeProblem: "",
      currentUserId: "",
      currentUserToken: "",
    };
  },
  mounted() {
    // Load countries on component mount
    this.loadCountries();
    // const currentUserStringId = localStorage.getItem('userid');
    // const currentUserStringToken = localStorage.getItem("token");
    // // console.log("current user Id details == ", currentUserStringId);
    // // console.log("current user Token details == ", currentUserStringToken);
    // try {
    //     if (currentUserStringId) {
    //         this.currentUserId = JSON.parse(currentUserStringId);
    //         this.currentUserToken = JSON.parse(currentUserStringToken);
    //         console.log("current user Id == ", this.currentUserId);
    //         console.log("current user token == ", this.currentUserToken);
    //     } else {
    //         throw new Error('Current user details not found.');
    //     }
    // } catch (error) {
    //     console.error(error.message);
    // }
  },
  watch: {
    rtCountry: "loadStates",
    rtState: "loadCities",
  },
  methods: {
    handleFileSelectDocuments(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          this.selectedFiles.push(files[i]);
          // console.log("File Details:", this.selectedFiles[i]);
          // console.log("File type:", this.selectedFiles[i].type);
        }
      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    submitForm() {
      // console.log("Submitting form...");
      // console.log("Current User ID:", localStorage.getItem("userid"));
      // console.log("Current User Token:", localStorage.getItem("token"));
      // Create a FormData object
      const formData = new FormData();
      formData.append("name", this.rtName);
      formData.append("email_address", this.rtEmail);
      formData.append("contact_no", this.rtContactNumber);
      formData.append("address", this.rtAddress);
      formData.append("country_id", this.rtCountry);
      formData.append("state_id", this.rtState);
      formData.append("city_id", this.rtCity);
      formData.append("desc_the_problem", this.rtDescribeProblem);
      // Append each selected file to FormData
      for (let i = 0; i < this.selectedFiles.length; i++) {
        // Use unique keys for each file, for example, "raise_ticket[0]", "raise_ticket[1]", etc.
        formData.append(`raise_ticket[${i}]`, this.selectedFiles[i]);
      }
      // Make an API call to your backend
      axios
        // .post("http://localhost:3500/submit-form", formData, {
        .post("raiseticket-store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // Handle the success response
          // console.log(response.data);
        })
        .catch((error) => {
          // Handle the error
          console.error("Axios Error:-", error);
        });
    },
    async loadCountries() {
      try {
        let allCountries = [];
        let shouldContinue = true;
        let page = 1;
        while (shouldContinue) {
          const response = await axios.get(`countries?page=${page}`);
          // console.log("Response:", response); // Log the response
          const countries = response.data && response.data.data;
          if (!countries || countries.length === 0) {
            // No more countries to fetch, break the loop
            shouldContinue = false;
          } else {
            allCountries = [...allCountries, ...countries];
            page++;
          }
        }
        this.countries = allCountries;
        // console.log("All Countries:", this.countries); // Log the final list of countries
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    },
    async loadStates() {
      try {
        const response = await axios.get(
          `states_of_country?country_id=${this.rtCountry}`
        );
        this.states = response.data.data; // Update states array
        // console.log("states = ", this.states);
        this.rtState = ""; // Reset state value
        this.rtCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading states:", error);
      }
    },
    async loadCities() {
      try {
        const response = await axios.get(
          `cities_of_state?state_id=${this.rtState}`
        );
        this.cities = response.data.data; // Update cities array
        // console.log("states = ", this.cities);
        this.rtCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading cities:", error);
      }
    },
  },
  components: { NavBar },
};
</script>
