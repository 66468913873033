<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    style="display: none"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
  >
    Launch demo modal
  </button>

  <!-- Modal -->
  <div
    v-if="paymentsuccess"
    style="width: 50%; margin: 0 auto; margin-top: 20%"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
        <div class="modal-body">
          <h3 class="text-center" style="color: #c10111">Congralulations..!</h3>
          <p style="text-align: center">
            Thank you for subscription, <br />now you are eligible to view all
            offers.
          </p>
          <router-link
            to="/Home"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
            "
          >
            <button type="button" class="btn btn-danger m-2">
              Go to home page
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Payment_Success",
  data() {
    return {
      paymentsuccess: true,
    };
  },

  mounted() {
    this.paymentsuccess = true;
    let userid = localStorage.getItem("userid");
    const hash = localStorage.getItem("hash");
    if (hash) {
      axios
        .post(`entrant/subscriptions/payment-status/` + userid, {
          transaction_id: "apTfklie234212kQ",
          payment_status: "1",
        })
        .then((response) => {
          // Handle successful response
          // console.log("Payment status updated successfully", response);
          // localStorage.setItem('paymentStatus', this.paymentStatus);
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating payment status:", error);
        });
    }
  },
};
</script>