<template>
    <div>
      <h1>Privacy Policy for GJ for Android</h1>
  
      <h2>INTRODUCTION</h2>
      <p>
        "Garba Jockey is a venture of Redjinni Industries Private Limited." A Portal
        for the Garba Lovers & Garba Classes, here known as Garba Jockeys, where
        they can learn & showcase their Garba talent, Share & Upload Videos, And win
        exciting prizes for the same. Moreover, they can also take their Garba
        classes online & teach the people who are willing to learn Garba."
      </p>
      <p>
        At GarbaJockey, we are committed to protecting your privacy as a user of our
        Android app. We use the information we collect about you to maximize the
        services that we provide to you. We respect the privacy and confidentiality
        of the information provided by you. Please read our privacy policy below
        carefully.
      </p>
      <p>
        This Privacy Policy explains how GarbaJockey collects, uses, discloses,
        transfers, stores, and protects your personal information and what
        corresponding rights you have with the processing of personal information.
        Personal information refers to any information related to an identified or
        identifiable natural person or household.
      </p>
      <p>
        You may change your details at any time by advising us in writing via email.
        All information we receive from our users is protected by our secure server.
        GarbaJockey's secure server software encrypts all user information before it
        is sent to us. Furthermore, all user data collected is secured against
        unauthorized use or access.
      </p>
  
      <h2>STORAGE AND SECURITY OF YOUR INFORMATION</h2>
      <p>
        We receive and store the information you provide us in our Android app. You
        may provide basic contact information such as your name, email address, and
        phone number to enable us to send information or process your service order
        or subscription, and we may also collect additional information at other
        times, including but not limited to, when you provide feedback, change your
        content or email preferences, respond to a survey, or communicate with our
        customer support.
      </p>
      <p>
        We may use personal information collected from you to provide you with
        direct marketing material, updates regarding our app, and information in the
        form of a newsletter. This will only apply if you have registered or
        subscribed to such publications by registering your details with us.
        However, if you wish to cease receiving any such information, you may let us
        know either by email or unsubscribing at any time, and your request will be
        actioned immediately.
      </p>
      <p>
        Individual profiles and company details are not used for any other purpose.
        Details are only supplied to a third-party supplier when it is required by
        law, for services that you have purchased, or to protect our copyright,
        trademarks, and other legal rights. We respect the privacy of our app users.
        We may collect information on or through this app that can personally
        identify you. We will use all reasonable means to protect the confidentiality
        of your Personal Data while in our possession or control. We will not knowingly
        share any of your Personal Data with any third party other than our service
        providers who assist us in providing the information and/or services we are
        providing to you. To the extent that we do share your personal information
        with a service provider, we would only do so if that party has agreed to
        comply with our privacy standards as described in this privacy policy.
      </p>
      <p>
        Any non-personal information, communications, and material you send to this
        app or us by email, or which we obtain from third parties without promises
        of confidentiality, may be kept, used, and disclosed by us on a non-confidential
        basis. We are free to use and reproduce any such information freely, and for
        any purpose whatsoever. Specifically, we will be free to use any ideas,
        concepts, know-how, or techniques contained in such information for any purpose,
        including developing, manufacturing, or marketing products.
      </p>
      <p>
        <strong>Our Email Policy:</strong> We'll not send, sell, or trade your email
        address to a third party without your permission, so feel safe with us. If
        you receive an email from GarbaJockey asking for your credit cards, banking
        details, or any other financial information, just neglect and ignore the email
        as we never ask via email for this information.
      </p>
  
      <h2>DISCLOSURE OF YOUR INFORMATION</h2>
      <p>
        We may from time to time need to disclose certain information, which may
        include your Personal Data, to comply with a legal requirement, such as a law,
        regulation, court order, subpoena, or warrant, in the course of a legal
        proceeding or response to a law enforcement agency.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicyGJ",
  };
  </script>
  