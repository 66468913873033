<template>
  <div class="tags custom-animation-gj" id="tags">
    <div class="upper-tag row">
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
    </div>
    <div class="middle-tag row my-2">
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
    </div>
    <div class="lower-tag row">
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
      <img
        class="tag-img col-4"
        src="../assets/6c0acfdb610220fbbf3718e6460a8bf5.png"
        alt
      />
    </div>
  </div>
  <!-- <h1>tags</h1> -->
</template>

<script>
export default {
  name: "MyTags",
};
</script>

<style scoped>
/* You don't need to include custom styles for Bootstrap components */
</style>
