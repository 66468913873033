<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div class="container p-0 m-auto w-75">
      <div
        class="row w-100 d-flex justify-content-center m-0 p-0 position-relative"
        style="top: 8rem"
      >
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <LeftSideContent />

          <LeftAdBanner />
        </div>

        <div
          class="col-12 col-sm-12 col-md-6 col-lg-6 rounded-4"
          style="box-shadow: 0px 0px 20px whitesmoke"
        >
          <div
            id="carouselExampleIndicators"
            class="carousel slide my-3"
            data-bs-ride="carousel"
          >
            <div
              class="carousel-indicators d-flex justify-content-center align-items-center position-absolute"
            >
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
                style="border-radius: 50%; background: #ffff"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
                style="border-radius: 50%; background: #ffff"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
                style="border-radius: 50%; background: #ffff"
              ></button>
            </div>
            <div class="carousel-inner w-100">
              <div class="carousel-item active">
                <img
                  src="../assets/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"
                  style="display: block; width: 100%; height: 205px"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="../assets/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"
                  style="display: block; width: 100%; height: 205px"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="../assets/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"
                  style="display: block; width: 100%; height: 205px"
                  alt="..."
                />
              </div>
            </div>
          </div>

          <div class="d-flex flex-column">
            <div
              class="d-flex align-items-center w-100 my-3 responsive-css-garba-class"
              v-for="(garbaclass, index) in garbaclasses.slice(
                0,
                getLimitCount()
              )"
              :key="index"
            >
              <div
                class="rounded-3 custom-res-img"
                style="width: 9rem; height: 7rem; overflow: hidden"
              >
                <img
                  class="w-100 h-100"
                  :src="
                    garbaclass.garba_class_logo
                      ? garbaclass.garba_class_logo
                      : defaultGCImage
                  "
                  style="object-fit: cover"
                />
              </div>
              <!-- <img class="rounded-3 w-25 custom-res-img" :src="defaultGCImage" alt="this is template of video"
              style="height: 7rem;" /> -->
              <div
                class="d-flex align-items-center w-100 flex-column mx-3 custome-res-headings"
              >
                <router-link
                  :to="{
                    name: 'AboutGarbaClass',
                    params: { id: garbaclass.id },
                  }"
                  style="text-decoration: none; color: black"
                  class="w-100"
                >
                  <h4 class="w-100">{{ garbaclass.garba_class_name }}</h4>
                  <span class="w-100">{{ garbaclass.address }}</span>
                  <span class="w-100">5K subscribers</span>
                  <div
                    class="d-flex align-items-center w-100 justify-content-between custom-res"
                  >
                    <span
                      class="px-2 fs-7 text-bg-danger text-center rounded-pill"
                    >
                      {{ garbaclass.rating_average }}
                      <i class="fa fa-star" style="color: white"></i>
                    </span>
                    <span class="mx-2"
                      >Price: {{ garbaclass.garba_class_fees }}</span
                    >
                    <span class="d-flex justify-content-between">
                      <button class="btn btn-outline-danger" @click="GCdetails">
                        Subscribe
                      </button>
                    </span>
                  </div>
                </router-link>
              </div>
            </div>

            <div
              class="w-100 d-flex align-items-center justify-content-center mt-3 link-class mb-5"
            >
              <a href="" @click.prevent="viewMore">{{
                viewMoreFlag == true ? "View Less" : "View More"
              }}</a>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-2 col-lg-3">
          <RightSideContent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RightSideContent from "../components/RightSideContent.vue";
import LeftSideContent from "../components/LeftSideContent.vue";
import NavBar from "./Profile_Screens/NavBar.vue";
import LeftAdBanner from "./LeftAdBanner.vue";

import axios from "@/axios";
export default {
  name: "GarbaPage",
  components: {
    NavBar,
    RightSideContent,
    LeftSideContent,
    LeftAdBanner,
  },

  data() {
    return {
      profile: [],
      garbaclasses: [],
      MyGc: [],
      suggestions: [],
      defaultImage: require("@/assets/profile-no-img.png"),
      defaultGCImage: require("./Profile_Screens/assets/d4ac8b9a68ffccbc2e6b53c8269f2dc0.png"),
      limitCount: 5,
      totalClassessCount: null,
      viewMoreFlag: false,
    };
  },
  methods: {
    viewMore() {
      this.viewMoreFlag = !this.viewMoreFlag;
      this.getLimitCount();
      window.scrollTo(0, 0);
    },
    getLimitCount() {
      if (this.viewMoreFlag == false) {
        return 5;
      } else {
        return this.totalClassessCount;
      }
    },
    getGC() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = `entrant/garba-classes?page=1&per_page=100`;
            break;
          case "3":
            endpoint = `gc/garba-classes?page=1&per_page=100`;
            break;
          case "2":
            endpoint = `gj/garba-classes?page=1&per_page=100`;
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "Garba-page-awards";
            break;
        }

        axios
          .get(endpoint)
          .then((response) => {
            // if (response.data.user_type !== 2) {
            // this.garbaclasses = response.data.data;

            // console.log("Garba Classes: ", response);

            this.garbaclasses = response.data.data.sort((a, b) => {
              return new Date(b.created_at) - new Date(a.created_at);
            });

            this.totalClassessCount = response.data.total;
            // console.log("data of user=", this.garbaclasses[1].rating_average);
            this.user_id = response.data.data.user_id;
            // }
            // else if (response.data.user_type === 3) {
            //   this.GetgarbaClassuser();
            // } else {
            //   this.$router.push("/");
            // }
          })
          .catch((error) => {
            console.log("error ", error);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    // GetgarbaClassuser() {
    //   axios
    //     .get("gc/garba-classes")
    //     .then((response) => {
    //       // this.garbaclasses = response.data.data;

    //       this.garbaclasses = response.data.data.sort((a, b) => {
    //         return new Date(b.created_at) - new Date(a.created_at);
    //       });
    //       console.log("garba-class", this.garbaclasses);
    //       // this.user_id= response.data.data.user_id
    //     })
    //     .catch((error) => {
    //       console.log("error ", error);
    //     });
    // },

    MyGC() {
      const userType = this.$store.state.userType;
      // console.log("User type at the beginning:", userType);

      if (userType === 3) {
        axios.get("gc/my-garba-classes").then((response) => {
          // console.log(response.data.data);
          this.MyGc = response.data.data;
          // console.log(this.MyGc);
        });
      }
    },

    GCdetails() {
      try {
        const userId = this.$route.params.id;
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = `entrant/garba-classes/${userId}`;
            break;
          case "3":
            endpoint = `gc/garba-classes/${userId}`;
            break;
          case "2":
            endpoint = `gj/garba-classes/${userId}`;
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "Garba-page-awards";
            break;
        }

        // alert(userId)
        axios
          .get(endpoint)
          .then(() => {
            // Do something with the response data
            // console.log(response.data);
          })
          .catch((error) => {
            // Handle the error
            console.log(error);
          });
      } catch (err) {
        console.log("error = ", err);
      }
    },

    // GC_details() {
    //   const userId = this.$route.params.id;
    //   // alert(userId)
    //   axios
    //     .get(`gc/garba-classes/${userId}`)
    //     .then((response) => {
    //       // Do something with the response data
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       // Handle the error
    //       console.log(error);
    //     });
    // },
  },
  mounted() {
    this.getGC();
    // this.GCdetails();
    this.MyGC();
    // this.suggestion();
    // this.GetgarbaClassuser();
    // this.GC_details();
  },
};
</script>

<style scoped>
.link-class a {
  color: #c10111;
  padding: 0.4rem 0.8rem;
  background: transparent;
  border: 1px solid transparent;
}

.link-class a:hover {
  padding: 0.4rem 0.8rem;
  background-color: #f5f5f5;
  border: 1px solid #c10111;
  border-radius: 6px;
  text-decoration: none;
}
.modal.show .modal-dialog {
  transform: none;
  margin-top: 6%;
  margin-left: 38%;
}

.modal-footer > * {
  /* margin: calc(var(--bs-modal-footer-gap) * .5); */
  margin-right: 6%;
}

.box {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background-color: #ffff;
}

.header {
  background-color: #c10111;
  height: 10%;
  text-align: center;
}

.profile-pic {
  width: 35%;
  position: relative;
  top: 43px;
  box-shadow: 0 0px 9px #4d4d4d;
  border-radius: 1rem;
  height: 4.5rem;
}

.profile-details {
  margin: 53px 0;
  padding: 10px;
}

.post {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 7px #d4d4d4;
  visibility: visible;
}

.post_image img {
  width: 13%;
  padding: 0 6px;
  border-radius: 3rem;
  height: 3rem;
}

.video {
  float: right;
  margin: -1rem -3rem 5px;
}

.video img {
  margin: 4px 11px 6px;
  cursor: pointer;
}

.Top-Profile {
  box-shadow: 0 0 8px #d4d4d4;
  border-radius: 4px;
  background-color: #ffffff;
}

.profiles {
  display: flex;
  padding: 10px 10px 10px;
  text-align: center;
}

.profiles_img {
  width: 72%;
  padding: 5px;
}

.profiles div {
  border: 1px solid #d4d4d4;
  align-items: center;
  padding: 0px 0px 10px;
}

.profiles-Designation {
  font-size: 13px;
  padding: 1px;
  color: #d4d4d4;
}

.follow-btn {
  width: 90%;
  border-radius: 15px;
  padding: 1px;
}

.box-2 {
  border-radius: 10px;
  box-shadow: 0 0 7px #d4d4d4;
  background: white;
  background-color: #ffff;
  padding: 10px 10px 8px;
}

.right-side {
  float: right;
  padding: 6px;
}

.left-side {
  padding: 6px;
}

.images {
  height: 78px;
  margin: 17px 6px 4px;
}

.Profile {
  border: 1px solid black;
  height: 70px;
  width: 16%;
}

.location {
  font-size: 10px;
}

.Profile-name {
  font-size: 10px;
  height: 73px;
  width: 85%;
  margin: 1px 7px 5px;
}

.main-content {
  margin: -198px 33px 10px;
  padding: 105px 95px 15px;
}

.post-block {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 30px #d4d4d4;
}

.post-block img.author-img {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  margin-right: 12px;
}

.post-block img.author-img.author-img--small {
  width: 11%;
  padding: 1%;
}

.post-block__content img,
.post-block__content video,
.post-block__content audio {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
}

.kebab-menu {
  cursor: pointer;
}

.dropdown .dropdown-text {
  display: none;
  width: 120px;
  background-color: #ffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* opacity: 0; */
  top: 100%;
  right: 28%;
  margin-left: -60px;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 7px #d4d4d4;
  cursor: pointer;
}

.post-block .comment-view-box__comment {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px;
}

input.form-control {
  border-radius: 10px;
}

.comment-btn {
  border: none;
  background-color: #ffffff;
}

.suggestion-title {
  height: 42px;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 165px;
  margin: 7px -13px 10px;
  padding: 3px 5px 4px 5px;
}

.user_action {
  padding: 0px 0px 0px 56px;
  margin: -3px 5px 10px;
}

.user_action span {
  margin: -3px 10px 10px;
  padding: 0px 20px 0px;
}

.about-section {
  margin-left: -2%;
}

.fullname span::before {
  content: "";
  position: absolute;
  top: 18%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.fullname input:focus ~ label,
.fullname input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.fullname input:focus ~ span::before,
.fullname input:valid ~ span::before {
  width: 84%;
}

.email span::before {
  content: "";
  position: absolute;
  top: 25.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.email input:focus ~ label,
.email input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.email input:focus ~ span::before,
.email input:valid ~ span::before {
  width: 84%;
}

.contactUs span::before {
  content: "";
  position: absolute;
  top: 33%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.contactUs input:focus ~ label,
.contactUs input:valid ~ label {
  margin-top: -3%;
  color: gray;
}

.contactUs input:focus ~ span::before,
.contactUs input:valid ~ span::before {
  width: 84%;
}

.Address span::before {
  content: "";
  position: absolute;
  top: 40.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.Address input:focus ~ label,
.Address input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.Address input:focus ~ span::before,
.Address input:valid ~ span::before {
  width: 84%;
}

.country span::before {
  content: "";
  position: absolute;
  top: 47.5%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.country input:focus ~ label,
.country input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.country input:focus ~ span::before,
.country input:valid ~ span::before {
  width: 37.5%;
}

.state span::before {
  content: "";
  position: absolute;
  top: 47.3%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.state input:focus ~ label,
.state input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.state input:focus ~ span::before,
.state input:valid ~ span::before {
  width: 37.5%;
}

.city span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.city input:focus ~ label,
.city input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.city input:focus ~ span::before,
.city input:valid ~ span::before {
  width: 37.5%;
}

.pincode span::before {
  content: "";
  position: absolute;
  top: 53.6%;
  left: 54.5%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.pincode input:focus ~ label,
.pincode input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.pincode input:focus ~ span::before,
.pincode input:valid ~ span::before {
  width: 37.5%;
}

.upload span::before {
  content: "";
  position: absolute;
  top: 66.8%;
  left: 8%;
  width: 0%;
  height: 1.5px;
  background: #c10111;
  transition: 0.5s;
}

.upload input:focus ~ label,
.upload input:valid ~ label {
  margin-top: -3%;
  color: #c10111;
}

.upload input:focus ~ span::before,
.upload input:valid ~ span::before {
  width: 84%;
}

.dropdown-toggle::after {
  display: none;
}

.post_video {
  width: 100%;
  object-fit: fill;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .profile-details h2 {
    font-size: 1em !important;
  }

  .about-of-profile-details {
    font-size: 0.9em;
  }

  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 1399px) {
  .custom-responsive-img {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .responsive-css-garba-class {
    flex-direction: column;
    text-align: center;
  }

  .custom-res {
    justify-content: center;
    margin: 0.6rem 0;
  }

  .custome-res-headings {
    margin: 1rem 0;
  }

  .custom-res-img {
    width: 50% !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
}
</style>
