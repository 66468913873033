<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Profile Section -->
      <div class="col-lg-6 d-flex" style="box-shadow: 0px 0px 9px -4px black">
        <div class="d-flex justify-content-center align-items-center">
          <img
            class="rounded-circle my-2"
            :src="profile.profile_photo ? profile.profile_photo : defaultImage"
            style="width: 6rem; height: 6.1rem"
          />
          <!-- <img class="w-75 rounded-4 my-2" src="../Profile_Screens/assets/profile-no-img.png"
            v-if="profile.profile_photo === ''" style="height: 8.1rem;" /> -->
        </div>
        <div class="w-100 ml-3 my-3">
          <h2 class="fs-4">{{ profile.first_name + profile.last_name }}</h2>
          <h3 class="fs-5">{{ profile.email }}</h3>
          <h3 class="fs-6">{{ profile.about_me }} {{ city.name }}</h3>
          <div class="d-flex">
            <router-link
              class="text-decoration-none flex-grow-1"
              to="/GarbaPage"
            >
              <button class="btn btn-danger w-100 my-2">Subscribe</button>
              <!-- :to="{
              path: `/subscription/${garbaClassId}`,
            }" -->
              <!-- params: {
              id: profile.id,
            }, -->
              <!-- params: {
              id: garbaClassId,
            }, -->
            </router-link>
            <button
              class="btn btn-danger w-100 my-2 ml-2"
              @click="this.$router.push('/HomePage/MyGarbaClass')"
            >
              My Garba Class
            </button>
          </div>
        </div>
      </div>

      <!-- User Actions Section -->
      <div class="col-lg-6 d-flex" style="box-shadow: 0px 0px 9px -4px black">
        <UserActions />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import UserActions from "@/components/Model/User_Actions.vue";

export default {
  name: "HomeProfile",
  props: {
    followerId: String,
    userId: String,
  },
  data() {
    return {
      profile: [],
      item: [],
      sub: [],
      city: "",
      id: "",
      defaultImage: require("@/assets/profile-no-img.png"),
      garbaClassId: "",
    };
  },
  components: {
    UserActions,
  },
  methods: {
    async getData() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log('User type at the beginning:', userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(endpoint);

        this.profile = response.data.data;
        // console.log("GC Profile: ", this.profile);
        const length = this.profile.garba_classes.length;
        this.garbaClassId = this.profile.garba_classes[length - 1].id;
        // this.garbaClassId = 123;
        // console.log("Garba Id: ", this.garbaClassId);
        this.id = response.data.data.id;
        this.city = response.data.data.city || [];
        // console.log('Profile:', this.city);
        // console.log('Profile:', this.profile);
      } catch (err) {
        console.log("Error:", err);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

