<template>
  <!-- <h1>hello</h1> -->
  <div class="custom-animation-gj">
    <NavBar />
    <div v-for="post in posts" :key="post.id" class="postw">
      <div class="post-block">
        <div class="d-flex justify-content-between">
          <div class="d-flex mb-3">
            <div class="mr-2">
              <a href="#!" class="text-dark"
                ><img
                  :src="post.user.profile_photo"
                  alt="User"
                  class="author-img"
              /></a>
            </div>
            <div>
              <h5 class="mb-0">
                <a class="text-dark" style="text-decoration: none"
                  >{{ post.user.first_name }} {{ post.user.last_name }}
                </a>
              </h5>
              <p class="mb-0 text-muted">
                {{ moment(post.created_at).fromNow() }} {{ post.location_city }}
              </p>
            </div>
          </div>
        </div>

        <lisT :postId="post.id" />

        <div class="post-block__content mb-2">
          <!-- <img :src="post.video" alt="Content img" /> -->
          <div v-if="post.videos && post.videos.length > 0">
            <video
              :src="post.videos[0].video"
              controls
              height="400"
              width="1080"
              type="video/mp4"
            ></video>
          </div>
        </div>
        <div class="mb-3">
          <div class="User_action d-flex my-2 mb-2">
            <div style="width: 100%; display: flex; gap: 9%">
              <UserlikeP
                :postId="post.id"
                :likes_count="post.likes_count"
                :is_liked="post.is_liked"
              />
              <likeList :postId="post.id" :likes_count="post.likes_count" />
              <UsecomT
                :postId="post.id"
                :comments_count="post.comments_count"
              />
              <UsersharE :postId="post.id" :shares_count="post.shares_count" />
              <UservieW :postId="post.id" :views_count="post.views_count" />
            </div>
          </div>
          <p class="mb-0">
            {{ post.description }}
          </p>
        </div>
        <div class="post-block__comments">
          <!-- Comment Input -->
          <addCommT :postId="post.id" />
          <!-- Comment content -->
          <commenT />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "./Profile_Screens/NavBar.vue";
export default {
  name: "GC_Id_Post",
  components: {
    NavBar,
  },
};
</script>
<style lang="scss" scoped></style>
