<template>
  <div class="custom-animation-gj">
    <NavBar />
    <div
      class="w-100 d-flex justify-content-center align-items-center flex-column container"
      style="margin: 5rem auto"
    >
      <img
        class="w-100"
        alt
        src="..\assets\b5a9ebefbdba66c084a0e863d6906d5a.png"
      />
      <div class="row w-100">
        <div
          class="col-sm-12 col-md-4 rounded-3 p-0"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <div class="leftbox p-3">
            <h4>Total Payment:</h4>
            <h4>
              Rs.&nbsp;{{ subscription.subscription_amount }}
              <br />
              <label>
                <p id="rr">Including Taxes(8%)</p>
              </label>
            </h4>
            <h4 id="a">Terms & Conditions</h4>
            <p class="terms-conditions">
              By subscribing to a Garba class through the Service, you agree to
              the terms and conditions outlined in the separate Garba class
              terms and conditions.
            </p>
          </div>
        </div>

        <div
          class="col-sm-12 col-md-8 rounded-3 p-0"
          style="box-shadow: 0px 0px 9px -4px black"
        >
          <div class="rightbox p-3">
            <h2>Subscription</h2>
            <hr class="my-4" style="border-color: #c10111" />

            <div class="user_subscription">
              <form class="form-row">
                <div class="OTP1">
                  <input
                    type="text"
                    v-modal="otpFirst"
                    class="otp_text1"
                    id="t1"
                    maxlength="1"
                    v-on:click="focus()"
                    required
                  />
                  <input
                    type="text"
                    v-modal="otp - 2"
                    class="otp_text2"
                    id="t2"
                    maxlength="1"
                    required
                  />
                  <input
                    type="text"
                    v-modal="otp - 3"
                    class="otp_text3"
                    id="t3"
                    maxlength="1"
                    required
                  />
                  <input
                    type="text"
                    v-modal="otp - 4"
                    class="otp_text4"
                    id="t4"
                    maxlength="1"
                    required
                  />
                  <input
                    type="text"
                    v-modal="otp - 5"
                    class="otp_text5"
                    id="t5"
                    maxlength="1"
                    required
                  />
                  <input
                    type="text"
                    v-modal="otp - 6"
                    class="otp_text6"
                    id="t6"
                    maxlength="1"
                    required
                  />
                  <p class="text-center mt-4 mb-0">
                    Enter Code You which get from garbaclass
                  </p>
                </div>
              </form>

              <hr style="color: #c10111" />
              <div class="user_subscription">
                <div
                  class="Subscription_details"
                  v-for="subscriptions in 1"
                  :key="subscriptions.id"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <p>Garba Class Subscription</p>
                    <p>{{ subscription.subscription_amount }}</p>
                  </div>

                  <div class="d-flex w-100 justify-content-between">
                    <p>CGST</p>
                    <p>{{ subscription.cgst_amount }}</p>
                  </div>

                  <div class="dashed_border"></div>

                  <div class="d-flex w-100 justify-content-between">
                    <p>Gross Amount</p>
                    <p>+150.00</p>
                  </div>

                  <div class="d-flex w-100 justify-content-between">
                    <p>SGST</p>
                    <p>{{ subscription.sgst_amount }}</p>
                  </div>

                  <div class="d-flex w-100 justify-content-between">
                    <p>GST</p>
                    <p>{{ subscription.gst_amount }}</p>
                  </div>

                  <div class="dashed_border"></div>

                  <div class="d-flex w-100 justify-content-between">
                    <p>Gross Amount</p>
                    <p>{{ subscription.subscription_amount }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="Procced_btn w-100 d-flex justify-content-center align-items-center"
            >
              <button
                class="btn btn-danger w-50"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                id="Procced_button"
                @click="userSub"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- model- popup -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-body d-flex justify-content-center align-items-center"
              style="height: 50vh"
            >
              <div class="position-absolute top-0 end-0">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="d-flex justify-content-center align-items-center flex-column my-3"
              >
                <h3>Congralulations...!</h3>
                <h6>
                  <span>Thank you for suscription,</span>
                  <span>now you are eligible to view all offers.</span>
                </h6>
                <router-link to="AboutGC/">
                  <!-- <button class="btn btn-outline-danger my-2" style="width: 100%" @click="userSub">Subscribe</button> -->
                  <button
                    type="button"
                    class="btn btn-danger mt-3"
                    id="home_btn"
                  >
                    Go to Home Pgae
                  </button>
                </router-link>
              </div>
            </div>
            <div
              class="d-flex justify-content-end position-absolute top-0 end-0"
            >
              <img src="../assets/Asset.png" alt="bg-img" class="w-50" />
            </div>
            <div
              class="d-flex justify-content-start position-relative top-0 start-0"
            >
              <img src="../assets/Asset.png" alt="bg-img" class="w-50" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MyPopup from "@/components/MyPopup.vue";
import axios from "@/axios";
import { ref } from "vue";
import NavBar from "./Profile_Screens/NavBar.vue";
export default {
  name: "Subsription_Page_2",
  props: {
    userId: String,
  },
  components: {
    NavBar,
  },
  data() {
    return {
      subscription: [],
    };
  },

  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },

  mounted() {
    this.userSub();
    this.otpverify();
  },

  methods: {
    openPopup() {
      const popup = document.getElementById("popup");
      popup.classList.add("open-popup");
    },

    closePopup() {
      const popup = document.getElementById("popup");
      popup.classList.remove("open-popup");
    },

    userSub() {
      const userId = this.$route.params.id;
      // console.log(userId);
      let data = {
        garba_class_id: userId,
        subscription_type: 1,
      };
      axios
        .post(`entrant/subscriptions/subseribe?${userId} `, data)
        .then((response) => {
          // console.log("you subscribe", response.data);
          this.subscription = response.data.data;
          // console.log("from subscription array", this.subscription);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    focus() {
      let OTP1 = document.getElementsByClassName("OTP1")[0];
      OTP1.onkeyup = function (e) {
        let target = e.srcElement;
        let maxLength = parseInt(target.attributes["maxlength"].value, 10);
        let myLength = target.value.length;
        if (myLength >= maxLength) {
          let next = target;
          while ((next = next.nextElementSibling)) {
            if (next == null) break;
            if (next.tagName.toLowerCase() == "input") {
              next.focus();
              break;
            }
          }
        } else if (myLength === 0) {
          let previous = target;
          while ((previous = previous.previousElementSibling)) {
            if (previous == null) break;
            if (previous.tagName.toLowerCase() === "input") {
              previous.focus();
              break;
            }
          }
        }
      };
    },

    otpverify() {
      let n1 = document.getElementById("t1").value;
      let n2 = document.getElementById("t2").value;
      let n3 = document.getElementById("t3").value;
      let n4 = document.getElementById("t4").value;
      let n5 = document.getElementById("t5").value;
      let n6 = document.getElementById("t6").value;
      let newotp = n1 + n2 + n3 + n4 + n5 + n6;
      const userId = this.$route.params.id;
      let data = {
        garba_class_id: userId,
        email_otp: newotp,
      };
      axios
        .post(`entrant/subscriptions/generate-otp?${userId}`, data)
        .then((response) => {
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 6%;
}

.dashed_border {
  border: 2px dashed grey;
  width: 100%;
}

hr.my-4 {
  border: 1px solid #c10111;
  color: #c10111;
}

.gc_radio_btn {
  accent-color: #c10111;
}

.btn:hover {
  color: white;
}

.popup {
  width: 28em;
  height: 37em;
  background: white;
  border-radius: 6px;
  position: absolute;
  margin-top: 10px;
  left: 50%;
  transform: transition(50%, 50%) scale(0.2);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  visibility: hidden;
  transition: top 0.3s ease-out;
}

.popup .designImg {
  width: 10em;
  float: right;
  margin-top: 5px;
  margin-left: -13em;
  margin-right: -2em;
  opacity: 1;
}

.popup .designImg2 {
  width: 4em;
  margin-left: -49px;
  margin-top: -16px;
  padding-right: 5px;
  opacity: 1;
}

.popup .designImg3 {
  float: right;
  margin-top: 9em;
  margin-right: 35px;
  opacity: 1;
  width: 14%;
  opacity: 1;
}

.popup .designImg4 {
  width: 10em;
  float: left;
  margin-top: -6em;
  margin-left: -30px;
  opacity: 1;
}

.popup h3 {
  width: 2em;
  font-size: 1.5em;
  color: #c41d2b;
  font-weight: bold;
  margin-top: 2.5em;
  margin-left: 90px;
}

.open-popup {
  visibility: visible;
  top: 55%;
  transform: translate(-50%, -50%) scale(1);
}

.popup .group1 {
  height: 16em;
  width: 11em;
  margin-top: 40px;
  margin-left: 7em;
}

.popup button {
  border-radius: 5px;
  width: 13em;
  background-color: #c41d2b;
  color: white;
  border: none;
}

.OTP1 {
  margin-left: 2%;
  white-space: nowrap;
  width: 64%;
  padding: 2%;
}

.otp_text1,
.otp_text2,
.otp_text3,
.otp_text4,
.otp_text5,
.otp_text6 {
  text-align: center;
  font-weight: bolder;
  font-size: 150%;
  width: 17%;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #d4d4d4;
  height: 7vh;
  margin-left: 0%;
  margin-left: 2%;
}
</style>
