<template>
  <div class="d-flex align-items-center ms-2">
    <div style="display: contents">
      <img
        :src="profile.profile_photo ? profile.profile_photo : defaultImage"
        class="profile-image rounded-circle mr-2"
      />
    </div>
    <div style="width: 90%">
      <p class="fs-6 m-0 user-name">
        What's in your mind {{ profile.first_name + " " + profile.last_name }}
      </p>
    </div>
  </div>

  <div
    class="video d-flex justify-content-center align-items-center flex-column"
  >
    <!-- @click="isOpen = true" -->
    <button
      type="button"
      class="video-button"
      @click="this.$router.push({ name: 'Login' })"
    >
      <img
        class="video-btn"
        src="./assets/408405_videos_icon.svg"
        alt="Video"
      />
      <p class="title fs-6 m-0">Video</p>
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

export default {
  name: "AddVideo",

  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },

  data() {
    return {
      profile: [],
      defaultImage: require("./assets/profile-no-img.png"),
      // apiUrl: "http://uatapi.garbajockey.com/public/api/",
      apiUrl: "https://api.garbajockey.com/api/",
    };
  },

  methods: {
    async getDe() {
      try {
        // Access userType in your component
        const userType = this.$store.state.userType;
        // console.log("User type at the beginning:", userType);

        let endpoint = "";
        switch (userType) {
          case "1":
            endpoint = "entrant/my-profile";
            break;
          case "3":
            endpoint = "gc/my-profile";
            break;
          case "2":
            endpoint = "gj/my-profile";
            break;

          // Add more cases for other user types as needed

          default:
            endpoint = "default-profile";
            break;
        }

        const response = await axios.get(`${this.apiUrl + endpoint}`, {
          headers: {
            Authorization: `Bearer ` + localStorage.getItem("authToken"),
          },
        });
        this.profile = response.data.data;
        // console.log("User Profile:", this.profile);

        if (localStorage.getItem("token") || response.data.Status == 200) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },

  mounted() {
    this.getDe();
  },
};
</script>

<style scoped>
.user-name {
  font-weight: 600;
}
.profile-image {
  width: 2.7rem;
  height: 2.5rem;
  object-fit: cover;
}
.video-button {
  border: none;
  background: none;
}

.Post_video {
  border: 2px dashed #c10111;
  border-radius: 1rem;
  margin: auto;
  padding: 1rem;
  height: 8rem;
}

.upload_video {
  margin: 1rem 0;
  cursor: pointer;
}

.upload_video input {
  display: none;
}

.input-box {
  border: 1px solid #c10111;
  border-radius: 5px;
  height: 46px;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}

.level-preview {
  display: flex !important;
  color: #fff;
  border-radius: 4px;
  padding: 0;
  position: absolute;
  top: 4.7rem;
  left: 1.7rem;
  z-index: 1;
}

.level-preview img {
  width: 25% !important;
  height: auto !important;
}

.Post_btn {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  background-color: #c10111;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button {
  display: flex;
  justify-content: flex-end;
  /* Align to the end of the container (right) */
  align-items: flex-start;
  /* Align to the start of the container (top) */
  position: absolute;
  top: 0;
  right: 1.9rem;
  background: none;
  border: none;
  cursor: pointer;
}

.video-size {
  background: #c10111;
  height: 1rem;
  margin: 1rem 0;
}

.tag-search-results {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tag-search-results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tag-search-results li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag-search-results li:hover {
  background-color: #f0f0f0;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.tag {
  background-color: #c10111;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.remove-tag-button {
  background: none;
  border: none;
  color: white;
  margin-left: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .profile-image {
    width: 2.5rem;
    height: 2.4rem;
  }
}
</style>
